import React, { useState, useEffect, useContext } from 'react';
import AssetCard from './AssetCard';
import { Col, Row, Card, Button, InputGroup, FormControl } from 'react-bootstrap';
import { GiCutDiamond } from "react-icons/gi";
import { FaPlusSquare, FaDollarSign, FaQuestion } from "react-icons/fa";
import { Link } from "react-router-dom";
import AppContext from './AppContext';
import Select from 'react-select'
import axios from 'axios';
import { GetCurrencyFormatted } from './../../Utils.js';
import Tips from './Tips';

function AssetsList(props) {
    const { assets, filter, nameFilter } = { ...props };
    return (
        <>
            {assets.slice(0).reverse().map(function (asset, index) {
                if (filter === "all" || filter === asset.type) {
                    if (nameFilter === "" || (asset.name && asset.name.toLowerCase().includes(nameFilter.toLowerCase())))
                        return <Col key={"assetCard" + index} className="mb-3"><AssetCard asset={asset} /></Col>
                }
                return null;
            })}
        </>
    )
}

function AssetsListInheritance(props) {
    const { assets, filter, nameFilter } = { ...props };
    return (
        <>
            {assets.map(function (asset, index) {
                if (filter === "all" || filter === "inheritance") {
                    if (nameFilter === "" || (asset.name && asset.name.toLowerCase().includes(nameFilter.toLowerCase()))){
                    
                    let extraInfo = "$" + GetCurrencyFormatted(asset.calculatedValue) + " (" + asset.percentage +  "%) " + " from " + asset.vaultName;
                    if(!asset.connected) {
                        extraInfo = "Connect with " + asset.vaultName + " for full details and allow your Benefactor to always have the most updated information about you. This can be done on your profile page.";
                    }
                    
                    return <Col key={"assetCard" + index} className="mb-3 dotted"><AssetCard asset={asset} disableViewButton={true} showDetails={false} showConnectedbutton={false} extraInfo={extraInfo} /></Col>
                    }
                }
                return null;
            })}
        </>
    )
}


function Assets(props) {
    const { vaultAccessMode, vault } = useContext(AppContext);
    const [assets, setAssets] = useState([]);
    const [inheritedAssets, setInheritedAssets] = useState([]);
    const [assetsFilter, setAssetsFilter] = useState("all");
    const [estimatedValue, setEstimatedValue] = useState(0);
    const [assetsWithoutAssignment, setAssetsWithoutAssignment] = useState(0);
    const [assetsNameFilter, setAssetsNameFilter] = useState("");
    const [assetsFilterLabels, setAssetsFilterLabels] = useState([]);
    const assetTypeToggleChange = (val) => setAssetsFilter(val.value);
    const assetNameFilterChange = (val) => setAssetsNameFilter(val);

    useEffect(() => {
        if(vault.ownerId === undefined) return;
        axios.get(`/api/profiles?options=readAssetsInheritance`, {
            params: {
                profileId: vault.ownerId,
            }
        })
            .then(res => {
                setInheritedAssets(res.data);
            })
            .catch(function (error) {
                console.log(error);
            })
    }, [vault]);

    useEffect(() => {
        console.log(vaultAccessMode);
        console.log (vault.id);

        axios.get(`/api/assets`, {
            params: {
                vault: vault.id,
            }
        })
            .then(res => {
                setAssets(res.data);
            })
            .catch(function (error) {
                console.log(error);
            })
    }, [vault]);

    useEffect(() => {
        const assetsFilterCounts = { all: 0, account: 0, insurance: 0, property: 0, vehicle: 0, collectibles: 0 };
        let estimatedValue = 0;
        let assetsWithoutAssignment = 0;
        assets.forEach(function (element) {
            assetsFilterCounts[element.type]++
            assetsFilterCounts.all++;
            estimatedValue += parseInt(element.calculatedValue);
            if (!element.assignedToCharity && !element.assigned)
                assetsWithoutAssignment++;
        });

        setEstimatedValue(estimatedValue);
        setAssetsWithoutAssignment(assetsWithoutAssignment);

        const options = [
            { value: "all", label: "All (" + (assetsFilterCounts.all + inheritedAssets.length) + ")" },
            { value: "account", label: "Accounts + Investments (" + assetsFilterCounts.account + ")" },
            { value: "insurance", label: "Insurance Policies (" + assetsFilterCounts.insurance + ")" },
            { value: "property", label: "Properties (" + assetsFilterCounts.property + ")" },
            { value: "vehicle", label: "Vehicles (" + assetsFilterCounts.vehicle + ")" },
            { value: "collectibles", label: "Collectibles (" + assetsFilterCounts.collectibles + ")" },
            { value: "inheritance", label: "Propspective Inheritance (" + inheritedAssets.length + ")" },
        ];

        setAssetsFilterLabels(options);

    }, [assets, inheritedAssets]);

    return <>
        <Row style={{ marginBottom: '20px' }}>
            <Col md={3} className="mb-3"><Card className="dashboard totalAssets" style={{ textAlign: "center" }}>    <blockquote className="blockquote mb-0 card-body">
                <GiCutDiamond /> {assets.length}
                <footer>
                    <small>
                        Total Assets in Vault
                    </small>
                </footer>
            </blockquote></Card></Col>
            <Col md={3} className="mb-3"><Card className="dashboard totalValue" style={{ textAlign: "center" }}>   <blockquote className="blockquote mb-0 card-body">
                <FaDollarSign /> {GetCurrencyFormatted(estimatedValue)}
                <footer>
                    <small>
                        Estimated Value of Assets in Vault
                    </small>
                </footer>
            </blockquote></Card></Col>
            <Col md={3} className="mb-3"><Card className="dashboard attention" style={{ textAlign: "center" }}>    <blockquote className="blockquote mb-0 card-body">
                <FaQuestion /> {assetsWithoutAssignment}
                <footer>
                    <small>
                        Assets without Beneficiary/Charity
                    </small>
                </footer>
            </blockquote></Card></Col>
            {!vaultAccessMode.isReadOnly && <Col className="mb-3"><Button variant="info" as={Link} to="/assets/add" size="sm" className="pull-right"><FaPlusSquare /> Add an Asset</Button></Col>}
        </Row>
        <Row style={{ marginBottom: '20px' }}>
            <Col md={6} >
                <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">Filter By Name</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        placeholder="Name"
                        aria-label="ilter By Name"
                        onChange={(e) => assetNameFilterChange(e.target.value)}
                    />
                </InputGroup>
            </Col>
            <Col md={6}>
                <Select
                    className="assetTypeToggleChange"
                    options={assetsFilterLabels}
                    placeholder="Filter By Asset Type"
                    onChange={assetTypeToggleChange}
                />
            </Col>
        </Row>
        <Row className="row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-3 row-cols-xl-3">
            <AssetsList assets={assets} filter={assetsFilter} nameFilter={assetsNameFilter} />
            <AssetsListInheritance assets={inheritedAssets} filter={assetsFilter} nameFilter={assetsNameFilter} />
            <Col className="mb-3">
                <Tips contentKey="assets" />
            </Col>
        </Row>
    </>
}

export default Assets;