import { CardGroup, Card, Form, Row, Col, InputGroup, Alert } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { FaUserAlt, FaLock } from 'react-icons/fa';
import { useHistory } from "react-router-dom";
import { useAuth } from "../../providers/auth-provider";
import { useState, useEffect } from 'react';

function ResetPassword(props) {
    const { setpassword } = useAuth();
    const history = useHistory();
    const { user, authUser, login } = useAuth();
    const [show, hasError] = useState(false);
    const [errorText, setErrorText] = useState("");
    const [validated, setValidated] = useState(false);
    var rpToken = "";

    const onInit = () => {
        let searchParams = new URLSearchParams(window.location.search)
        searchParams.has('t') // true
        let token = searchParams.get('t')
        authUser({ "token": token })
        .then(res => {
            if (res.status !== 200) {
                // Show error from login
                hasError(true);
                setErrorText("The reset password link has expired, please send another reset password request.")
                }
            rpToken = token;
        });
    }

    useEffect(onInit, []);


    const onSubmit = (e) => {
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
          e.preventDefault();
          e.stopPropagation();
        }
        else
        {        
            e.preventDefault();
            setpassword({ "password": e.target["password"].value, "token": rpToken});
            // Redirect to login
            setErrorText("Password successfully reset. Redirecting to login...")
            setTimeout(() => {  window.location.replace("/login"); }, 3000);
        }
        setValidated(true);
    }    

    return <>
        <CardGroup>
            <Card className="p-4" md={8}>
                <Card.Body>
                    <Form noValidate validated={validated} onSubmit={onSubmit}>
                        <h3>Password Reset</h3>
                        <p className="text-muted">Please type in your new password</p>
                        {errorText !== "" && <Alert key='alertmessage' variant='danger'>
                            {errorText} <br />
                        </Alert>}                        
                        <Form.Row>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text><FaLock /></InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control required name="password" placeholder="Password" type="password" />
                                <Form.Control.Feedback type="invalid">
                                    Please enter a password.
                                </Form.Control.Feedback>                                  
                            </InputGroup>
                        </Form.Row>
                        <Row>
                            <Col>
                                <Button variant="info" size="sm" type="submit" className="pull-right">Reset Password</Button>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
        </CardGroup>
    </>
}

export default ResetPassword;