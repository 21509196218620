import React, { useState, useEffect, useContext } from 'react';
import AccountsCard from './AccountsCard';
import {Button, Row} from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import { FaMinusSquare } from 'react-icons/fa';
import { Link } from "react-router-dom";
import axios from 'axios';
import AppContext from '../AppContext';
import Tips from '../Tips';

function Accounts (props) {
    const { vaultAccessMode, vault } = useContext(AppContext);
    const [liabilities, setLiabilities] = useState();

    useEffect(() => {
        axios.get(`/api/accountDevices`, {
            params: {
                vault: vault.id,
            }
        })
        .then(res => {
            setLiabilities(res.data);
        })
        .catch(function (error) {
            console.log(error);
        })
    }, []);

    
    return <>
       <Tips contentKey="accounts" /><br />
       {!vaultAccessMode.isReadOnly && <Row style={{ marginBottom: '20px' }}>
            <Col>
                <Button variant="info" size="sm" as={Link} to="/accounts/new" className="pull-right"><FaMinusSquare /> Add a Account</Button>
            </Col>
        </Row>}
        <Row className="row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-3 row-cols-xl-3">
            {liabilities && liabilities.map ((l, i) => {
                return <Col key={l._id} className="mb-3"><AccountsCard liability={l} /></Col>
            })}  
       </Row>
    </>
}

export default Accounts;