import { Card, Image, Button } from 'react-bootstrap';
import { GiChest, GiCutDiamond } from "react-icons/gi";
import { FaTelegramPlane, FaUserFriends, FaPaperclip, FaCoins, FaHome, FaCar, FaUmbrella } from 'react-icons/fa';
import { Link } from "react-router-dom";
import { GetCurrencyFormatted, GetSubTypeImage, GetSubTypeLabel } from './../../Utils.js';

function AssetSubTypeIconFcuk(props) {
    let icon = <GiChest className="circle-icon" />;

    switch (props.type) {
        case "account": icon = <FaCoins />; break;
        case "insurance": icon = <FaUmbrella />; break;
        case "property": icon = <FaHome />; break;
        case "vehicle": icon = <FaCar />; break;
        case "collectible": icon = <GiChest />; break;
        default: icon = <GiCutDiamond />
    }
    return icon;
}

function AssetCard(props) {
    const { asset = {}, showDetails = true, extraInfo = "", disableViewButton = false, showConnectedbutton = false } = props;

    return <>
        <Card className={"assetCard " + asset.type}>
            <Card.Header>
                {asset.subType !== "" && <Image src={"https://www.execuvault.com/asset-icons/" + GetSubTypeImage(asset.subType)} className="smallerAvatar" roundedCircle />}
                
                <div style={{ fontWeight: "bold", marginTop: "4px" }}>{asset.name}<br />
                <div style={{fontWeight:"normal", fontSize:'.8em', marginTop:'2px'}}>{GetSubTypeLabel(asset.subType)}</div>
                </div>
                <div style={{ float: "right" }}><AssetSubTypeIconFcuk type={asset.type} /></div>
            </Card.Header>
            <Card.Body>

                {extraInfo !== "" && <Card.Text className="moreCSS"  style={{ fontSize: '.8em' }}>
                    {extraInfo}
                </Card.Text>}

                {showDetails && <><div className="moreCSS" style={{ fontSize: '.8em' }}>
                     <div style={{ float: "right" }} >$ {GetCurrencyFormatted(asset.calculatedValue)}</div>
                </div>

                </>}
            </Card.Body>
            {showConnectedbutton && <> 
                <Card.Footer>
                    <Button variant="info" as={Link} to={"/assets/" + asset._id} size="sm" className="pull-right"><FaTelegramPlane /> Connect</Button>
                </Card.Footer>
            </>}
            {!disableViewButton && <>
                <Card.Footer>
                        {asset.assigned > 0 && <><FaUserFriends />{" "}</>}
                        {asset.files && asset.files.length > 0 && <><FaPaperclip /></>}
                    <Button variant="info" as={Link} to={"/assets/" + asset._id} size="sm" className="pull-right"><FaTelegramPlane /> View</Button>
                </Card.Footer>
            </>
            }
        </Card>
    </>
}

export default AssetCard;