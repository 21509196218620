import Avatar from 'react-avatar';

function ProfileImage(props) {
    const { name, profileImage = "" } = props;
    let imageSrc = "";

    if (profileImage !== "" ) {
        var str = JSON.stringify({
            "bucket": "excevalut",
            "key": profileImage,
            "edits": {
                "resize": {
                    "width": 55,
                    "height": 55,
                    "fit": "cover"
                }
            }
        });
        var enc = window.btoa(str);
        imageSrc = "https://d3m0wmprcd486y.cloudfront.net/" + enc;
    }

    return <Avatar name={name} src={imageSrc} round={true} maxInitials={2} size="45" color="#2982cc" textMarginRatio={.25} textSizeRatio={2} />
}

export default ProfileImage;