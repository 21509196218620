import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom'
import { Link } from "react-router-dom";
import logo from '../../../xv-logo.png';
import { useContext, useState } from 'react';
import AppContext from './../AppContext';
import { FaAngleRight } from 'react-icons/fa';


export function Header(props) {
  const {vault, user} = useContext(AppContext);
  const [expanded, setExpanded] = useState(false);
  const isAdvisor = user.role === "advisor";
  let vaultNameDisplay = vault.name;
 
  return <>
    <Navbar bg="dark" variant="dark" expand="lg" className="mainNavigationBar" expanded={expanded}>
      <Navbar.Brand>
        <img
          src={logo}
          width="120"
          className="d-inline-block align-top"
          alt="Logo"
        />
       </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(expanded ? false : "expanded")} />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">

        </Nav>
        <Nav className="dropdown-menu-right">
          <NavDropdown title={"Hi " + user.fullName + "!"} className="dropdown-menu-right">
            <NavDropdown.Item as={NavLink} exact to="/profile" href="/profile" onClick={() => setExpanded(false)}>Profile</NavDropdown.Item>
            <NavDropdown.Item as={NavLink} exact to="/advisor" href="/advisor" onClick={() => setExpanded(false)}>Clients</NavDropdown.Item>
            <NavDropdown.Item as={NavLink} exact to="/logout" href="/logout" onClick={() => setExpanded(false)}>Logout</NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  </>
}

export default Header;