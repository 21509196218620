import { FaCheckCircle, FaDollarSign, FaUsers, FaTasks } from 'react-icons/fa';
import { Row, ListGroup, Col, Card } from 'react-bootstrap';
import Tips from './../Tips';
import { GiCutDiamond } from "react-icons/gi";
import axios from 'axios';
import AppContext from './../AppContext';
import { useContext, useEffect, useState} from "react";
import {GetCurrencyFormatted} from './../../../Utils.js';

export default function Dashboard(props) {
    const { vault } = useContext(AppContext);
    const [totalAssetCount, setTotalAssetCount] = useState("0");
    const [totalConnectionsCount, setTotalConnectionsCount] = useState("0");
    const [totalCalculatedValue, setTotalCalculatedValue] = useState(0);

    const onInit = () => {
        if(vault === undefined || vault.id === undefined || vault === {}) return;
        axios.all([
            axios.get(`/api/connections?options=names`, {
                params: {
                    vault: vault.id,
                }
            }),
            axios.get(`/api/assets?options=calculatedValue`, {
                params: {
                    vault: vault.id
                }
            })
        ])
            .then(axios.spread((connectionResults, assetResult) => {
                let totalCalculatedValue = 0;
                assetResult.data.forEach(function (element) { 
                    totalCalculatedValue += parseInt(element.calculatedValue);
                });
                setTotalCalculatedValue (totalCalculatedValue);
                setTotalAssetCount (assetResult.data.length);
                setTotalConnectionsCount (connectionResults.data.length);
            }))
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(onInit, [vault]);

    return <>
        <Row style={{ marginBottom: '20px' }}>
            <Col md={4} className="mb-4"><Card style={{ textAlign: "center" }}>    <blockquote className="blockquote mb-0 card-body">
                <GiCutDiamond /> {totalAssetCount}
                <footer>
                    <small>
                        Total Assets in Vault
                    </small>
                </footer>
            </blockquote></Card></Col>
            <Col md={4} className="mb-4"><Card style={{ textAlign: "center" }}>   <blockquote className="blockquote mb-0 card-body">
                <FaDollarSign /> {GetCurrencyFormatted (totalCalculatedValue)}
                <footer>
                    <small>
                        Estimated Value of Assets in Vault
                    </small>
                </footer>
            </blockquote></Card></Col>
            <Col md={4} className="mb-4"><Card style={{ textAlign: "center" }}>    <blockquote className="blockquote mb-0 card-body">
                <FaUsers /> {totalConnectionsCount}
                <footer>
                    <small>
                        Connections Added
                    </small>
                </footer>
            </blockquote></Card></Col>
          {/*   <Col md={3} className="mb-3"><Card style={{ textAlign: "center" }}>
                <blockquote className="blockquote mb-0 card-body">
                    <FaTasks /> 3/5
                <footer>
                        <small>
                            Vault Completion Rate
                    </small>
                    </footer>
                </blockquote></Card></Col> */}
        </Row>
        <Row>
            <Col style={{display:"none"}}>
                <Card>
                    <Card.Header>
                        Vault Completion Checklist
                    </Card.Header>
                    <Card.Body className="small">
                        <ListGroup className="vaultCheckList">
                            <ListGroup.Item variant="success">Completed Arrangements and Wishes <div className="pull-right"><FaCheckCircle /></div></ListGroup.Item>
                            <ListGroup.Item variant="success">Adding First Beneficiary <div className="pull-right"><FaCheckCircle /></div></ListGroup.Item>
                            <ListGroup.Item variant="success">Adding First Asset <div className="pull-right"><FaCheckCircle /></div></ListGroup.Item>
                            <ListGroup.Item>Adding First Document</ListGroup.Item>
                            <ListGroup.Item>Connecting with First Professional Advisor/Lawyer</ListGroup.Item>
                        </ListGroup>
                    </Card.Body>
                </Card>
            </Col>
            <Col>
                <Tips contentKey="dashboard" />
            </Col>
        </Row>
        <Row style={{marginTop:"20px"}}>
        <Col>
                <Tips contentKey="dashboard1" />
            </Col>
            <Col>
                <Tips contentKey="dashboard2" />
            </Col>
            <Col>
                <Tips contentKey="dashboard3" />
            </Col>            
        </Row>
    </>
}
