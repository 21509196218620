import PeopleCard from './PeopleCard';
import { Row, Button, Col, InputGroup, FormControl } from 'react-bootstrap';
import { FaUserPlus } from 'react-icons/fa';
import { Link } from "react-router-dom";
import { useContext, useState, useEffect } from 'react';
import AppContext from './AppContext';
import axios from 'axios';
import Tips from './Tips';

function ConnectionsList(props) {
    const { connections, filter } = { ...props };
    return (
        <>
            {connections.slice(0).reverse().map(function (connection, index) {
                if (filter === "" || connection.name.toLowerCase().includes(filter.toLowerCase()))
                    return <Col key={"connectionCard" + index} className="mb-3"><PeopleCard connection={connection} /></Col>
                return null;
            })}
        </>
    )
}


function People(props) {
    const { vaultAccessMode, vault } = useContext(AppContext);
    const [connections, setConnections] = useState([]);
    const [connectionssFilter, setConnectionssFilter] = useState("");
    const connectionsFilterChange = (val) => setConnectionssFilter(val);
    const isReadOnly = vaultAccessMode.isReadOnly;

    useEffect(() => {
        axios.get(`/api/connections`, {
            params: {
                options:"withCounts",
                vault: vault.id,
            }
        })
        .then(res => {
            setConnections(res.data);
        })
        .catch(function (error) {
            console.log(error);
        })
    }, [vault]);

    return <>
        <Row style={{ marginBottom: '20px' }}>
            <Col md={6} >
                <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">Filter By Name</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        placeholder="Name"
                        aria-label="filter By Name"
                        onChange={(e) => connectionsFilterChange(e.target.value)}
                    />
                </InputGroup>
            </Col>
            <Col md={6}>
                {!isReadOnly && <Button variant="info" size="sm" className="pull-right" style={{ marginLeft: "5px" }} as={Link} to="/people/add"><FaUserPlus /> Add Connection</Button>}
                {!isReadOnly && <Button variant="secondary" size="sm" className="pull-right" as={Link} to="/advisorsearch"><FaUserPlus /> Advisor Search</Button>}
            </Col>
        </Row>
        <Row className="row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-3 row-cols-xl-3">
            <ConnectionsList connections={connections} filter={connectionssFilter} />
            <Col className="mb-3">
                <Tips contentKey="people" />
            </Col>            
        </Row>
    </>
}

export default People;