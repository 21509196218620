import { Col, Row, Card, ToggleButtonGroup, ToggleButton, Form } from 'react-bootstrap';
import { GiChest } from "react-icons/gi";
import { FaCar, FaCoins, FaUmbrella, FaHome } from "react-icons/fa";
import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import Tips from './Tips';
import AssetDetailsForm from './AssetDetailsForm';
import axios from 'axios';
import AppContext from './AppContext';
import { GetSubTypeLabel } from './../../Utils.js';

function AddAsset() {
    const { vault } = useContext(AppContext);
    const history = useHistory();
    const [assetType, setAssetType] = useState("account");
    const [subAssetType, setSubAssetType] = useState("account");
    const assetTypeToggleChange = (val) => setAssetType(val);
    const assetSubTypeToggleChange = (e) => setSubAssetType(e.target.value);
    const [relationship, setRelationship] = useState([]);

    let showExtraFields = assetType === "property" || assetType === "vehicle";

    const onSubmit = (data, e) => {
        data.type = assetType;
        data.subType = subAssetType;
        axios.put(`/api/assets?vault=` + vault.id, data)
            .then(res => {
                if (res.status === 200) {
                    history.push("/assets/" + res.data + "?new")
                }
            })
    }

    useEffect(() => {
        switch (assetType) {
            case "account":
                setRelationship(["cash", "checking", "crypto", "mutualfunds", "retirement", "savings", "shares", "stocks"]);
                break;
            case "insurance":
                setRelationship(["annuity", "final", "life", "preneed", "Other"]);
                break;
            case "property":
                setRelationship(["building", "condo", "cottage", "farm", "garage", "house", "land", "marina", "timeshare"]);         
                break;
            case "vehicle":
                setRelationship(["bicycle", "boat", "car", "motorcycle", "plane", "rv", "snowmobile", "tractor", "truck", "suv", "Other"]);       
                break;
            case "collectibles":
                setRelationship(["artwork", "books", "clothing", "coins", "decor", "gadgets", "music", "jewelry", "sporting", "tools", "toys", "vintages"]);
                break;
            default:
                setRelationship([]);
        }
    }, [assetType])

    return <>
        <Row style={{ marginBottom: '20px' }}>
            <Col>
                <Card>
                    <Card.Header>
                        Asset Category
                    </Card.Header>
                    <Card.Body>
                        <ToggleButtonGroup type="radio" name="options" className="assetToggle giant" defaultValue={assetType} onChange={assetTypeToggleChange}>
                            <ToggleButton className="account"  value={"account"}><FaCoins /><br />Accounts + Investments</ToggleButton>
                            <ToggleButton className="insurance"  value={"insurance"}><FaUmbrella /><br />Insurance Policies</ToggleButton>
                            <ToggleButton className="properties" value={"property"}><FaHome /><br />Properties</ToggleButton>
                            <ToggleButton className="vehicle"  value={"vehicle"}><FaCar /> Vehicles</ToggleButton>
                            <ToggleButton className="collectibles" value={"collectibles"}><GiChest /> Collectibles</ToggleButton>
                        </ToggleButtonGroup>

                        <Col>
                            <br />
                            <Form.Label>Subcategory</Form.Label><br />
                            <Form.Control as="select" name="locationId" defaultValue={document.locationId} onChange={assetSubTypeToggleChange}>
                                <option value="None">None Selected</option>
                                {relationship !== null && <>
                                    {relationship.map(function (r, index) {
                                        return <option key={r} value={r}>{GetSubTypeLabel(r)}</option>
                                    })}
                                </>
                                }
                            </Form.Control>
                        </Col>
                    </Card.Body>
                </Card>
                <br />
                <Card>
                    <Card.Header>
                        Details
                     </Card.Header>
                    <AssetDetailsForm onSubmit={onSubmit} showExtraFields={showExtraFields} defaultValues={{value:0, amountOwing:0, percentage:100}} isReadOnly={false} />
                </Card>
            </Col>
            <Col md="4">
                 {assetType === "account" && <Tips contentKey="assets_account" />}
                 {assetType === "insurance" && <Tips contentKey="assets_insurance" />}
                 {assetType === "property" && <Tips contentKey="assets_properties" />}
                 {assetType === "vehicle" && <Tips contentKey="assets_vehicle" />}
                 {assetType === "collectibles" && <Tips contentKey="assets_collectibles" />}
            </Col>
        </Row>
    </>
}

export default AddAsset;