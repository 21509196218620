import {
  Form,
  Row,
  ToggleButtonGroup,
  Col,
  Card,
  Button,
  Modal,
  Badge,
  Alert,
  ToggleButton,
  Image,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router";
import { useState, useEffect, useContext } from "react";
import {
  FaTelegramPlane,
  FaRegEyeSlash,
  FaProjectDiagram,
  FaHistory,
} from "react-icons/fa";
import AppContext from "./AppContext";
import Select from "react-select";
import Address from "./Address";
import axios from "axios";
import AssetDetailsForm from "./AssetDetailsForm";
import Toaster from "./Toaster";
import Attachments from "./Attachments";
import ReactGoogleMapImage from "react-google-map-image";
import { GetSubTypeImage } from "./../../Utils.js";
import TimeAgo from "javascript-time-ago";
import Tips from "./Tips";
import en from "javascript-time-ago/locale/en";

function AssetDetails(props) {
  const assetProfile = props.match.params.id;
  const { vaultAccessMode, vault } = useContext(AppContext);
  const [deleteAssetConfirmation, setDeleteAssetConfirmation] = useState(false);
  const [showLocation, setShowLocation] = useState(false);
  const [asset, setAsset] = useState({});
  const [permission, setPermission] = useState("");
  const [showNewLocationForm, setNewLocationForm] = useState(false);
  const [locations, setLocations] = useState(null);
  const [displayLocation, setDisplayLocation] = useState(null);
  const [showHistory, setShowHistory] = useState(false);
  const [relatedContacts, setRelatedContacts] = useState([]);
  const [beneficiaryOptions, setBeneficiaryOptions] = useState([]);
  const [
    beneficiaryOptionsWithoutAdvisors,
    setBeneficiaryOptionsWithoutAdvisors,
  ] = useState([]);
  const [beneficiarySelections, setBeneficiarySelections] = useState();
  const [beneficiarySelectionsErrorMsg, setBeneficiarySelectionsErrorMsg] =
    useState("");
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [toasterMessage, setToasterMessage] = useState({
    title: "",
    message: "",
  });
  const isReadOnly = vaultAccessMode.isReadOnly;
  const location = useLocation();
  const history = useHistory();
  TimeAgo.addLocale(en);
  const timeAgo = new TimeAgo("en-US");

  const uploadCallBack = (acceptedFiles) => {
    const files = [];
    acceptedFiles.forEach(function (file) {
      files.push({ name: file.name, type: file.type });
    });

    axios
      .post(
        `/api/assets?options=attachFile&vault=` +
          vault.id +
          `&asset=` +
          assetProfile,
        files,
        {}
      )
      .then(function (result) {
        result.data.forEach(function (url, i) {
          var options = { headers: { "Content-Type": acceptedFiles[i].type } };
          return axios.put(result.data[i], acceptedFiles[i], options);
        });
      });
  };

  const deleteFileCallback = (filekey) => {
    axios.delete(
      `/api/assets?options=attachFile&vault=` +
        vault.id +
        `&document=` +
        assetProfile +
        `&filekey=` +
        filekey
    );
  };

  const viewFileCallBack = (fileKey) => {
    axios
      .get(`/api/assets?options=attachFile`, {
        params: {
          vault: vault.id,
          filekey: fileKey,
        },
      })
      .then((res) => {
        var win = window.open(res.data, "_blank");
        win.focus();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const onSubmit = (data, e) => {
    data.meta = {};
    if (asset.type === "account") {
      data.meta.institution = e.target["meta.institution"].value;
      data.meta.account = e.target["meta.account"].value;
      data.meta.transit = e.target["meta.transit"].value;
    } else if (asset.type === "insurance") {
      data.meta.coverage = e.target["meta.coverage"].value;
      data.meta.company = e.target["meta.company"].value;
      data.meta.policy = e.target["meta.policy"].value;
      data.meta.expiration = e.target["meta.expiration"].value;
    } else if (asset.type === "vehicle") {
      data.meta.model = e.target["meta.model"].value;
      data.meta.vin = e.target["meta.vin"].value;
    } else if (asset.type === "collectibles") {
      data.meta.maker = e.target["meta.maker"].value;
      data.meta.serial = e.target["meta.serial"].value;
    }

    axios
      .post(`/api/assets?vault=` + vault.id + `&asset=` + assetProfile, data)
      .then((res) => {
        if (res.status === 200) {
          setToasterMessage({
            title: "Success!",
            message: "Asset Details updated",
          });
          setShowSuccessToast(true);
        }
      });
  };
  const resetLocationDisplay = (selectedLocation, addresses) => {
    let _locations = locations;
    if (_locations === null && addresses !== null) {
      _locations = addresses;
    }

    if (_locations !== null) {
      const location = _locations.find(
        (element) => element._id === selectedLocation
      );
      setDisplayLocation(location);
    }
  };

  const handleLocationSave = (event) => {
    setShowLocation(false);
    event.preventDefault();
    const selectedLocation = event.target.locationSelection.value;
    if (selectedLocation !== "") {
      axios
        .post(
          `/api/assets?vault=` +
            vault.id +
            `&options=location&asset=` +
            assetProfile,
          { selectedLocation }
        )
        .then((res) => {
          if (res.status === 200) {
            resetLocationDisplay(selectedLocation);
            setToasterMessage({
              title: "Success!",
              message: "Asset Location updated",
            });
            setShowSuccessToast(true);
          }
        });
    }
  };

  const handleRelatedContacts = (item, key) => {
    setRelatedContacts(item);
  };

  const handleBeneficarySelection = (event) => {
    event.preventDefault();
    setBeneficiarySelectionsErrorMsg("");
    const lookup = beneficiarySelections.reduce((a, e) => {
      a[e.assignment] = ++a[e.assignment] || 0;
      return a;
    }, {});

    if (beneficiarySelections.filter((e) => lookup[e.assignment]).length > 0) {
      setBeneficiarySelectionsErrorMsg(
        "The same beneficiary/charity was assigned more than once."
      );
      return;
    }

    let totalPercentage = 0;
    beneficiarySelections.map((a) => {
      totalPercentage += parseInt(a.percentage);
    });
    if (totalPercentage < 0 || totalPercentage > 100) {
      setBeneficiarySelectionsErrorMsg(
        "The total percentage exceeeded 100. Total calculated percentage is currently " +
          totalPercentage +
          "."
      );
      return;
    }

    const ___parent = {
      beneficiarySelections: beneficiarySelections,
      relatedContacts: relatedContacts,
      permissions: permission,
    };

    axios
      .post(
        `/api/assets?vault=` +
          vault.id +
          `&options=assignments&asset=` +
          assetProfile,
        ___parent
      )
      .then((res) => {
        if (res.status === 200) {
          setToasterMessage({
            title: "Success!",
            message: "Asset Beneficiaries/Charities updated",
          });
          setShowSuccessToast(true);
        }
      });
  };

  const handleBeneficaryDropdown = (item, key) => {
    if (beneficiarySelections[key] === undefined)
      beneficiarySelections[key] = { assignment: "", percentage: 0 };

    beneficiarySelections[key].assignment = item.value;
    setBeneficiarySelections(beneficiarySelections);
  };

  const handleBeneficaryPercentage = (event, key) => {
    if (beneficiarySelections[key] === undefined)
      beneficiarySelections[key] = { assignment: "" };

    beneficiarySelections[key].percentage = event.target.value;
    setBeneficiarySelections(beneficiarySelections);
  };

  const onInit = () => {
    if (vault === undefined || vault.id === undefined) return;

    if (location.search === "?new") {
      setToasterMessage({ title: "Success!", message: "New Asset Created" });
      setShowSuccessToast(true);
    }

    console.log(vault.id);

    axios
      .all([
        axios.get(`/api/connections?options=names`, {
          params: {
            vault: vault.id,
          },
        }),
        axios.get(`/api/assets`, {
          params: {
            vault: vault.id,
            asset: assetProfile,
          },
        }),
        axios.get(`/api/locations`, {
          params: {
            vault: vault.id,
          },
        }),
      ])
      .then(
        axios.spread((connectionResults, assetResult, locationResults) => {
          let loadedConnections = [];
          connectionResults.data.map((c) => {
            return loadedConnections.push({
              label: c.name,
              value: c._id,
            });
          });

          setBeneficiaryOptions(loadedConnections);

          loadedConnections = [];
          connectionResults.data.map((c) => {
            if (c.type === "advisor") return;
            return loadedConnections.push({
              label: c.name,
              value: c._id,
            });
          });

          setBeneficiaryOptionsWithoutAdvisors(loadedConnections);

          const showExtraFields =
            assetResult.data.type === "property" ||
            assetResult.data.type === "vehicle";
          assetResult.data.showExtraFields = showExtraFields;
          setAsset(assetResult.data);
          setPermission(assetResult.data.permission);

          if (assetResult.data.assignments !== undefined) {
            assetResult.data.assignments.map((a) => {
              const c = connectionResults.data.find(
                (x) => x._id === a.assignment
              );
              if (c != null) {
                a.label = c.name;
              }
            });
            setBeneficiarySelections(assetResult.data.assignments);
          } else {
            setBeneficiarySelections([]);
          }
          setLocations(locationResults.data.locations);
          resetLocationDisplay(
            assetResult.data.address,
            locationResults.data.locations
          );
          setIsLoaded(true);
        })
      )
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(onInit, [vault]);

  const onProfilePropertyChange = (val, field) => {
    asset[field] = val.target.value;
    setAsset(asset);
  };

  const handleDelete = () => {
    setDeleteAssetConfirmation(false);
    axios
      .delete(`/api/assets?vault=` + vault.id + `&asset=` + assetProfile)
      .then((res) => {
        if (res.status === 200) {
          history.push("/assets/");
        }
      });
  };

  const onNotesSubmit = (e) => {
    e.preventDefault();
    const updatedAssetProfile = {
      notes: asset.notes,
    };

    axios
      .post(
        `/api/assets?vault=` +
          vault.id +
          `&options=notes&asset=` +
          assetProfile,
        updatedAssetProfile
      )
      .then((res) => {
        if (res.status === 200) {
          setToasterMessage({
            title: "Success!",
            message: "Asset Notes Updated",
          });
          setShowSuccessToast(true);
        }
      });
  };

  return (
    <>
      <Modal
        size="sm"
        show={deleteAssetConfirmation}
        onHide={() => setDeleteAssetConfirmation(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Are you sure you want to delete this asset?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <Button
            variant="info"
            size="sm"
            className="pull-right"
            onClick={() => {
              handleDelete();
            }}
          >
            {" "}
            Delete Asset
          </Button>
        </Modal.Body>
      </Modal>

      <Modal
        show={showLocation}
        onHide={() => setShowLocation(false)}
        centered
        size="lg"
      >
        <Form onSubmit={handleLocationSave}>
          <Modal.Header closeButton>
            <Modal.Title>Asset's Location</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Row>
              <Form.Group as={Col} controlId="formLocationSelection">
                <Form.Control
                  as="select"
                  defaultValue={asset.address}
                  name="locationSelection"
                >
                  <option value="None">None Selected</option>
                  {locations !== null && (
                    <>
                      {locations.map(function (location, index) {
                        return (
                          <option key={location._id} value={location._id}>
                            {location.name} &#160;&#160;&#160;&#160;&#160;(
                            {location.address}, {location.city})
                          </option>
                        );
                      })}
                    </>
                  )}
                </Form.Control>
              </Form.Group>
            </Form.Row>
            {showNewLocationForm && <Address />}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="info"
              size="sm"
              className="pull-right"
              type="submit"
            >
              {" "}
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal
        show={showHistory}
        onHide={() => setShowHistory(false)}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Audit History</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            {asset.audit !== undefined &&
              asset.audit
                .slice(0)
                .reverse()
                .map(function (note, index) {
                  return (
                    <span key={note.timestamp}>
                      {note.user} <Badge variant="info">{note.action}</Badge>{" "}
                      {timeAgo.format(note.timestamp)}
                      <br />
                    </span>
                  );
                })}
          </>
        </Modal.Body>
        <Modal.Footer>
          <small>
            Note: Does not include audit history recently completed.
          </small>
        </Modal.Footer>
      </Modal>

      {isLoaded && (
        <>
          <Row>
            <Col>
              <Card>
                <Card.Header>
                  <Image
                    src={
                      "https://www.execuvault.com/asset-icons/" +
                      GetSubTypeImage(asset.subType)
                    }
                    className="smallerAvatar"
                    roundedCircle
                  />{" "}
                  {asset.name}
                  {!isReadOnly && (
                    <a
                      href="#"
                      className="pull-right showHistoryLink"
                      onClick={() => setShowHistory(true)}
                    >
                      <FaHistory /> View History
                    </a>
                  )}
                </Card.Header>
                <AssetDetailsForm
                  onSubmit={onSubmit}
                  showExtraFields={
                    asset.type === "property" || asset.type === "vehicle"
                  }
                  isReadOnly={isReadOnly}
                  assetType={asset.type}
                  showSubCategory={true}
                  defaultValues={{
                    title: asset.name,
                    value: asset.value,
                    subType: asset.subType,
                    permission: parseInt(asset.permission),
                    amountOwing: asset.amountOwing,
                    percentage: asset.percentage,
                    meta: asset.meta,
                  }}
                />
              </Card>
              <br />
              <Form noValidate onSubmit={handleBeneficarySelection}>
                <Card>
                  <Card.Header>Beneficiaries/Charities/Related..</Card.Header>
                  <Card.Body>
                    {!isReadOnly && (
                      <>
                        <Form.Row>
                          <Form.Group as={Col} controlId="Designation">
                            <Form.Label>
                              Would Beneficiaries be able to see this asset?
                            </Form.Label>
                            <br />

                            <ToggleButtonGroup
                              type="radio"
                              name="permission"
                              value={permission}
                              onChange={(e) => setPermission(e)}
                            >
                              <ToggleButton variant="light" value={"1"}>
                                <FaProjectDiagram /> Default: Beneficiary's
                                Setting
                              </ToggleButton>
                              <ToggleButton variant="light" value={"2"}>
                                <FaRegEyeSlash /> Hide Asset
                              </ToggleButton>
                            </ToggleButtonGroup>
                          </Form.Group>
                        </Form.Row>
                      </>
                    )}
                    {beneficiarySelectionsErrorMsg !== "" && (
                      <Alert key="alertmessage" variant="danger">
                        {beneficiarySelectionsErrorMsg} <br />
                      </Alert>
                    )}

                    {[0, 1, 2, 3, 4].map((v, index) => {
                      return (
                        <Form.Row key={"beneficiaryDropDown" + index}>
                          <Col md={9}>
                            {index === 0 && (
                              <Form.Label>Beneficiary</Form.Label>
                            )}
                            {beneficiarySelections !== undefined &&
                              beneficiarySelections.length >= 0 && (
                                <Select
                                  options={beneficiaryOptionsWithoutAdvisors}
                                  defaultValue={
                                    beneficiarySelections[index]
                                      ? {
                                          value:
                                            beneficiarySelections[index]
                                              .assignment,
                                          label:
                                            beneficiarySelections[index].label,
                                        }
                                      : {}
                                  }
                                  onChange={(e) =>
                                    handleBeneficaryDropdown(e, index)
                                  }
                                />
                              )}
                          </Col>
                          <Form.Group as={Col}>
                            {index === 0 && <Form.Label>Percentage</Form.Label>}
                            <Form.Control
                              defaultValue={
                                beneficiarySelections != null &&
                                beneficiarySelections[index]
                                  ? beneficiarySelections[index].percentage
                                  : ""
                              }
                              onChange={(e) =>
                                handleBeneficaryPercentage(e, index)
                              }
                            ></Form.Control>
                          </Form.Group>
                        </Form.Row>
                      );
                    })}
                    <br />
                    <Form.Label> Identify Related Contacts</Form.Label>
                    <br />
                    {beneficiarySelections !== undefined &&
                      beneficiaryOptions.length >= 0 && (
                        <Select
                          name="relatedContacts"
                          defaultValue={asset.relatedContacts}
                          onChange={(e) => handleRelatedContacts(e)}
                          isMulti
                          options={beneficiaryOptions}
                        />
                      )}
                  </Card.Body>
                  {!isReadOnly && (
                    <Card.Footer>
                      <Button
                        variant="info"
                        size="sm"
                        type="submit"
                        className="pull-right"
                      >
                        <FaTelegramPlane /> Save
                      </Button>
                    </Card.Footer>
                  )}
                </Card>
              </Form>
              <br />
              <Card>
                <Card.Header>Notes</Card.Header>
                <Card.Body>
                  <Form.Group>
                    <Form.Control
                      as="textarea"
                      rows={8}
                      defaultValue={asset.notes}
                      onChange={(e) => onProfilePropertyChange(e, "notes")}
                    />
                  </Form.Group>
                </Card.Body>
                <Card.Footer>
                  {!isReadOnly && (
                    <Button
                      variant="info"
                      size="sm"
                      className="pull-right"
                      onClick={(e) => {
                        onNotesSubmit(e);
                      }}
                    >
                      {" "}
                      Save
                    </Button>
                  )}
                </Card.Footer>
              </Card>
            </Col>
            <Col md="4">
              <Attachments
                isEditing={!isReadOnly}
                isReadOnly={isReadOnly}
                uploadCallBack={uploadCallBack}
                deleteFileCallback={deleteFileCallback}
                viewFileCallBack={viewFileCallBack}
                originalFiles={asset && asset.files}
              />
              <br />
              <Card>
                <Card.Header>Location</Card.Header>
                <Card.Body>
                  {displayLocation && (
                    <>
                      <Card.Text style={{ fontSize: ".8em" }}>
                        <strong>{displayLocation.name}</strong>
                        <br />
                        <ReactGoogleMapImage
                          config={{
                            center:
                              displayLocation.address +
                              " " +
                              displayLocation.city +
                              " " +
                              displayLocation.country,
                            size: "500x240",
                            zoom: "15",
                            key: "AIzaSyBb9nrVZdnuiYeJyODid0RT-OpFxKn_iMs",
                            maptype: "roadmap",
                          }}
                          wrapperStyle={{ width: "100%" }}
                          style={{
                            width: "100%",
                            height: "auto",
                            border: "1px solid #ccc",
                          }}
                        />
                        {displayLocation.address}
                        <br />
                        {displayLocation.city}, {displayLocation.region},{" "}
                        {displayLocation.country} <br />
                        {displayLocation.zip}
                      </Card.Text>
                    </>
                  )}
                  {asset && !asset.address && <>None Selected</>}
                </Card.Body>
                <Card.Footer>
                  {!isReadOnly && (
                    <Button
                      variant="info"
                      size="sm"
                      className="pull-right"
                      onClick={() => setShowLocation(true)}
                    >
                      <FaTelegramPlane /> Edit
                    </Button>
                  )}
                </Card.Footer>
              </Card>
              <br />
              {!isReadOnly && (
                <Card>
                  <Card.Body>
                    <Button
                      variant="info"
                      size="sm"
                      className="pull-right"
                      onClick={() => setDeleteAssetConfirmation(true)}
                    >
                      <FaTelegramPlane /> Delete
                    </Button>
                  </Card.Body>
                </Card>
              )}
              <br />
              {asset.type === "account" && (
                <Tips contentKey="assets_detail_account" />
              )}
              {asset.type === "insurance" && (
                <Tips contentKey="assets_detail_insurance" />
              )}
              {asset.type === "property" && (
                <Tips contentKey="assets_detail_properties" />
              )}
              {asset.type === "vehicle" && (
                <Tips contentKey="assets_detail_vehicle" />
              )}
              {asset.type === "collectibles" && (
                <Tips contentKey="assets_detail_collectibles" />
              )}
            </Col>
          </Row>
        </>
      )}
      <Toaster
        setShowSuccessToast={setShowSuccessToast}
        toasterMessage={toasterMessage}
        showSuccessToast={showSuccessToast}
      />
    </>
  );
}

export default AssetDetails;

