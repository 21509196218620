import { CardGroup, Card, Form, Row, Col, InputGroup, Alert } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { FaUserAlt, FaLock } from 'react-icons/fa';
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../../providers/auth-provider";
import { useState, useEffect } from 'react';
import logo from '../../XVLogo800p.png';
import { useLocation } from 'react-router';

function LoginPage(props) {
    const { user, login } = useAuth();
    const history = useHistory();
    const [loginErrorText, setLoginErrorText] = useState("");
    const [isPersonalAccountOnly, setIsPersonalAccountOnly] = useState(false);
    const [defaultEmail, setDefaultEmail] = useState("");
    const location = useLocation();


    const onSubmit = (e) => {
        e.preventDefault();
        login({ "email": e.target["email"].value, "password": e.target["password"].value })
            .then(res => {
                if (res.status === 200) {
                    const localUserJson = localStorage.getItem("user");
                    const localUser = localUserJson && JSON.parse(localUserJson);
                    if (localUser.role === "advisor") {
                        history.push('/advisor');
                    }
                    else {
                        history.push('/');
                    }
                }
                else {
                    // Show error from login
                    res.json()
                    .then(error => {
                        if (error.msg.length)
                        setLoginErrorText(error.msg);
                    })                    
                }
            });
        // Redirect to home
    }

    useEffect(() => {
        if (location.search.indexOf("?email") >= 0) {
            const email = location.search.substr(7)
            if (email.length > 0) {
                setDefaultEmail(email);
                setIsPersonalAccountOnly(true);
            }
        }
    });

    return <>
        <CardGroup>
            <Card className="p-5" md={8}>
                <Card.Body>
                    <Form onSubmit={onSubmit}>
                        <p class="font-12 mt-0 mb-0">XV Legacy Transfer</p>
                        <h3>ExecuVault Login</h3>
                        {loginErrorText !== "" && <Alert key='alertmessage' variant='danger'>
                            {loginErrorText} <br />
                        </Alert>}
                        <Form.Row>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text><FaUserAlt /></InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control name="email" defaultValue={defaultEmail} placeholder="Email Address" />
                            </InputGroup>
                        </Form.Row>
                        <Form.Row>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text><FaLock /></InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control name="password" placeholder="Password" type="password" />
                            </InputGroup>
                        </Form.Row>
                        <Row>
                            <Col>
                                <p style={{ fontSize: ".875rem" }} >Forgotten Your Password?<br/>
                                    <Link to="/forgotpassword" style={{ fontSize: ".875rem" }} >Click to Reset</Link>
                                </p>
                                <Button variant="info" size="sm" type="submit" className="pull-right">login</Button>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
            <Card bg="primary" className="py-2 d-md-down-none text-center" text="white" style={{ width: "45%" }}>
                <Card.Body className="card-login">
                    <img class="signupLogo" src={ logo }></img>
                    <br/>
                    <br/>
                    <h5>WELCOME TO EXECUVAULT</h5>
                    <br/>
                    <br/>                   
                    <p class="LoginPrompt">Need a Personal Account?</p>
                    <Link to={"/signup?email=" + defaultEmail}>
                        <Button variant="info" size="m" type="button" >signup</Button>
                    </Link>
                    <br/>
                    {!isPersonalAccountOnly && <>
                    <br/>                   
                    <p class="LoginPrompt">Are you an Advisor or Lawyer?</p>
                    <Link to="/advisorsignup">
                        <Button variant="info" size="m" type="button">register</Button>
                    </Link>
                    </>}
                    <p class="Copyright">© 2021 ExecuVault™</p>
                </Card.Body>
            </Card>
        </CardGroup>
    </>
}

export default LoginPage;