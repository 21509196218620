import { Form, Row, InputGroup, FormControl, Col, Card, Button, Modal, Badge, DropdownButton, Dropdown } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { FaTelegramPlane, FaRegFileAlt, FaTimes, FaPaperclip } from 'react-icons/fa';
import { useForm } from "react-hook-form";
import AppContext from '../AppContext';
import Attachments from './../Attachments';
import Tips from '../Tips';
import { IoMdSettings } from 'react-icons/io';
import Address from '../Address';
import axios from 'axios';
import Toaster from '../Toaster';
import { useLocation } from 'react-router';

function LiabilityDetails(props) {
    const liabilityProfile = props.match.params.id;
    const { vaultAccessMode, vault } = useContext(AppContext);
    const [deleteAssetConfirmation, setDeleteAssetConfirmation] = useState(false);
    const [showSuccessToast, setShowSuccessToast] = useState(false);
    const [toasterMessage, setToasterMessage] = useState({ title: "", message: "" });
    const [showLocation, setShowLocation] = useState(false);
    const [showNewLocationForm, setNewLocationForm] = useState(false);
    const [showHistory, setShowHistory] = useState(false);
    const [isEditing, setIsEditing] = useState(liabilityProfile !== "new");
    const [showNoteModal, setNoteModal] = useState(false);
    const isReadOnly = vaultAccessMode === "readOnly";
    const [liability, setLiability] = useState();
    const [locations, setLocations] = useState(null);
    const { register, handleSubmit, errors } = useForm({ criteriaMode: "all" });
    const history = useHistory();
    const location = useLocation();

    const onProfilePropertyChange = (val, field) => {
        liability[field] = val.target.value;
        setLiability(liability);
    }

    const onNotesSubmit = (e) => {
        e.preventDefault();
        const updatedLiabilityProfile = {
            notes: liability.notes
        }

        axios.post(`/api/liabilities?vault=` + vault.id + `&options=notes&liability=` + liabilityProfile, updatedLiabilityProfile)
            .then(res => {
                if (res.status === 200) {
                    setToasterMessage({ title: "Success!", message: "Liability Notes Updated" })
                    setShowSuccessToast(true);
                    setNoteModal(false);
                }
            })
    }    

    const handleDelete= () => {
        setDeleteAssetConfirmation(false);
          axios.delete(`/api/liabilities?vault=` + vault.id + `&liability=` + liabilityProfile)
            .then(res => {
                if (res.status === 200) {
                    history.push("/liabilities/");
                }
            })            
    }

    const handleLocationSave= (event) => {
        setShowLocation(false);
        event.preventDefault();
        const selectedLocation = event.target.locationSelection.value;
        if(selectedLocation !== ""){
            axios.post(`/api/liabilities?vault=` + vault.id + `&options=location&liability=` + liabilityProfile, {selectedLocation})
            .then(res => {
                if (res.status === 200) {
                    setToasterMessage({ title: "Success!", message: "Liability Location updated" })
                    setShowSuccessToast(true);
                }
            })            
        }
    }

    const onSubmit = (data, e) => {
        setIsEditing (liabilityProfile !== "new");
        if(isEditing){
            axios.post(`/api/liabilities?vault=` + vault.id + `&liability=` + liabilityProfile, data)
            .then(res => {
                if (res.status === 200) {
                    console.log("done");
                    setToasterMessage({ title: "Success!", message: "Liability Details Updated" })
                    setShowSuccessToast(true);
                }
            })
        }
        else{
        axios.put(`/api/liabilities?vault=` + vault.id, data)
            .then(res => {
                if (res.status === 200) {
                    history.push("/liabilities/" + res.data + "?new");
                    setIsEditing(true);
                }
            })
        }
    }

    useEffect(() => {
        if(liabilityProfile === "new"){
            return;
        }
        if (location.search === "?new") {
            setToasterMessage({ title: "Success!", message: "New Liability Created" })
            setShowSuccessToast(true);
        }


        axios.get(`/api/liabilities`, {
            params: {
                vault: vault.id,
                liability: liabilityProfile
            }
        })
            .then(res => {
                setLiability(res.data);
            })
            .catch(function (error) {
                console.log(error);
            })
    }, []);

    const handleFileSelect = event => {
        event.preventDefault();
    };

    useEffect(() => {
        if (locations === null && vault.id !== undefined) {
            console.log(vault);
            axios.get(`/api/locations`, {
                params: {
                    vault: vault.id
                }
            })
                .then(res => {
                    setLocations(res.data.locations);
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }, [showLocation]);

    const uploadCallBack = (acceptedFiles) => {
        const files = [];
        acceptedFiles.forEach(function (file) {
            files.push({ name: file.name, type: file.type });
        });
        axios.post(`/api/liabilities?options=attachFile&vault=` + vault.id + `&liability=` + liabilityProfile, files, {})
            .then(function (result) {
                result.data.forEach(function (url, i) {
                    var options = { headers: { 'Content-Type': acceptedFiles[i].type } };
                    return axios.put(result.data[i], acceptedFiles[i], options);
                });
            })
            .then(function (results) {
                axios.get(`/api/liabilities`, {
                    params: {
                        vault: vault.id,
                        liability: liabilityProfile
                    }
                })
                    .then(res => {
                        setLiability(res.data);
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
            });
    }

    const deleteFileCallback = (filekey) => {
        axios.delete(`/api/liabilities?options=attachFile&vault=` + vault.id + `&liability=` + liabilityProfile + `&filekey=` + filekey);
    }

    const viewFileCallBack = (fileKey) => {
        axios.get(`/api/liabilities?options=attachFile`, {
            params: {
                vault: vault.id,
                liability: liabilityProfile,
                filekey: fileKey
            }
        })
            .then(res => {
                    var win = window.open(res.data, '_blank');
                    win.focus();
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    return <>
        <Modal
            size="sm"
            show={deleteAssetConfirmation}
            onHide={() => setDeleteAssetConfirmation(false)}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Are you sure you want to delete this liability?
          </Modal.Title>
            </Modal.Header>
            <Modal.Body> <Button variant="info" size="sm" className="pull-right" onClick={() => {handleDelete()}}> Delete Liability</Button></Modal.Body>
        </Modal>
        <Modal
            show={showLocation}
            onHide={() => setShowLocation(false)}
            centered
            size="lg"
        >
             <Form onSubmit={handleLocationSave}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Liabilities's Location
          </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                    {liability !== undefined && <><Form.Row>
                        <Form.Group as={Col} controlId="formLocationSelection">
                            <Form.Control as="select" defaultValue={liability.address} name="locationSelection">
                                <option value="None">None Selected</option>
                                {locations !== null && <>
                                    {locations.map(function (location, index) {
                                        return <option key={location._id} value={location._id}>{location.name} &#160;&#160;&#160;&#160;&#160;({location.address}, {location.city})</option>
                                    })}
                                  </>
                                }
                            </Form.Control>
                        </Form.Group>
                    </Form.Row>
                    </>
                    }

                    {showNewLocationForm && <Address />}
                
            </Modal.Body>
            <Modal.Footer>
                <Button variant="info" size="sm" className="pull-right" type="submit" > Save</Button>
            </Modal.Footer>
            </Form>
        </Modal>

        <Modal
            show={showHistory}
            onHide={() => setShowHistory(false)}
            centered
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Audit History
          </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <>
                    {liability && liability.audit !== undefined && liability.audit.reverse().map(function (note, index) {
                        return <span key={note.timestamp}>{note.user}  <Badge variant="info">{note.action}</Badge> {note.timestamp}<br /></span>;
                    })}
                </>
            </Modal.Body>
            <Modal.Footer>
                <small>Note: Does not include audit history recently completed.</small>
            </Modal.Footer>
        </Modal>

        <Modal
            show={showNoteModal}
            onHide={() => setNoteModal(false)}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Note
          </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {liability && <Form.Group>
                    <Form.Control as="textarea" rows={8} defaultValue={liability.notes} onChange={(e) => onProfilePropertyChange(e, "notes")} />
                </Form.Group>}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="info" size="sm" className="pull-right" onClick={(e) => { onNotesSubmit(e) }}> Save</Button>
            </Modal.Footer>
        </Modal>

        <Row>
            <Col>
                <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Card>
                        {isEditing && <>
                            <Card.Header>
                                {liability && liability.name}
                        <DropdownButton variant="icon" className="pull-right" id="dropdown-basic-button" title={<IoMdSettings />}>
                                    <Dropdown.Item onClick={() => setShowLocation(true)}>View/Add Location</Dropdown.Item>
                                    <Dropdown.Item onClick={() => setShowHistory(true)}>View History</Dropdown.Item>
                                    <Dropdown.Item onClick={() => setNoteModal(true)}>View/Add Note</Dropdown.Item>
                                    {!isReadOnly && <Dropdown.Item onClick={() => setDeleteAssetConfirmation(true)}>Remove Liability</Dropdown.Item>}
                                </DropdownButton>
                            </Card.Header>
                        </>}
                        {!isEditing && <>
                            <Card.Header>
                                Liability Details
                        </Card.Header>
                        </>}
                        <Card.Body>

                            <Form.Row>
                                <Form.Group as={Col} md="12">
                                    <Form.Label>Nickname of this Liability</Form.Label>
                                    <Form.Control
                                        name="name"
                                        defaultValue={liability ? liability.name : ""}
                                        placeholder=""
                                        ref={register({
                                            required: true, maxLength: 80,
                                        })}
                                        isInvalid={!!errors.name}
                                    />
                                    <Form.Control.Feedback type="invalid" tooltip>
                                        {errors.name && "Name is required"}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} md="4" controlId="formGridAddress2">
                                    <Form.Label>Estimated Amount Owing</Form.Label>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Prepend>
                                            <InputGroup.Text>$</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <FormControl placeholder=""
                                            name="amountOwing" aria-label="Amount"
                                            placeholder=""
                                            defaultValue={liability ? liability.amountOwing : ""}
                                            isInvalid={!!errors.amountOwing}
                                            ref={
                                                register({
                                                    required: true,
                                                    valueAsNumber: true,
                                                    validate: { positive: value => parseInt(value, 10) > 0 || 'Liability value should be greater than 0' }
                                                })
                                            }
                                            isInvalid={!!errors.amountOwing}
                                        />
                                        <Form.Control.Feedback type="invalid" tooltip>
                                            {errors.amountOwing?.types?.required && <p>Required</p>}
                                            {errors.amountOwing?.types?.positive && <p>Must be greater than 0</p>}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                            </Form.Row>
                        </Card.Body>
                        {!isReadOnly && <Card.Footer>
                            <Button variant="info" type="submit" size="sm" className="pull-right"><FaTelegramPlane /> Save</Button>
                        </Card.Footer>}
                    </Card>
                </Form>
            </Col>
            <Col md="4">
            {isEditing && <>
                    <Attachments
                        isEditing={isEditing}
                        isReadOnly={vaultAccessMode.isReadOnly}
                        uploadCallBack={uploadCallBack}
                        deleteFileCallback={deleteFileCallback}
                        viewFileCallBack={viewFileCallBack}
                        originalFiles={liability && liability.files} />
                    <br />
                </>}
                <Tips contentKey="liabilityDetails" />
            </Col>
        </Row>
        <Toaster setShowSuccessToast={setShowSuccessToast} toasterMessage={toasterMessage} showSuccessToast={showSuccessToast} />
    </>
}

export default LiabilityDetails;