import AdvisorCard from './AdvisorCard';
import { Row,  Col, InputGroup, FormControl } from 'react-bootstrap';
import { useContext, useState, useEffect } from 'react';
import AppContext from './AppContext';
import axios from 'axios';

function ConnectionsList(props) {
    const { connections, filter, vault } = { ...props };
    return (
        <>
            {connections.slice(0).reverse().map(function (connection, index) {
               if(connection._id === "6034b68100c6aa633892f513") return;


                if (filter === "" || connection.fullName.toLowerCase().includes(filter.toLowerCase()))
                    return <Col key={"connectionCard" + index} className="mb-3"><AdvisorCard connection={connection} vault={vault} /></Col>
                return null;
            })}
        </>
    )
}


function AdvisorSearch(props) {
    const { vaultAccessMode, vault } = useContext(AppContext);
    const [connections, setConnections] = useState([]);
    const [connectionssFilter, setConnectionssFilter] = useState("");
    const connectionsFilterChange = (val) => setConnectionssFilter(val);

    useEffect(() => {
        axios.get(`/api/profiles?options=readAdvisors`, {
            params: {
                vault: vault.id,
            }
        })
        .then(res => {
            setConnections(res.data);
        })
        .catch(function (error) {
            console.log(error);
        })
    }, []);

    return <>
        <Row style={{ marginBottom: '20px' }}>
            <Col md={6} >
                <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">Search By Name</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        placeholder="Name"
                        aria-label="filter By Name"
                        onChange={(e) => connectionsFilterChange(e.target.value)}
                    />
                </InputGroup>
            </Col>
        </Row>
        <Row className="row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-3 row-cols-xl-3">
            <ConnectionsList connections={connections} filter={connectionssFilter} vault={vault} />
        </Row>
    </>
}

export default AdvisorSearch;