import Select from 'react-select'
import { Col, Row, Button, Form } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import axios from 'axios';

export const Content = (props) => {
    const [contentBlocks, setContentBlocks] = useState([]);
    const [contentData, setContentData] = useState("");
    const [contentBlock, setContentBlock] = useState("");
    const [actionLink, setActionLink] = useState("");
    const [actionLinkText, setActionLinkText] = useState("");

    useEffect(() => {
        axios.get(`/api/content`)
            .then(res => {
                var newArray = res.data.map(function (item) {
                    return {
                        value: item._id,
                        label: item.key,
                        content:item.block,
                        actionLink: item.actionLink,
                        actionLinkText: item.actionLinkText

                    };
                });

                setContentBlocks(newArray);
            })
            .catch(function (error) {
                console.log(error);
            })
    }, []);

    const onContentBlockChange = (e) => {
        setContentBlock(e.content);
        setActionLink(e.actionLink);
        setActionLinkText(e.actionLinkText);
        setContentData(e);
        console.log(e);
    }

    const onContentSubmit = (e) => {
        e.preventDefault();
        axios.post(`/api/content?content=` + contentData.value, {key:contentData.label, block:contentBlock, actionLink:actionLink, actionLinkText:actionLinkText })
    }

    return <>
        <Row>
            <Col md={6}>
                <Select
                    className="assetTypeToggleChange"
                    options={contentBlocks}
                    placeholder="contentBlocks"
                    onChange={(e) => onContentBlockChange(e)}
                />
            </Col>
        </Row>
        <Row style={{marginTop:"20px"}}>
            <Col>
                <Form.Control as="textarea" value={contentBlock} onChange ={(e) => setContentBlock(e.target.value)} rows={8}></Form.Control>
            </Col>
        </Row>
        <Row style={{marginTop:"20px"}}>
            <Col>
                 <Form.Label>Action Text</Form.Label>
                 <Form.Control value={actionLinkText} onChange={(e)=> setActionLinkText(e.target.value)} placeholder="Valid Action Text" />
            </Col>
        </Row>    
        <Row style={{marginTop:"20px"}}>
            <Col>
                 <Form.Label>Action Link</Form.Label>
                 <Form.Control name="Email" value={actionLink} onChange={(e)=> setActionLink(e.target.value)} placeholder="Valid Relative URL" />
            </Col>
        </Row>               
        <Row style={{marginTop:"20px"}}>
            <Col><Button variant="info" size="sm" className="pull-right" onClick={(e) => { onContentSubmit(e) }}> Save</Button></Col>
        </Row>
    </>
}

export default Content;