import { Button, Card, Modal, ButtonGroup, Form, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { FaTelegramPlane, FaChartPie, FaRegStickyNote } from 'react-icons/fa';
import {FcInvite} from 'react-icons/fc';
import { AiOutlineUserDelete } from 'react-icons/ai';
import { useContext, useState } from 'react';
import ProfileImage from "./../ProfileImage";
import AppContext from './../AppContext';
import axios from 'axios';
import Moment from 'react-moment';

function PeopleCard(props) {
    const { person } = props;
    const { setVault, setVaultAccessMode, user } = useContext(AppContext);
    const [isDeleted, setIsDeleted] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [deleteAssetConfirmation, setDeleteAssetConfirmation] = useState(false);
    const [noteModal, setNoteModal] = useState(false);
    const [inviteModal, setInviteModal] = useState(false);
    const [inviteMessage, setInviteMessage] = useState("");
    const [note, setNote] = useState(person.note);

    const onNoteAdd = (e) => {
        e.preventDefault();
        setNoteModal(false);
        axios.post(`/api/connections?options=addNoteToClient&vault=` + person.vault, { email: user.email, note: note })
            .then(res => {
                if (res.status === 200) {
                    
                }
            })
    }

    const onDeleteSubmit = (e) => {
        e.preventDefault();
        setDeleteAssetConfirmation(false);
        axios.post(`/api/connections?options=removeConnectedClient&vault=` + person.vault, { email: user.email })
            .then(res => {
                if (res.status === 200) {
                    setIsDeleted(true);
                }
            })
    }

    const inviteCustomer = (e) => {
        console.log(user);
        setIsDisabled(true);
        axios.post(`/api/connections?options=inviteConnection&vault=` + person.vault + "&connection=" + user.fullName, {inviteMessage:inviteMessage})
            .then(res => {
                if (res.status === 200) {
                   console.log(res.data);
                   setInviteModal(false);
                }
            })
    }

    return <>

        <Modal
            size="sm"
            show={deleteAssetConfirmation}
            onHide={() => setDeleteAssetConfirmation(false)}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Are you sure you want to remove this client?
          </Modal.Title>
            </Modal.Header>
            <Modal.Body> <Button variant="info" size="sm" className="pull-right" onClick={onDeleteSubmit}> Remove Client</Button></Modal.Body>
        </Modal>

        <Modal
            show={inviteModal}
            onHide={() => setInviteModal(false)}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Email Message
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Control name="note" rows="10"  as="textarea"  value={inviteMessage} onChange={(e)=> setInviteMessage(e.target.value)}  placeholder="Message" />
                    </Form.Group>
                </Form.Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="info" size="sm" className="pull-right" onClick={(e) => { inviteCustomer(e) }}> Send</Button>
            </Modal.Footer>
        </Modal>


        <Modal
            show={noteModal}
            onHide={() => setNoteModal(false)}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Note
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Control name="note" rows="10"  as="textarea"  value={note} onChange={(e)=> setNote(e.target.value)}  placeholder="Note" />
                    </Form.Group>
                </Form.Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="info" size="sm" className="pull-right" onClick={(e) => { onNoteAdd(e) }}> Add</Button>
            </Modal.Footer>
        </Modal>


        {!isDeleted && <>
            <Card className="clientCard">
                <Card.Header className={"alittlebitofcss"}>
                    <ProfileImage profileImage={person.profileImage} name={person.name} />{' '} <div style={{ marginTop: '8px' }}>{person.name}
                        <br /><div style={{ fontSize: '.8em' }}> {person.email}</div></div>
                        <Button variant="light" className="floatTopRight" size="sm" onClick={() => { setDeleteAssetConfirmation(true) }}><AiOutlineUserDelete /></Button>
                </Card.Header>
                <Card.Body>
                    <Card.Text style={{ fontSize: '.8em' }}>
                        {person.assigned && !person.inviteTimestamp && <>Vault Created</>}
                        {person.assigned && person.inviteTimestamp && <>Invited</>}
                        {!person.assigned && <>Connected</>}
                    </Card.Text>
                </Card.Body>
                <Card.Footer>
                    <ButtonGroup aria-label="Basic example">             
                        <Button variant="info" size="sm" onClick={() => { setNoteModal(true) }}><FaRegStickyNote /> Note</Button>
                        {person.assigned && <>
                        <Button variant="info" disabled={isDisabled} onClick={() => { setInviteModal(true) }} size="sm" className="pull-right"><FcInvite /> Invite</Button>
                        </>}
                        <Button variant="info" as={Link} to={"/advisor/report/" + person.vault} size="sm" className="pull-right"><FaChartPie /> Report</Button>
                        <Button variant="primary" as={Link} to="/" onClick={() => { setVault({ name: person.name, id: person.vault, ownerId: person.owner }); setVaultAccessMode({ isReadOnly: false }) }} size="sm" className="pull-right"><FaTelegramPlane /> Vault</Button>
                    </ButtonGroup>
                </Card.Footer>
            </Card>
        </>}
    </>
}

export default PeopleCard;