import React, { useState, useEffect, useContext } from 'react';
import FileCard from '../FileCard';
import {Button, Row} from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import { FaMinusSquare } from 'react-icons/fa';
import { Link } from "react-router-dom";
import axios from 'axios';
import AppContext from './../AppContext';
import { GetDocumentTypeLabel, documentTypeLabels } from './../../../Utils.js';
import Tips from './../Tips';

function Documents (props) {
    const { vaultAccessMode, vault } = useContext(AppContext);
    const [documents, setDocuments] = useState();

    useEffect(() => {
        axios.get(`/api/documents`, {
            params: {
                vault: vault.id,
            }
        })
        .then(res => {
            setDocuments(res.data);
        })
        .catch(function (error) {
            console.log(error);
        })
    }, []);

    
    return <>
       <Tips contentKey="documents" /><br />
       <Row style={{ marginBottom: '20px' }}>
            <Col>
                {!vaultAccessMode.isReadOnly && <Button variant="info" size="sm" as={Link} to="/documents/new" className="pull-right"><FaMinusSquare /> Add a Document Collection</Button>}
            </Col>
        </Row>
        <Row className="row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-3 row-cols-xl-3">
            {documents && documents.map ((l, i) => {
                return <Col key={l._id} className="mb-3"><FileCard document={l} /></Col>
            })}  
       </Row>
       <Row className="row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-3 row-cols-xl-3">
            {documentTypeLabels && documentTypeLabels.map ((l, i) => {
                if(l === "other") return;
                if(documents && documents.filter(e => e.type === l).length > 0) return ;
                return <Col key={l + "_" + i} className="mb-3"><FileCard document={{"name":GetDocumentTypeLabel(l), "type":l}} /></Col>
            })}  
       </Row>       
    </>
}

export default Documents;