import { CardGroup, Card, Form, Row, Col, InputGroup, Alert } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { FaUserAlt, FaLock } from 'react-icons/fa';
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../../providers/auth-provider";
import { useState } from 'react';
import logo from '../../XVLogo800p.png';

function Signup(props) {
    const { signup } = useAuth();
    const [signupErrorText, setSignupErrorText] = useState("");
    const [validated, setValidated] = useState(false);

    const onSubmit = (e) => {
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
          e.preventDefault();
          e.stopPropagation();
        }
        else
        {        
            e.preventDefault();
            signup({ "email": e.target["email"].value, "password": e.target["password"].value, 
            "fullName": e.target["fullName"].value, "isAdvisor": true })
            .then(res => {
                if (res.status === 200) {
                    setSignupErrorText("Please check your email to verify your account and get started!");
                }
                else
                {
                    res.json()
                    .then(error => {
                        if (error.msg.length)
                            setSignupErrorText(error.msg);
                    })
            
                }
            })        
        }
        setValidated(true);
    }
    return <>
        <CardGroup>
            <Card className="p-5" md={8}>
                <Card.Body className="card-body card-login">
                    <Form noValidate validated={validated} onSubmit={onSubmit}>
                        <p class="font-12 mt-0 mb-0">XV Advisor Portal</p>
                        <h3>Advisor Registration</h3>
                        {signupErrorText !== "" && <Alert key='alertmessage' variant='danger'>
                            {signupErrorText} <br />
                        </Alert>}
                        <Form.Row>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text><FaUserAlt /></InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control required name="fullName" placeholder="Full Name" />
                                <Form.Control.Feedback type="invalid">
                                    Please enter your full name.
                                </Form.Control.Feedback>                                  
                            </InputGroup>
                        </Form.Row>
                        <Form.Row>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text><FaUserAlt /></InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control required name="email" placeholder="Email Address" />
                                <Form.Control.Feedback type="invalid">
                                    Please enter your email address.
                                </Form.Control.Feedback>  
                            </InputGroup>
                        </Form.Row>
                        <Form.Row>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text><FaLock /></InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control required name="password" placeholder="Password" type="password" />
                                <Form.Control.Feedback type="invalid">
                                    Please enter a password.
                                </Form.Control.Feedback>    
                            </InputGroup>
                        </Form.Row>
                        <Form.Row>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text><FaLock /></InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control name="retypePassword" placeholder="Retype Password" type="password" />
                            </InputGroup>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group controlId="formBasicCheckbox">
                                <Form.Check>
                                    <Form.Check.Input required />
                                    <Form.Check.Label>I have read and understood the Terms and Conditions of the ExecuVault™ <a href="https://www.execuvault.com/app/Beta-Participation-Agreement.html">Advisor Agreement</a></Form.Check.Label>
                                    <Form.Control.Feedback type="invalid">
                                    You must agree to all Terms and Conditions.
                                    </Form.Control.Feedback>                                         
                                </Form.Check>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <InputGroup className="mb-3">
                            </InputGroup>
                        </Form.Row>
                        <Row>
                            <Col>
                                <Button variant="info" size="sm" type="submit" className="pull-right">Register</Button>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
            <Card bg="primary" className="py-2 d-md-down-none text-center" text="white" style={{ width: "45%" }}>
                <Card.Body className="card-login">
                <img class="signupLogo" src={ logo }></img>
                    <br/>
                    <br/>
                    <h5>WELCOME TO EXECUVAULT</h5>
                    <br/>
                    <br/>                   
                    <p class="LoginPrompt">Already have an Account?</p>
                    <Link to="/login">
                        <Button variant="info" size="m" type="button" >login</Button>
                    </Link>
                    <br/>
                    <br/>                   
                    <p class="LoginPrompt">Need a Personal Account?</p>
                    <Link to="/signup">
                        <Button variant="info" size="m" type="button">signup</Button>
                    </Link>
                    <p class="Copyright">© 2021 ExecuVault™</p>
                </Card.Body>
            </Card>
        </CardGroup>
    </>
}

export default Signup;