import { Form, Card, Col, Button, Table, Row } from 'react-bootstrap';
import Address from './Address';
import Toaster from './Toaster';
import ProfileImage from './ProfileImage';
import { FaTelegramPlane } from 'react-icons/fa';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import React, { useContext, useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useForm } from "react-hook-form";
import AppContext from './AppContext';
import Tips from './Tips';

export const UserProfile = (props) => {

    const {vault, user} = useContext(AppContext);
    const isAdvisor = user.role === "advisor";
    const [toasterMessage, setToasterMessage] = useState({ title: "", message: "" });
    const [showSuccessToast, setShowSuccessToast] = useState(false);
    const [profile, setProfile] = useState({});
    const [profileConnections, setProfileConnections] = useState([]);
    const [profileImageKey, setProfileImageKey] = useState("initial");
    const inputFile = useRef(null)
    const { register, handleSubmit, errors } = useForm({ criteriaMode: "all" });

    useEffect(() => {
        axios.get(`/api/profiles`, {
            params: {
                profileId: user.id,
            }
        })
            .then(res => {
                setProfile(res.data);
            })
            .catch(function (error) {
                console.log(error);
            })

        axios.get(`/api/profiles?options=readProfilePrivacyConnection`, {
            params: {
                profileId: user.id,
            }
        })
            .then(res => {
                setProfileConnections(res.data);
            })
            .catch(function (error) {
                console.log(error);
            })
    }, []);

    const onSubmit = (data, e) => {
        const updatedProfile = {
            email: e.target.email.value,
            fullName: e.target.fullName.value,
            dob: e.target.dob === undefined ? "" :  e.target.dob.value,
            phoneNumber: e.target.phoneNumber.value,
            address: e.target.address.value,
            city: e.target.city.value,
            gender: e.target.gender === undefined ? "" : e.target.gender.value,
            marital:  e.target.marital === undefined ? "" : e.target.marital.value,            
            country: e.target["rcrs-country"].value,
            region: e.target["rcrs-region"].value,
            zip: e.target.zip.value
        };

        const ___profile = profile;
        ___profile.fullName = updatedProfile.fullName;
        setProfile(___profile);

        axios.post(`/api/profiles?profileId=` + user.id, updatedProfile)
            .then(res => {
                if (res.status === 200) {

                    setProfileImageKey("keyyyy" + Math.floor(Math.random() * 100));
                    setToasterMessage({ title: "Success!", message: "Profile Details Updated" })
                    setShowSuccessToast(true);
                }
            })
    }

    const onConnectionChange = (connection, checked) => {
        axios.post(`/api/connections?options=connected&connection=` + connection, {checked: checked});
    }

    const onSubmitEmployer = (data, e) => {

        const updatedProfile = {
            employer: e.target.employer.value,
            occupation: e.target.occupation.value,
            income: !isAdvisor ? e.target.income.value: "",
            designations: isAdvisor ? e.target.designations.value : "",
            speciality: isAdvisor ? e.target.specialty.value: "",
            bio: isAdvisor ? e.target.bio.value: "",
            biography: isAdvisor ? e.target.biography.value: "",
            website: isAdvisor ? e.target.website.value: ""
        };

        axios.post(`/api/profiles?options=profileEmployer&profileId=` + user.id, updatedProfile)
            .then(res => {
                if (res.status === 200) {
    setToasterMessage({ title: "Success!", message: "Profile Details Updated" })
                    setShowSuccessToast(true);
                }
            })
    }


    const handleInputFileChange = (event) => {
        const file = event.target.files[0];
        let newImageProfilePath = "";
        axios.post(`/api/profiles?options=profileImage&profileId=` + user.id, { type: file.type })
            .then(function (result) {
                var options = { headers: { 'Content-Type': file.type } };
                newImageProfilePath = result.data.key;
                return axios.put(result.data.signedUrls, file, options);
            })
            .then(function (result) {
                const ___profile = profile;
                ___profile.profileImage = newImageProfilePath;
                setProfile(___profile);
                setProfileImageKey("keyup" + Math.floor(Math.random() * 1000));
            })

    }

    const onChangeImageClick = () => {
        inputFile.current.click();
    };

    return <>
        <Row><Col md={8}>
            <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                <Card>
                    <Card.Header>
                        Profile Details
                    </Card.Header>
                    <Card.Body>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" name="email"
                                    ref={register({
                                        required: true, maxLength: 80, pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        }
                                    })}
                                    placeholder="Enter email"
                                    isInvalid={!!errors.email}
                                    defaultValue={user.email}
                                    disabled />
                                <Form.Control.Feedback type="invalid" tooltip>
                                    {errors.email && "Email is required"}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Label>Full Name</Form.Label>
                                <Form.Control name="fullName" ref={register({ required: true, maxLength: 80 })} placeholder="First Name Last Name" defaultValue={profile.fullName} />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control type="phone" name="phoneNumber" ref={register({ maxLength: 18 })} defaultValue={profile.phoneNumber} placeholder="Phone Number" />
                            </Form.Group>
                            {!isAdvisor && <>               
                            <Form.Group as={Col}>
                                <Form.Label>Date of Birth</Form.Label>
                                <Form.Control name="dob" ref={register({ maxLength: 10 })} defaultValue={profile.dob} placeholder="MM/DD/YYYY" />
                            </Form.Group>
                            </>}
                        </Form.Row>
                        {!isAdvisor && <>               
                        <Form.Row>
                       
                            <Form.Group as={Col}>
                                <Form.Label>Gender</Form.Label>
                                {profile.fullName !== undefined && <>
                                <Form.Control as="select" name="gender" defaultValue={profile.gender}>
                                    <option>Male</option>
                                    <option>Female</option>
                                    <option>Neutral/Non-Binary</option>
                                </Form.Control>
                                </>}
                            </Form.Group>
                           
                            <Form.Group as={Col}>
                                <Form.Label>Marital Status</Form.Label>
                                {profile.fullName !== undefined && <>
                                <Form.Control as="select" name="marital" defaultValue={profile.marital}>
                                    <option>Married</option>
                                    <option>Widowed</option>
                                    <option>Separated</option>
                                    <option>Divorced</option>
                                    <option>Partnered</option>
                                    <option>Single</option>                                    
                                </Form.Control>                         
                                </>}
                            </Form.Group>
                           
                        </Form.Row>   
                         </>}                     
                        <Address
                            register={register}
                            errors={errors}
                            defaultAddress={
                                {
                                    address: profile.address,
                                    city: profile.city,
                                    region: profile.region,
                                    country: profile.country,
                                    zip: profile.zip
                                }

                            } />
                    </Card.Body>
                    <Card.Footer>
                        <Button variant="info" size="sm" type="submit" className="pull-right"><FaTelegramPlane /> Save</Button>{' '}
                    </Card.Footer>
                </Card>
            </Form>
            <br />
            <Form noValidate onSubmit={handleSubmit(onSubmitEmployer)}>
                <Card>
                    <Card.Header>
                        {!isAdvisor && <>Employer Details</>}
                        {isAdvisor && <>Advisor Details</>}
                    </Card.Header>
                    <Card.Body>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>
                                {!isAdvisor && <>Employer</>}
                                 {isAdvisor && <>Company</>}                                    
                                </Form.Label>
                                <Form.Control type="text" name="employer"
                                    placeholder="Employer"
                                    defaultValue={profile.employer}
                                    />
                            
                            </Form.Group>  
                            <Form.Group as={Col}>
                                <Form.Label>Job Title</Form.Label>
                                <Form.Control name="occupation" placeholder="Occupation" defaultValue={profile.occupation} />
                            </Form.Group>                                                      
                        </Form.Row>
                        {!isAdvisor && <>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>Annual Income</Form.Label>
                                <Form.Control type="employer" name="income"
                                    placeholder="$"
                                    defaultValue={profile.income} />
                            </Form.Group>                                                       
                        </Form.Row>  
                        </>}
                        {isAdvisor && <>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>Certifications</Form.Label>
                                <Form.Control type="employer" name="designations"
                                    placeholder="e.g. CEA, CFP, JD" defaultValue={profile.designations} />
                            </Form.Group>                                                       
                        </Form.Row>     
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>Speciality</Form.Label>
                                {profile.fullName !== undefined && <>
                                <Form.Control as="select" name="specialty" defaultValue={profile.speciality}>
                                    <option value="advisor_planner">Estate Advisor / Planner </option>
                                    <option value="lawyer_attorney">Estate Lawyer / Tax Attorney </option>
                                    <option value="advisor_tax">Financial Advisor / Planner</option>
                                    <option value="agent_broker">Insurance Agent / Broker</option>
                                    <option value="wealth_consultant">Wealth Manager / Consultant</option>      
                                    <option value="other">Other</option>                                      
                                </Form.Control>  
                                </>}

                            </Form.Group>                                                       
                        </Form.Row>   
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>Website</Form.Label>
                                <Form.Control type="input" name="website"
                                    placeholder=""  defaultValue={profile.website} />
                            </Form.Group>                                                       
                        </Form.Row>                                               
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>Biography Tagline</Form.Label>
                                <Form.Control type="input" name="bio"
                                    placeholder=""  defaultValue={profile.bio} />
                            </Form.Group>                                                       
                        </Form.Row>  
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>Biography (this will be displayed in the Advisor Locator)</Form.Label>
                                <Form.Control type="input" name="biography" as="textarea" rows={8} 
                                    placeholder="" defaultValue={profile.biography} />
                            </Form.Group>                                                       
                        </Form.Row>                          
                        </>}                                                                      
                    </Card.Body>
                    <Card.Footer>
                    <Button variant="info" size="sm" type="submit"  className="pull-right"><FaTelegramPlane /> Save</Button>
                </Card.Footer>                    
                    </Card>
            </Form>    
            <br />
            {!isAdvisor && <>
        <Form>
            <Card>
                <Card.Header>
                    Profile Privacy Settings
            </Card.Header>
                <Card.Body>

                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Vault</th>
                                <th className="w-20">Connected</th>
                            </tr>
                        </thead>
                        <tbody>
                            {profileConnections && <>
                                {profileConnections.map ((c, i) => {
                                    return <tr key={"connections_" + i}>
                                    <td> {c.vaultName}</td>
                                    <td>  <BootstrapSwitchButton
                                        checked={c.connected}
                                        onlabel='Connected'
                                        offlabel='Not Connected'
                                        size="sm"
                                        onstyle="success"
                                        width={150}
                                        onChange={(checked) => { 
                                            console.log(checked);
                                            console.log(c);
                                            onConnectionChange(c._id, checked);
                                        }}
                                    />
                                    </td>
                                </tr>
                                })}
                            </>}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </Form> 
        </>}
        </Col>
            <Col>
               <Card>
                    <Card.Header>
                        Profile Image
                    </Card.Header>
                    <Card.Body>
                        <ProfileImage profileImage={profile.profileImage} name={profile.fullName} key={profileImageKey} />
                        <input type='file' id='file' ref={inputFile} style={{ display: 'none' }} onChange={handleInputFileChange} />
                        <Button variant="info" size="sm" className="pull-right" onClick={onChangeImageClick}>Change Profile Image</Button>{' '}
                    </Card.Body>
                </Card>
                {isAdvisor && <> 
                <br />
                <Tips contentKey="advisor_profile" />
                </>}
            </Col>
        </Row>
        <Toaster setShowSuccessToast={setShowSuccessToast} toasterMessage={toasterMessage} showSuccessToast={showSuccessToast} />
    </>
}

export default UserProfile;