import {Button, Card, Modal} from 'react-bootstrap';
import ProfileImage from "./ProfileImage";
import { FaTelegramPlane, FaBriefcase, FaInfoCircle } from 'react-icons/fa';
import axios from 'axios';
import { useHistory } from "react-router-dom";
import {GetRelationshipLabel2} from './../../Utils.js';
import {  useState,  } from 'react';
function AdvisorCard (props) {
    const {connection, vault} = props;
    const history = useHistory();
    const [showFullInfo, setShowFullInfo] = useState(false);
    
    const onSubmit = (e) => {
        const __connection = {
            email: connection.email,
            name: connection.fullName,
            address: connection.address,
            city: connection.city,
            country: connection.country,
            region: connection.region,
            zip: connection.zip,
            type: connection.type,
            phoneNumber: connection.phoneNumber,
            website: connection.website,                 
            relationship: connection.speciality,
            type: "advisor",
            profileImage: connection.profileImage,
            connected:true
        }

        axios.put(`/api/connections?vault=` + vault.id, __connection)
            .then(res => {
                if (res.status === 200) {
                    history.push("/people/" + res.data + "?new")
                }
            })
    }

    return <>
       <Card className={"peopleCard " + connection.type}>
       <Card.Header className={"alittlebitofcss"}>     
            <ProfileImage profileImage={connection.profileImage} name={connection.fullName} />
            <div style={{marginTop:"8px"}}>
            {connection.fullName} <FaBriefcase />
            <div style={{fontSize:'.8em', marginTop:'-2px'}}>{connection.designations}</div>
            </div>
            </Card.Header>
        <Card.Body>
            <Card.Text>
                {GetRelationshipLabel2(connection.speciality)}<br />
                Phone: {connection.phoneNumber}<br />
                Email: {connection.email}
            </Card.Text>
            <Card.Text>
            {connection.bio}
            </Card.Text>
        </Card.Body>
          <Card.Footer>
            <Button variant="info" onClick={() => onSubmit(false)} size="sm"><FaTelegramPlane /> Add as Advisor</Button>{' '} 
            {connection.biography && connection.biography !== "" && <>
            <Button variant="info" onClick={() => setShowFullInfo(true)} size="sm"><FaInfoCircle /> More Info</Button>
            </>}
        </Card.Footer>
        </Card>
        {connection.biography && connection.biography !== "" && <>
        <Modal
            size="lg"
            show={showFullInfo}
            onHide={() => setShowFullInfo(false)}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                {connection.fullName}'s Full Biography
          </Modal.Title>
            </Modal.Header>
            <Modal.Body>
           
            {connection.biography.split("\n").map((i,key) => {
                return <div key={key}>{i}<br /></div>;
            })}
           
            
            </Modal.Body>
        </Modal>     
        </>}        
</>
}

export default AdvisorCard;