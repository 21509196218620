import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { FaPencilAlt } from 'react-icons/fa';
import { Link } from "react-router-dom";
import { GetDocumentTypeLabel } from './../../Utils.js';

function GetDocumentTypeIcon (type){
  let iconName = "";
  switch(type){
    case 'birth':
      iconName = 'fa fa-certificate';
      break;
    case 'passport':
      iconName = 'fas fa-passport';
      break;
    case 'sin':
      iconName = 'fa fa-hashtag';
      break;
    case 'drivers':
      iconName='fas fa-steering-wheel';
      break;
    case 'marriage':
      iconName = 'fas fa-rings-wedding';
      break;
    case 'divorce':
      iconName = 'fas fa-not-equal';
      break;
    case 'will':
      iconName = 'fas fa-feather-alt';
      break;
    case 'dnr':
      iconName = 'fa fa-heartbeat';
      break;
    case 'power':
      iconName = 'fa fa-gavel';
      break;
    case 'prenuptial':
      iconName = 'fas fa-bells';
      break;
    case 'tax':
      iconName='fas fa-stamp';
      break;
    default:
      iconName = 'fa fa-file-pdf';
  }
  return iconName + " fa-lg";
}

function FileCard(props) {
    const {name, type, _id} = props.document;
    const isAddPrompt = _id === undefined;
   
   return <>
       <Card className={(isAddPrompt ? 'ghosted' : '')}>
       <Card.Header>
       <div className="specialAccountCircle"><i className={GetDocumentTypeIcon(type)} /></div><div style={{ float: "left", marginTop:"5px" }}> {name} {!isAddPrompt && <><br /><span style={{ fontWeight:"400"}}>{GetDocumentTypeLabel(type)}</span></>}</div>
        </Card.Header>
        <Card.Footer>
            {isAddPrompt && <Button variant="info" className="pull-right" as={Link} to={"/documents/new?type=" + type}  size="sm"><FaPencilAlt /> Add</Button>}
            {!isAddPrompt && <Button variant="info" className="pull-right" as={Link} to={"/documents/" + _id}  size="sm"><FaPencilAlt /> View</Button>}
        </Card.Footer>
        </Card>
</>
}

export default FileCard;