export function GetDesignationLabel(label) {
    switch (label) {
        case "xv": return "XV Proxy";
        case "survivor": return "Survivor";
        case "power": return "Power of Attorney";
        case "executor": return "Executor";
        case "emergency": return "Emergency Contact";
        case "advisor": return "Advisor/Lawyer";
        default: return "";
    }
}

export function GetAssetTypeLabel(label) {
    switch (label) {
        case "account":  return "Account";
        case "insurance":  return "Insurance";
        case "property":  return "Property";
        case "vehicle":  return "Vehicle";
        case "collectibles": return "Collectible";
        default: return label;
    }
}

export function GetAssetTypeColor(label) {
    switch (label) {
        case "account":  return "#C30000";
        case "insurance":  return "#F96B02";
        case "property":  return "#42CD96";
        case "vehicle":  return "#3C3681";
        case "collectibles": return "#FFAB06";
        default: return "#efefef";
    }
}


export function GetConnectionTypeLabel(label) {
    switch (label) {
        case "beneficiary": return "Beneficiary";
        case "advisor": return "Advisor";
        case "dependent": return "Dependent";
        case "charity": return "Charity";
        case "contact": return "Contact";
        default: return label;
    }
}

export function GetDocumentTypeLabel(label) {
    switch (label) {
        case "birth": return "Birth Certificate";
        case "passport": return "Passport";
        case "sin": return "SSN#/SIN#";
        case "drivers": return "Drivers License";
        case "marriage": return "Marriage License";
        case "divorce": return "Divorce Papers";
        case "will": return "Will / Trust";
        case "dnr": return "Living Will / DNR";
        case "power": return "Power of Attorney";
        case "tax": return "Tax Returns";
        case "prenuptial": return "Prenuptial";
        case "passwords": return "Important Online Accounts"
        case "other": return "Other";
        default: return label;
    }
}

export const designationOptions = [
    { label: 'Business Successor', value: 'BusinessSuccessor' },
    { label: 'Digital Guardian',value:'DigitalGuardian'},
    { label: 'Emergency Contact',  value:'EmergencyContact'},
    { label: 'Eulogist',value: 'Eulogist' },
    { label: 'Executor', value: 'Executor' },
    { label: 'Health Care Advocate',  value: 'HealthCareAdvocate' },
    { label: 'Power of Attorney', value: 'PowerAttorney' },
    { label: 'Survivor', value: 'Survivor' },
    { label: 'XV Proxy',  value: 'Proxy' },
  ]
  

export function GetSubTypeLabel (label){
    switch (label){
        case "cash": return "Cash";
        case "building": return "Building / Commercial Space";
        case "condo": return "Condo / Apartment";
        case "checking": return "Checking";
        case "savings": return "Savings";
        case "bank": return "Bank Account";
        case "crypto": return "Crypto and Bitcoin";
        case "mutualfunds": return "Mutual Funds";
        case "retirement": return "Retirement Funds";
        case "shares": return "Shares and Holdings";
        case "stocks": return "Stocks and Bonds";
        case "annuity": return "Annuity Contract";
        case "final": return "Final Expense Policy";
        case "life": return "Life Insurance Policy";
        case "preneed": return "Preneed Plan";
        case "cottage": return "Cottage / Cabin";
        case "farm": return "Farm";
        case "garage": return "Garage / Warehouse";
        case "house": return "House";
        case "land": return "Land / Lot";
        case "marina": return "Marina Dock / Boat Slip";
        case "timeshare": return  "Timeshare / Vacation Property";
        case "bicycle": return "Bicycle";
        case "boat": return "Boat";
        case "car": return "Car";
        case "motorcycle": return "Motorcycle";
        case "plane": return "Plane";
        case "rv": return "RV or Camper";
        case "snowmobile": return  "Snowmobile";
        case "tractor": return "Tractor";
        case "truck": return  "Truck";
        case "suv": return  "Van / SUV"; 
        case "artwork": return "Artwork";
        case "books": return "Books and Rare Volumes";
        case "clothing": return "Clothing";
        case "coins": return  "Coins / Stamp Collections";
        case "decor": return "Decor / Furnishings";
        case "gadgets": return  "Gadgets / Electronics";
        case "music": return  "Instruments / Music Library";        
        case "jewelry": return  "Jewelry / Watches";       
        case "sporting": return "Sporting Gear / Sports Memorabilia'";
        case "tools": return  "Tools / Equipment";
        case "toys": return  "Toys / Figurines";        
        case "vintages": return  "Vintages or Spirits";     
        default: return label;
    }
}

export function GetSubTypeImage (label){
    switch (label){
        case "cash": return "Investments-Cash.png";
        case "building": return "Properties-BuildingCommercialSpace.png";
        case "condo": return "Properties-CondoApartment.png";
        case "checking": return "Investments-Checking.png";
        case "savings": return "Investments-Savings.png";
        case "bank": return "Investments-Savings.png";
        case "crypto": return "Investments-CryptoBitcoin.png";
        case "mutualfunds": return "Investments-MutualFunds.png";
        case "retirement": return "Investments-RetirementFunds.png";
        case "shares": return "Investments-SharesHoldings.png";
        case "stocks": return "Investments-StocksBonds.png";
        case "annuity": return "Policies-AnnuityContract.png";
        case "final": return "Policies-FinalExpensePolicy.png";
        case "life": return "Policies-LifeInsurancePolicy.png";
        case "preneed": return "Policies-PreneedPlan.png";
        case "cottage": return "Properties-CottageCabin.png";
        case "farm": return "Properties-Farm.png";
        case "garage": return "Properties-GarageWarehouse.png";
        case "house": return "Properties-House.png";
        case "land": return "Properties-LandLot.png";
        case "marina": return "Properties-MarinaDockBoatSlip.png";
        case "timeshare": return   "Properties-House.png";
        case "bicycle": return "Vehicles-Bicycle.png";
        case "boat": return "Vehicles-Boat.png";
        case "car": return "Vehicles-Car.png";
        case "motorcycle": return "Vehicles-Motorcycle.png";
        case "plane": return "Vehicles-Plane.png";
        case "rv": return "Vehicles-RVCamper.png";
        case "snowmobile": return  "Vehicles-Snowmobile.png";
        case "tractor": return "Vehicles-Tractor.png";
        case "truck": return  "Vehicles-Truck.png";
        case "suv": return  "Vehicles-VanSUV.png"; 
        case "artwork": return "Collectibles-Artwork.png";
        case "books": return "Collectibles-BooksRareVolumes.png";
        case "clothing": return "Collectibles-Clothing.png";
        case "coins": return  "Collectibles-CoinsStampCollections.png";
        case "decor": return "Collectibles-DecorFurnishings.png";
        case "gadgets": return  "Collectibles-GadgetsElectronics.png";
        case "music": return  "Collectibles-InstrumentsMusicLibrary.png";        
        case "jewelry": return  "Collectibles-JewelryWatches.png";       
        case "tools": return  "Collectibles-ToolsEquipment.png";
        case "toys": return  "Collectibles-ToysFigurines.png";        
        case "vintages": return  "Collectibles-VintagesSpirits.png";     
        case "sporting": return "Collectibles-SportingGearSportsMemorabilia.png";
        default: return "placeholder-assets.png";
    }
}

export function GetRelationshipLabel(label) {
    switch (label) {
        case "spouse": return "Spouse";
        case "child": return "Child";
        case "friend": return "Friend";
        case "family": return "Family Member";
        case "partner": return "Partner";
        case "sibling": return "Sibling";
        case "parent": return "Parent";
        case "charity": return "Charity";
        case "medical": return "Medical Doctor";
        case "lawyer": return "Estate Lawyer";
        case "otheradvisor": return "Advisor";
        case "advisor": return "Financial Advisor";
        case "insurance": return "Insurance Agent";
        case "planner": return "Financial Planner";
        case "agent": return "Insurance Agent";        
        case "wealth": return "Wealth Manager";
        case "tax": return "Tax Attorney";         
        case "elder": return "Elder Parent";
        case "minor": return "Minor Child";        
        case "pet": return "Pet";
        case "special": return "Special Needs";               
        default: return label;
    }
}

export function GetRelationshipLabel2 (label){
    switch (label) {
        case "advisor_planner": return "Estate Advisor / Planner";
        case "lawyer_attorney": return "Estate Lawyer / Tax Attorney";
        case "advisor_tax": return "Financial Advisor / Planner";
        case "agent_broker": return "Insurance Agent / Broker";
        case "wealth_consultant": return "Wealth Manager / Consultant";
        default: return label;
    }
}

export function GetVaultAccessLabel(permissions) {
    if (permissions !== undefined) {
        if (permissions.editVault) {
            return "Edit"
        }
        else if (permissions.viewVault) {
            return "View Only"
        }
    }
    return "No Access"
}

export function GetCurrencyFormatted(value) {
    if (value !== undefined && value !== null) {
        const valueWithDecimals = value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        const decimalPointer = valueWithDecimals.indexOf('.');
        return valueWithDecimals.substring(0, decimalPointer); 
    }
}

 export const isNumber = value => typeof value === 'number' && value === value && value !== Infinity && value !== -Infinity

 export const documentTypeLabels = ["birth", "passport", "sin", "drivers", "marriage", "divorce", "will", "dnr", "power", "tax", "prenuptial", "passwords", "other"];

 export const  GetIconForAssetCategory = (assetCategory) => {
    let iconName = 'fa fa-gem';
    console.log(assetCategory);
    switch (assetCategory) {
        case 'vehicle':
            iconName = 'fa fa-car';
            break;
        case 'Properties':
            iconName = 'fa fa-map-marker-alt';
            break;
        case 'Policies':
            iconName = 'fa fa-umbrella';
            break;
        case 'Investments':
            iconName = 'fas fa-coins';
            break;
        case 'Collectibles':
            iconName = 'fa fa-treasure-chest'
            break;
        default:
            iconName = 'fa fa-gem';
    }
    return iconName;
}

export function GetAssetTypeIcon(label) {
    switch (label) {
        case "account":  return "Account";
        case "insurance":  return "Insurance";
        case "property":  return "Property";
        case "vehicle":  return "Vehicle";
        case "collectibles": return "Collectible";
        default: return label;
    }
}


export function GetDocumentTypeIcon (type){
    let iconName = "";
    switch(type){
      case 'birth':
        iconName = 'fa fa-certificate';
        break;
      case 'passport':
        iconName = 'fas fa-passport';
        break;
      case 'sin':
        iconName = 'fa fa-hashtag';
        break;
      case 'drivers':
        iconName='fas fa-steering-wheel';
        break;
      case 'marriage':
        iconName = 'fas fa-rings-wedding';
        break;
      case 'divorce':
        iconName = 'fas fa-not-equal';
        break;
      case 'will':
        iconName = 'fas fa-feather-alt';
        break;
      case 'dnr':
        iconName = 'fa fa-heartbeat';
        break;
      case 'power':
        iconName = 'fa fa-gavel';
        break;
      case 'prenuptial':
        iconName = 'fas fa-bells';
        break;
      case 'tax':
        iconName='fas fa-stamp';
        break;
      default:
        iconName = 'fa fa-file-pdf';
    }
    return iconName;
  }
  