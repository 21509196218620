import { CardGroup, Card, Form, Row, Col, InputGroup, Alert } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { FaUserAlt, FaLock } from 'react-icons/fa';
import { useHistory } from "react-router-dom";
import { useAuth } from "../../providers/auth-provider";
import { useState } from 'react';

function ForgotPassword(props) {
    const { forgotpassword } = useAuth();
    const history = useHistory();
    const [loginErrorText, setLoginErrorText] = useState("");

    const onSubmit = (e) => {
        e.preventDefault();
        forgotpassword({ "email": e.target["email"].value })
        .then(res => {
            if (res.status === 200) {
                setLoginErrorText("Please check your email for instructions how to reset your password.")
            }
            else
            {
                res.json()
                .then(error => {
                    if (error.msg.length)
                    setLoginErrorText(error.msg);
                })
        
            }
        })        
    }    
    return <>
        <CardGroup>
            <Card className="p-4" md={8}>
                <Card.Body>
                    <Form onSubmit={onSubmit}>
                        <h3>Forgot your password?</h3>
                        <p className="text-muted">On submission, a email will be sent with a reset password link.</p>
                        {loginErrorText !== "" && <Alert key='alertmessage' variant='danger'>
                            {loginErrorText} <br />
                        </Alert>}
                        <Form.Row>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text><FaUserAlt /></InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control name="email" placeholder="Email Address" />
                            </InputGroup>
                        </Form.Row>
                        <Row>
                            <Col>
                                <Button variant="info" size="sm" type="submit" className="pull-right">Send email</Button>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
        </CardGroup>
    </>
}

export default ForgotPassword;