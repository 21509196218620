import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { FaTelegramPlane, FaCreditCard } from 'react-icons/fa';
import { GetCurrencyFormatted } from './../../../Utils.js';
import { Link } from "react-router-dom";

function LiabilitiesCard(props) {
    const {liability} = props;
    return <>
       <Card>
       <Card.Header>
           <FaCreditCard />{' '} {liability.name}
            </Card.Header>
        <Card.Body>
            <Card.Text style={{fontSize:'.8em'}}>
               Total Estimated Liability: $ {GetCurrencyFormatted(liability.amountOwing)}<br />
               </Card.Text>
        </Card.Body>
          <Card.Footer>
          <Button variant="info" as={Link} to={"/liabilities/" + liability._id} className="pull-right" size="sm"><FaTelegramPlane /> View</Button>
        </Card.Footer>
        </Card>
</>
}

export default LiabilitiesCard;