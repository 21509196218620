import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom'
import logo from '../../xv-logo.png';
import { useContext, useState, useEffect } from 'react';
import AppContext from './AppContext';
import { FaAngleRight } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

function Header(props) {
  const { vault, user, setVault, setVaultAccessMode } = useContext(AppContext);
  const [expanded, setExpanded] = useState(false);
  const [vaultNameDisplay, setVaultNameDisplay] = useState("My");

  const [vaultDropdowns, setVaultDropdowns] = useState([]);
  const isAdvisor = user.role === "advisor";
  const location = useLocation();
  let hideNavBarFromAdvisor = !(isAdvisor && location.pathname === "/profile");

  useEffect(() => {
    if (vault.name === undefined) return;

    if (!isAdvisor) {
      axios.get(`/api/profiles?options=readVaultList`, {
        params: {
          profileId: user.id,
        }
      })
        .then(res => {
          setVaultDropdowns(res.data);
        })
        .catch(function (error) {
          console.log(error);
        })
    }

    const _vaultName = user.id === vault.ownerId ? "My" : vault.name;
    setVaultNameDisplay(_vaultName);

    

  }, [vault]);

  return <>
    <Navbar bg="dark" variant="dark" expand="lg" className="mainNavigationBar" expanded={expanded}>
      <Navbar.Brand>
          <img
            src={logo}
            width="120"
            className="d-inline-block align-top"
            alt="Logo"
          />
       </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(expanded ? false : "expanded")} />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">

        {hideNavBarFromAdvisor && <>
            <Nav.Link disabled as={NavLink} exact to="/" href="/" onClick={() => setExpanded(false)}>{vaultNameDisplay} <FaAngleRight />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Nav.Link>
            <Nav.Link as={NavLink} className="topHeaderLink" exact to="/" href="/" onClick={() => setExpanded(false)}>Essentials</Nav.Link>
            <Nav.Link as={NavLink} className="topHeaderLink" exact to="/people" href="/people" onClick={() => setExpanded(false)}>Connections</Nav.Link>
            <Nav.Link as={NavLink} className="topHeaderLink" exact to="/assets" href="/assets" onClick={() => setExpanded(false)}>Assets</Nav.Link>
            <Nav.Link as={NavLink} className="topHeaderLink" exact to="/report" href="/report" onClick={() => setExpanded(false)}>Reports</Nav.Link>
        </>}
        </Nav>
        <Nav className="dropdown-menu-right">
          <NavDropdown title={"Hi " + user.fullName + "!"} className="dropdown-menu-right">
            <NavDropdown.Item as={NavLink} exact to="/profile" href="/profile" onClick={() => setExpanded(false)}>Profile</NavDropdown.Item>

            {!isAdvisor  && vaultDropdowns.length > 0 &&  <>
             {vaultDropdowns.map(function (r, index) {
               if(r.name !== "My") return;

               return <NavDropdown.Item as={NavLink} exact to={"/" + r.vault} href={"/" + r.vault}
                  onClick={() => { setExpanded(false); setVault({ name: "My Vault", id: r.vault, ownerId: r.owner }); }}>
                  {"My Vault"}</NavDropdown.Item>
              })}           
            </>}

            {isAdvisor && <>
              <NavDropdown.Item as={NavLink} exact to="/advisor" href="/advisor" onClick={() => setExpanded(false)}>Clients</NavDropdown.Item>
            </>}

            {!isAdvisor  && vaultDropdowns.length > 1 &&  <>
              <NavDropdown.Divider />
              <div style={{fontSize:"14px", marginLeft:"5px"}}> --- Connected Vaults ---</div>
             {vaultDropdowns.map(function (r, index) {
               if(r.name === "My") return;
                return <NavDropdown.Item as={NavLink} exact to={"/" + r.vault} href={"/" + r.vault}
                  onClick={() => { setExpanded(false); setVault({ name: r.name, id: r.vault, ownerId: r.owner }); }}>
                  {r.name}</NavDropdown.Item>
              })} 
              <NavDropdown.Divider />
            </>}

            <NavDropdown.Item as={NavLink} exact to="/logout" href="/logout" onClick={() => setExpanded(false)}>Logout</NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  </>
}

export default Header;