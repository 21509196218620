import {
  Form,
  Row,
  Col,
  Card,
  Button,
  Modal,
  Badge,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { FaTelegramPlane } from "react-icons/fa";
import { useForm } from "react-hook-form";
import AppContext from "./../AppContext";
import Attachments from "./../Attachments";
import { IoMdSettings } from "react-icons/io";
import axios from "axios";
import Toaster from "./../Toaster";
import { useLocation } from "react-router";
import {
  GetDocumentTypeLabel,
  documentTypeLabels,
  GetDocumentTypeIcon,
} from "./../../../Utils.js";

function DocumentsDetails(props) {
  const documentProfile = props.match.params.id;
  const { vaultAccessMode, vault } = useContext(AppContext);
  const [deleteDcoumentsConfirmation, setDeleteDcoumentsConfirmation] =
    useState(false);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [toasterMessage, setToasterMessage] = useState({
    title: "",
    message: "",
  });
  const [showHistory, setShowHistory] = useState(false);
  const [isEditing, setIsEditing] = useState(documentProfile !== "new");
  const [document, setDocument] = useState();
  const [locations, setLocations] = useState(null);
  const { register, handleSubmit, errors } = useForm({ criteriaMode: "all" });
  const history = useHistory();
  const location = useLocation();

  const handleDelete = () => {
    setDeleteDcoumentsConfirmation(false);
    axios
      .delete(
        `/api/documents?vault=` + vault.id + `&document=` + documentProfile
      )
      .then((res) => {
        if (res.status === 200) {
          history.push("/documents/");
        }
      });
  };

  const onSubmit = (data, e) => {
    const _isEditing = documentProfile !== "new";
    setIsEditing(_isEditing);
    console.log(_isEditing);

    if (_isEditing) {
      axios
        .post(
          `/api/documents?vault=` + vault.id + `&document=` + documentProfile,
          data
        )
        .then((res) => {
          if (res.status === 200) {
            setToasterMessage({
              title: "Success!",
              message: "Documents Details Updated",
            });
            setShowSuccessToast(true);
          }
        });
    } else {
      axios.put(`/api/documents?vault=` + vault.id, data).then((res) => {
        console.log(res);
        if (res.status === 200) {
          setIsEditing(true);
          history.push("/documents/" + res.data + "?new");
        }
      });
    }
  };

  const viewFileCallBack = (fileKey) => {
    axios
      .get(`/api/documents?options=attachFile`, {
        params: {
          vault: vault.id,
          document: documentProfile,
          filekey: fileKey,
        },
      })
      .then((res) => {
        var win = window.open(res.data, "_blank");
        win.focus();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const uploadCallBack = async (acceptedFiles) => {
    const files = [];

    for (const file of acceptedFiles) {
      files.push({ name: file.name, type: file.type });
    }

    axios
      .post(
        `/api/documents?options=attachFile&vault=` +
          vault.id +
          `&document=` +
          documentProfile,
        files,
        {}
      )
      .then(function (result) {
        result.data.forEach(function (url, i) {
          var options = { headers: { "Content-Type": acceptedFiles[i].type } };
          return axios.put(result.data[i], acceptedFiles[i], options);
        });
      })
      .then(function (results) {
        axios
          .get(`/api/documents`, {
            params: {
              vault: vault.id,
              document: documentProfile,
            },
          })
          .then((res) => {
            setDocument(res.data);
          })
          .catch(function (error) {
            console.log(error);
          });
      });
  };

  const deleteFileCallback = (filekey) => {
    axios.delete(
      `/api/documents?options=attachFile&vault=` +
        vault.id +
        `&document=` +
        documentProfile +
        `&filekey=` +
        filekey
    );
  };

  useEffect(() => {
    if (documentProfile === "new") {
      let type = "";
      if (location.search.includes("?type") && location.search.length > 5) {
        type = location.search.substring(6);
      }
      setDocument({ type: type });
      return;
    }

    if (location.search === "?new") {
      setToasterMessage({
        title: "Success!",
        message: "New Document Collection Created",
      });
      setShowSuccessToast(true);
    }

    axios
      .get(`/api/documents`, {
        params: {
          vault: vault.id,
          document: documentProfile,
        },
      })
      .then((res) => {
        setDocument(res.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [isEditing]);

  const handleFileSelect = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (locations === null) {
      axios
        .get(`/api/locations`, {
          params: {
            vault: vault.id,
          },
        })
        .then((res) => {
          setLocations(res.data.locations);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, []);

  return (
    <>
      <Modal
        size="sm"
        show={deleteDcoumentsConfirmation}
        onHide={() => setDeleteDcoumentsConfirmation(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Are you sure you want to delete this Document Collection?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <Button
            variant="info"
            size="sm"
            className="pull-right"
            onClick={() => {
              handleDelete();
            }}
          >
            {" "}
            Delete Documents
          </Button>
        </Modal.Body>
      </Modal>
      <Modal
        show={showHistory}
        onHide={() => setShowHistory(false)}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Audit History</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            {document &&
              document.audit !== undefined &&
              document.audit.reverse().map(function (note, index) {
                return (
                  <span key={note.timestamp}>
                    {note.user} <Badge variant="info">{note.action}</Badge>{" "}
                    {note.timestamp}
                    <br />
                  </span>
                );
              })}
          </>
        </Modal.Body>
        <Modal.Footer>
          <small>
            Note: Does not include audit history recently completed.
          </small>
        </Modal.Footer>
      </Modal>

      <Row>
        <Col>
          <Form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Card>
              {isEditing && (
                <>
                  <Card.Header>
                    {document !== undefined && (
                      <>
                        <div className="specialAccountCircle">
                          <i className={GetDocumentTypeIcon(document.type)} />
                        </div>
                      </>
                    )}
                    {document ? document.name : ""}
                    <DropdownButton
                      variant="icon"
                      className="pull-right"
                      id="dropdown-basic-button"
                      title={<IoMdSettings />}
                    >
                      <Dropdown.Item onClick={() => setShowHistory(true)}>
                        View History
                      </Dropdown.Item>
                      {!vaultAccessMode.isReadOnly && (
                        <Dropdown.Item
                          onClick={() => setDeleteDcoumentsConfirmation(true)}
                        >
                          Remove Document Collection
                        </Dropdown.Item>
                      )}
                    </DropdownButton>
                  </Card.Header>
                </>
              )}
              {!isEditing && (
                <>
                  <Card.Header>Document Collection Details</Card.Header>
                </>
              )}
              <Card.Body>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Nickname</Form.Label>
                    <Form.Control
                      name="name"
                      defaultValue={document ? document.name : ""}
                      placeholder=""
                      ref={register({
                        required: true,
                        maxLength: 80,
                      })}
                      isInvalid={!!errors.name}
                    />
                    <Form.Control.Feedback type="invalid" tooltip>
                      {errors.name && "Name is required"}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Document Type</Form.Label>
                    {document !== undefined && (
                      <>
                        <Form.Control
                          as="select"
                          name="type"
                          defaultValue={document.type}
                          ref={register()}
                        >
                          <option value=""></option>
                          {documentTypeLabels.map(function (label, index) {
                            return (
                              <option key={label + index} value={label}>
                                {GetDocumentTypeLabel(label)}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </>
                    )}
                  </Form.Group>
                </Form.Row>
                <Form.Row></Form.Row>
                <Form.Row></Form.Row>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Location of Original Documents</Form.Label>
                    {document !== undefined && locations !== null && (
                      <>
                        <Form.Control
                          as="select"
                          name="locationId"
                          defaultValue={document.locationId}
                          ref={register()}
                        >
                          <option value="None">None Selected</option>
                          {locations !== null && (
                            <>
                              {locations.map(function (location, index) {
                                return (
                                  <option
                                    key={location._id}
                                    value={location._id}
                                  >
                                    {location.name}{" "}
                                    &#160;&#160;&#160;&#160;&#160;(
                                    {location.address}, {location.city})
                                  </option>
                                );
                              })}
                            </>
                          )}
                        </Form.Control>
                      </>
                    )}
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Notes</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      ref={register()}
                      defaultValue={
                        document !== undefined ? document.notes : ""
                      }
                      name="notes"
                    />
                  </Form.Group>
                </Form.Row>
              </Card.Body>
              {!vaultAccessMode.isReadOnly && (
                <Card.Footer>
                  <Button
                    variant="info"
                    type="submit"
                    size="sm"
                    className="pull-right"
                  >
                    <FaTelegramPlane /> Save
                  </Button>
                </Card.Footer>
              )}
            </Card>
          </Form>
        </Col>
        <Col md="4">
          {isEditing && (
            <>
              <Attachments
                isEditing={isEditing}
                isReadOnly={vaultAccessMode.isReadOnly}
                uploadCallBack={uploadCallBack}
                deleteFileCallback={deleteFileCallback}
                viewFileCallBack={viewFileCallBack}
                originalFiles={document && document.files}
              />
              <br />
            </>
          )}
          {/*  <Tips>Some quick example text to build on the card title and make up the bulk of the card's content.</Tips> */}
        </Col>
      </Row>
      <Toaster
        setShowSuccessToast={setShowSuccessToast}
        toasterMessage={toasterMessage}
        showSuccessToast={showSuccessToast}
      />
    </>
  );
}

export default DocumentsDetails;

