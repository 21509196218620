import { Nav, Row, Col } from 'react-bootstrap';
import { Switch, Route, Link } from "react-router-dom";
import Locations from './Vault/Locations';
import Dashboard from './Vault/Dashboard';
import Documents from './Vault/Documents';
import Accounts from './Vault/Accounts';
import AccountDetails from './Vault/AccountDetails';
import DocumentsDetails from './Vault/DocumentsDetails';
import Liabilities from './Vault/Liabilities.js';
import LiabilityDetails from './Vault/LiabilityDetails';
import Wishes from './Vault/Wishes';
import { useLocation } from 'react-router-dom';
import { FaMap, FaFileAlt, FaSpa, FaMinusCircle } from 'react-icons/fa';
import { RiAccountBoxLine } from 'react-icons/ri';

function Vault(props) {
    const location = useLocation();
    return <>
        <Row style={{ marginBottom: '20px' }}>
            <Col>
                <Nav justify variant="tabs" activeKey={location.pathname}>
                    <Nav.Item>
                        <Nav.Link as={Link} to="/locations" eventKey="/locations"><FaMap /> Locations</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as={Link} to="/documents" eventKey="/documents"><FaFileAlt /> Documents</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as={Link} to="/wishes" eventKey="/wishes"><FaSpa /> Arrangements</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as={Link} to="/liabilities" eventKey="/liabilities"><FaMinusCircle /> Liabilities</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as={Link} to="/accounts" eventKey="/accounts"><RiAccountBoxLine /> Logins</Nav.Link>
                    </Nav.Item>
                </Nav>
            </Col>
        </Row>
        <Switch>
            <Route path={`/documents/:id`} component={DocumentsDetails} />AccountDetails
            <Route path={`/documents`} component={Documents} />
            <Route path={`/wishes`} component={Wishes} />
            <Route path='/accounts/:id' component={AccountDetails} />
            <Route path={`/accounts`} component={Accounts} />
            <Route path={`/locations`} component={Locations} />
            <Route path='/liabilities/:id' component={LiabilityDetails} />
            <Route path='/liabilities' component={Liabilities} />
            <Route path='/' component={Dashboard} />
        </Switch>
    </>
}

export default Vault;