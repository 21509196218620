import { Col, Row, Modal, Button, Form } from 'react-bootstrap';
import LocationCard from './LocationCard';
import Tips from './../Tips';
import Address from './../Address';
import axios from 'axios';
import AppContext from './../AppContext';
import { useContext, useEffect, useState } from "react";

function Locations(props) {

    const [showLocation, setShowLocation] = useState(false);
    const [locations, setLocations] = useState([]);
    const [editLocation, setEditLocation] = useState({});
    const [locationDirty, setLocationDirty] = useState(true);
    const { vault, vaultAccessMode } = useContext(AppContext);

    const triggerDelete = () => {
        axios.delete(`/api/locations?vault=` + vault.id + "&location=" + editLocation._id)
            .then(res => {
                if (res.status === 200) {
                    setShowLocation(false);
                    setLocationDirty(true);
                }
            });
    }

    const onSubmit = (e) => {
        e.preventDefault();

        const _location = {
            address: e.target.address.value,
            city: e.target.city.value,
            country: e.target["rcrs-country"].value,
            region: e.target["rcrs-region"].value,
            zip: e.target.zip.value,
            name: e.target.nickname.value,
            isPrimary: e.target.isPrimary.checked,
            notes: e.target.notes.value
        };


        if (editLocation._id === undefined || editLocation._id === "") {

            axios.put(`/api/locations?vault=` + vault.id, _location)
                .then(res => {
                    if (res.status === 200) {
                        setShowLocation(false);
                        setLocationDirty(true);
                    }
                });
        } else {
            _location._id = editLocation._id;
            axios.post(`/api/locations?vault=` + vault.id, _location)
                .then(res => {
                    if (res.status === 200) {
                        setShowLocation(false);
                        setLocationDirty(true);
                    }
                });
        }
    }

    const EditLocationCallBack = (location) => {
        setShowLocation(true);
        setEditLocation(location);
    }

    useEffect(() => {
        if (locationDirty) {
            axios.get(`/api/locations`, {
                params: {
                    vault: vault.id
                }
            })
                .then(res => {
                    setLocations(res.data.locations);
                    setLocationDirty(false);
                })
                .catch(function (error) {
                    console.log(error);
                });
        }

    }, [locationDirty]);

    return <>
        <Tips contentKey="locations" /><br />
        {!vaultAccessMode.isReadOnly && 
        <Row>
            <Col>
                <Button variant="info" onClick={() => { setEditLocation({}); setShowLocation(true) }} size="sm" className="pull-right"> Add a Location</Button>
            </Col>
        </Row>}
        <br />

        <Row className="row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-3 row-cols-xl-3">
            <>
                {locations.map(function (location, index) {
                    return <Col key={location._id + " " + location.name} className="mb-3"><LocationCard location={location} isReadOnly={vaultAccessMode.isReadOnly} setShowLocation={EditLocationCallBack} /></Col>
                })}
            </>
        </Row>


        <Modal
            show={showLocation}
            onHide={() => setShowLocation(false)}
            centered
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Location
                </Modal.Title>
            </Modal.Header>
            <Form noValidate onSubmit={onSubmit}>
                <Modal.Body>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Nickname</Form.Label>
                            <Form.Control name="nickname" defaultValue={editLocation.name} placeholder="An unique name for the property" />
                        </Form.Group>
                    </Form.Row>
                    <Address register={() => void 0} errors={{}} defaultAddress={editLocation} />

                    <Row style={{ marginTop: "20px", marginBottom:"20px" }}>
                        <Col>
                            <Form.Label>Notes</Form.Label>
                            <Form.Control as="textarea" name="notes" defaultValue={editLocation.notes} rows={8}></Form.Control>
                        </Col>
                    </Row>

                    <Form.Group>
                        <Form.Check type="checkbox" name="isPrimary" id="isPrimaryCheck" defaultChecked={editLocation.isPrimary} label="Is Primary Address" />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" size="sm" className="mr-auto" onClick={() => triggerDelete()}> Delete</Button>
                    <Button variant="info" type="submit" size="sm" className="pull-right"> Save</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    </>
}

export default Locations;