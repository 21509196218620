import { Form, Col, Card, Row, Button, ToggleButton, ToggleButtonGroup, OverlayTrigger, Tooltip, DropdownButton, Dropdown, Modal, Badge } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import { useLocation } from 'react-router';
import { IoMdSettings } from 'react-icons/io';
import { FaTelegramPlane, FaRegEyeSlash, FaRegEye, FaInfoCircle } from 'react-icons/fa';
import { useContext, useState, useEffect } from 'react';
import AssetCard from './AssetCard';
import ProfileImage from './ProfileImage';
import axios from 'axios';
import AppContext from './AppContext';
import Address from './Address';
import { useForm } from "react-hook-form";
import Toaster from './Toaster';
import { GetRelationshipLabel, GetCurrencyFormatted, designationOptions } from './../../Utils.js';
import Select from 'react-select'

function AssetsList(props) {
    const { assets } = { ...props };
    if (assets === undefined) {
        return <Col>There are no assets assigned to this connection.</Col>;
    }
    return (
        <>
            {assets.map(function (asset, index) {
                const totalAssignedValue = "Total Assigned Value: $" + GetCurrencyFormatted(asset.calculatedValue * asset.assignments[0].percentage / 100);
                return <Col key={"assetCard" + index} className="mb-3"><AssetCard asset={asset} showDetails={false} extraInfo={totalAssignedValue} /></Col>
            })}
        </>
    )
}

function Person(props) {
    const { vaultAccessMode, vault } = useContext(AppContext);
    const [profile, setProfile] = useState({ name: "" });
    const [deleteAssetConfirmation, setDeleteAssetConfirmation] = useState(false);
    const [relationship, setRelationship] = useState([]);
    const [showSuccessToast, setShowSuccessToast] = useState(false);
    const [relatedDesignations, setRelatedDesignations] = useState();
    const [showHistory, setShowHistory] = useState(false);
    const [toasterMessage, setToasterMessage] = useState({ title: "", message: "" });
    //const [designations, setDesignations] = useState([]);
    const [showNoteModal, setNoteModal] = useState(false);
    const connectionProfile = props.match.params.id;
    const { register, handleSubmit, errors } = useForm({ criteriaMode: "all" });
    const location = useLocation();
    const history = useHistory();

    const onSubmit = (data, e) => {

        let updatedConnectionProfile = {};

        if (profile.connected) { 
            updatedConnectionProfile = {
            relationship: e.target.Relationship?.value,
            relatedDesignations: relatedDesignations
            }
        }
        else {
            updatedConnectionProfile = {
                email: e.target.email?.value,
                name: e.target.fullName.value,
                address: e.target.address.value,
                city: e.target.city.value,
                phoneNumber: e.target.phoneNumber.value,
                website: e.target.website.value,
                country: e.target["rcrs-country"].value,
                region: e.target["rcrs-region"].value,
                zip: e.target.zip.value,
                relationship: e.target.Relationship?.value,
                relatedDesignations: relatedDesignations
            };
        }


        axios.post(`/api/connections?vault=` + vault.id + `&connection=` + connectionProfile, updatedConnectionProfile)
            .then(res => {
                if (res.status === 200) {
                    setToasterMessage({ title: "Success!", message: "Connection Details Updated" })
                    setShowSuccessToast(true);
                }
            })
    }
    const onDeleteSubmit = () => {
        axios.delete(`/api/connections?vault=` + vault.id + `&connection=` + connectionProfile)
            .then(res => {
                if (res.status === 200) {
                    history.push("/people/");
                }
            })
    };

    const handleRelatedDesignations = (item, key) => {
        setRelatedDesignations(item)
    }

    const onPermissionSubmit = (e) => {
        e.preventDefault();
        const updatedConnectionProfile = {
            permissions: {
                viewAssets: e.target.ViewAssetsToggle.value === 'true',
                viewVault: e.target.ViewVaultToggle.value === 'true',
                /*  editVault: e.target.EditVaultToggle.value === 'true', */
            }
        }

        axios.post(`/api/connections?vault=` + vault.id + `&options=permissions&connection=` + connectionProfile, updatedConnectionProfile)
            .then(res => {
                if (res.status === 200) {
                    setToasterMessage({ title: "Success!", message: "Connection Permissions Updated" })
                    setShowSuccessToast(true);
                }
            })
    }
    const onProfilePropertyChange = (val, field) => {
        profile[field] = val.target.value;
        setProfile(profile);
    }

    const onNotesSubmit = (e) => {
        e.preventDefault();
        const updatedConnectionProfile = {
            notes: profile.notes
        }

        axios.post(`/api/connections?vault=` + vault.id + `&options=notes&connection=` + connectionProfile, updatedConnectionProfile)
            .then(res => {
                if (res.status === 200) {
                    setToasterMessage({ title: "Success!", message: "Connection Notes Updated" })
                    setShowSuccessToast(true);
                    setNoteModal(false);
                }
            })
    }

    useEffect(() => {
        if (location.search === "?new") {
            setToasterMessage({ title: "Success!", message: "New Connections Added" })
            setShowSuccessToast(true);
        }

        axios.get(`/api/connections`, {
            params: {
                vault: vault.id,
                connection: connectionProfile,
                options: "full"
            }
        })
            .then(res => {
                setProfile(res.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        switch (profile.type) {
            case "beneficiary":
                setRelationship(["spouse", "partner", "child", "sibling", "parent", "family", "friend"]);
                break;
            case "advisor":
                setRelationship(["lawyer", "planner", "insurance", "tax", "wealth"]);     
                break;
            case "dependent":
                setRelationship(["elder", "minor", "pet", "special"]);
                break;
            case "contact":
                setRelationship([]);    
                ////setRelationship(["Accountant", "Babysitter", "Bookkeeper", "Branch Manager", "Condo Community Association", "Contractor", "Cleaner", "Doctor", "Electrician", "Gardener", "Landscaper", "Maintenance Repair", "Mechanic", "Property Manager", "Realtor", "Superintendent", "Tenant", "Utility Provider"]);
                break;                
            default:
                setRelationship([]);
        }
    }, [profile.type])

    return <>

        <Row>
            <Col md={9}>
                <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Card className="mb-3" className={"peopleCard " + profile.type}>
                        <Card.Header>
                            <ProfileImage name={profile.name} profileImage={profile.profileImage} /> {profile.name} {profile.connected && <span className="text-muted">({profile.email})</span>}
                            {!vaultAccessMode.isReadOnly && <>
                                <DropdownButton variant="icon" className="pull-right" id="dropdown-basic-button" title={<IoMdSettings />}>
                                    <Dropdown.Item onClick={() => setShowHistory(true)}>View History</Dropdown.Item>
                                    <Dropdown.Item onClick={() => setNoteModal(true)}>View/Add Note</Dropdown.Item>
                                    {!vaultAccessMode.isReadOnly && <Dropdown.Item onClick={() => setDeleteAssetConfirmation(true)}>Remove Connection</Dropdown.Item>}
                                </DropdownButton>
                            </>}
                        </Card.Header>
                        <Card.Body>
                            {!profile.connected && <>
                                <Form.Row>
                                    {profile.type !== "charity" && profile.type !== "dependent" && <>
                                        <Form.Group as={Col}>
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control type="email" name="email"
                                                ref={register({
                                                    required: true, maxLength: 80, pattern: {
                                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                    }
                                                })}
                                                placeholder="Enter email"
                                                isInvalid={!!errors.email}
                                                defaultValue={profile.email} disabled={profile.connected} />
                                            <Form.Control.Feedback type="invalid" tooltip>
                                                {errors.email && "Email is required"}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </>}
                                    <Form.Group as={Col}>
                                        <Form.Label>{profile.type === "charity" ? <>Charity Name</> : <> Full Name</>}</Form.Label>
                                        <Form.Control name="fullName" ref={register} placeholder="First Name Last Name" defaultValue={profile.name} />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>Phone Number</Form.Label>
                                        <Form.Control name="phoneNumber" ref={register} placeholder="Phone Number" defaultValue={profile.phoneNumber} />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Website, Social, or other</Form.Label>
                                        <Form.Control name="website" ref={register} placeholder="Website" defaultValue={profile.website} />
                                    </Form.Group>
                                </Form.Row>
                            </>}
                            {!profile.connected && <Address
                                register={register}
                                errors={errors}
                                defaultAddress={
                                    {
                                        address: profile.address,
                                        city: profile.city,
                                        region: profile.region,
                                        country: profile.country,
                                        zip: profile.zip
                                    }

                                } />}
                            {profile.connected && <>
                                <Form.Label><strong>Address: </strong></Form.Label> {profile.address} {profile.city}, {profile.region}, {profile.country}, {profile.zip}<br />
                                {profile.website !== "" && <><Form.Label><strong>Website: </strong></Form.Label> {profile.website} </>} <br />
                                <Form.Label><strong>Phone Number: </strong></Form.Label> {profile.phoneNumber}
                                <br /><br />
                            </>
                            }
                            {relationship.length > 0 && <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>Relationship</Form.Label><br />
                                    <ToggleButtonGroup type="radio" name="Relationship" className="toggleSelector" defaultValue={profile.relationship}>
                                        {relationship.map((r, i) => {
                                            return <ToggleButton key={r} variant="light" value={r}>{GetRelationshipLabel(r)}</ToggleButton>
                                        })}
                                    </ToggleButtonGroup>                     

                                </Form.Group>
                            </Form.Row>}

                            {relationship.length > 0 && <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>Additional Designation, if applicable</Form.Label><br />
                                    <Select name="relatedContacts" defaultValue={profile.relatedDesignations} onChange={(e) => handleRelatedDesignations(e)} isMulti options={designationOptions} />
                                </Form.Group>
                            </Form.Row>}
                        </Card.Body>
                        {!vaultAccessMode.isReadOnly && <Card.Footer>
                            <Button variant="info" size="sm" type="submit" className="pull-right"><FaTelegramPlane /> Save</Button>
                        </Card.Footer>}
                    </Card>
                </Form>
            </Col>
            <Col>
            {(profile.type === "advisor" || profile.type=== "beneficiary") && <>
                <Form noValidate onSubmit={onPermissionSubmit}>
                    <Card className="mb-3">
                        <Card.Header>
                            Permission Settings
                    </Card.Header>
                        <Card.Body>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>View Assets
                                <OverlayTrigger placement="right" overlay={<Tooltip>Determine if the connection can see any assets you have assigned to them.</Tooltip>}>
                                            <Button variant="icon"><FaInfoCircle /></Button>
                                        </OverlayTrigger>
                                    </Form.Label><br />
                                    <ToggleButtonGroup key={profile.permissions?.viewAssets} type="radio" name="ViewAssetsToggle" className="toggleSelector" defaultValue={profile.permissions?.viewAssets}>
                                        <ToggleButton variant="light" value={true}><FaRegEye /> Yes</ToggleButton>
                                        <ToggleButton variant="light" value={false}><FaRegEyeSlash /> No</ToggleButton>
                                    </ToggleButtonGroup>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>Vault Access
                                <OverlayTrigger placement="right" overlay={<Tooltip>Determine if the connection can access this vault.</Tooltip>}>
                                            <Button variant="icon"><FaInfoCircle /></Button>
                                        </OverlayTrigger>
                                    </Form.Label><br />
                                    <ToggleButtonGroup key={profile.permissions?.viewVault} type="radio" name="ViewVaultToggle" className="toggleSelector" defaultValue={profile.permissions?.viewVault}>
                                        <ToggleButton variant="light" value={true}><FaRegEye /> Yes</ToggleButton>
                                        <ToggleButton variant="light" value={false}><FaRegEyeSlash /> No</ToggleButton>
                                    </ToggleButtonGroup>
                                </Form.Group>
                            </Form.Row>
                            {/*                             <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>Edit Vault
                                <OverlayTrigger placement="right" overlay={<Tooltip>Determine if the connection can see any assets you have assigned to them.</Tooltip>}>
                                            <Button variant="icon"><FaInfoCircle /></Button>
                                        </OverlayTrigger>
                                    </Form.Label><br />
                                    <ToggleButtonGroup key={profile.permissions?.editVault} type="radio" name="EditVaultToggle" className="toggleSelector" defaultValue={profile.permissions?.editVault}>
                                        <ToggleButton variant="light" value={true}><FaRegEye /> Yes</ToggleButton>
                                        <ToggleButton variant="light" value={false}><FaRegEyeSlash /> No</ToggleButton>
                                    </ToggleButtonGroup>
                                </Form.Group>
                            </Form.Row> */}
                        </Card.Body>
                        {!vaultAccessMode.isReadOnly && <Card.Footer>
                            <Button variant="info" size="sm" type="submit" className="pull-right"><FaTelegramPlane /> Save</Button>{' '}
                        </Card.Footer>}
                    </Card>
                </Form>
                </>}
            </Col>
        </Row>
        <Row style={{ marginTop: '25px' }}>
            <Col>
                <Row><Col>
                    Assets Assigned<hr />
                </Col></Row>
                <Row className="row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-3 row-cols-xl-3">
                    <AssetsList assets={profile.assets} />
                </Row>
            </Col>
        </Row>
        <Toaster setShowSuccessToast={setShowSuccessToast} toasterMessage={toasterMessage} showSuccessToast={showSuccessToast} />
        <Modal
            size="sm"
            show={deleteAssetConfirmation}
            onHide={() => setDeleteAssetConfirmation(false)}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Are you sure you want to remove this connection?
          </Modal.Title>
            </Modal.Header>
            <Modal.Body> <Button variant="info" size="sm" className="pull-right" onClick={onDeleteSubmit}> Remove Connection</Button></Modal.Body>
        </Modal>
        <Modal
            show={showNoteModal}
            onHide={() => setNoteModal(false)}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Note
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group>
                    <Form.Control as="textarea" name="ConnectionNote" defaultValue={profile.notes} onChange={(e) => onProfilePropertyChange(e, "notes")} rows={8} />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="info" size="sm" className="pull-right" onClick={(e) => { onNotesSubmit(e) }}> Save</Button>
            </Modal.Footer>
        </Modal>

        <Modal
            show={showHistory}
            onHide={() => setShowHistory(false)}
            centered
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Audit History
          </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <>
                    {profile.audit !== undefined && profile.audit.reverse().map(function (note, index) {
                        return <span key={note.timestamp}>{note.user}  <Badge variant="info">{note.action}</Badge> {note.timestamp}<br /></span>;
                    })}
                </>
            </Modal.Body>
            <Modal.Footer>
                <small>Note: Does not include audit history recently completed.</small>
            </Modal.Footer>
        </Modal>
    </>
}

export default Person;