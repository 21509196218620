import { CardGroup, Card, Form, Alert } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../providers/auth-provider";
import { useState, useEffect } from "react";

function VerifyEmail(props) {
  const { user, verifyEmail } = useAuth();
  const history = useHistory();
  const [show, hasError] = useState(false);
  const [verifyErrorText, setVerifyErrorText] = useState("");

  const onInit = () => {
    let searchParams = new URLSearchParams(window.location.search);
    searchParams.has("t"); // true
    let token = searchParams.get("t");
    verifyEmail({ token: token }).then((res) => {
      if (res.status === 200) {
        setVerifyErrorText(
          "Thanks for verifying your email, logging you in..."
        );
        setTimeout(() => {
          history.push("/");
        }, 3000);
      } else {
        // Show error from login
        hasError(true);
        setVerifyErrorText(
          "The verification email has expired or is incorrect, we sent you a new one."
        );
      }
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(onInit, []);

  return (
    <>
      <CardGroup>
        <Card className="p-4" md={8}>
          <Card.Body>
            <Form>
              <h3>Verify Email</h3>
              {verifyErrorText !== "" && (
                <Alert key="alertmessage" variant="danger">
                  {verifyErrorText} <br />
                </Alert>
              )}

              <p className="text-muted"></p>
            </Form>
          </Card.Body>
        </Card>
      </CardGroup>
    </>
  );
}

export default VerifyEmail;
