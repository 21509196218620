import PeopleCard from './ClientCard.js';
import { Row, Col, Button, Modal, Form, InputGroup, FormControl, Card } from 'react-bootstrap';
import { FaUserPlus } from 'react-icons/fa';
import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import AppContext from './../AppContext';
import Tips from './../Tips';
import { RiBookReadLine } from 'react-icons/ri';

function People(props) {
    const people = [
        { name: "Tim Leung", vault: "5fef9cfbf73d8e3ad875e8b5" },
        { name: "Brian Kieller", vault: "5ff283c6f73d8e3ad875e8b9" },
        { name: "Donald Ho", vault: "5ff28597f73d8e3ad875e8bb" },
    ];

    const {user} = useContext(AppContext);
    const [clients, setClients] = useState([]);
    const [showAddClientModal, setShowAddclientModal] = useState(false);
    const [clientName, setClientName] = useState("");
    const [clientEmail, setClientEmail] = useState("");
    const [clientMessage, setClientMessage] = useState("");
    const [reload, setReload] = useState(0);
    const [connectionssFilter, setConnectionssFilter] = useState("");
    const connectionsFilterChange = (val) => setConnectionssFilter(val);

    useEffect(() => {
        axios.get(`/api/vaults`, {
            params: {
                options: "clients",
                profileId: user.id
            }
        })
            .then(res => {

                const newArray = res.data.map(function (item) {
                    return {
                        name: item.MainVault.name === undefined ? item.MainOwner.fullName: item.MainVault.name,
                        vault: item.MainVault._id,
                        note: item.MainVault.note,
                        assigned: item.MainOwner === undefined,
                        email: item.MainOwner === undefined ? item.MainVault.email : item.MainOwner.email,
                        profileImage: item.MainOwner === undefined ? "": item.MainOwner.profileImage,
                        owner: item.MainOwner === undefined ? "": item.MainOwner._id,
                        inviteTimestamp: item.MainVault.inviteTimestamp
                    };
                });
                setClients(newArray);
            })
            .catch(function (error) {
                console.log(error);
            })
    }, [reload]);

    const onAddClientVaultSubmit = (e) => {
        e.preventDefault();
        axios.put(`/api/vaults`, {name:clientName, email:clientEmail, message:clientMessage, advisor: user.id})
            .catch(function (error) {
                if(error.response.status === 303){
                    alert("That user already has an Execuvault account.  In order to connect please contact them and request that they add you in their Connections as an Advisor");
                }
                return;
            })
            .then(res => {
                
                console.log(res);

                if (res && res.status === 200) {
                    setReload(clientEmail);
                    setClientName("");
                    setClientMessage("");
                    setClientEmail("");
                    setShowAddclientModal(false);
                }
            })
            .catch(function (error) {
                // handle error
                console.log(error);
         })            
    }

    const onAddClientVault = (e) => {
        e.preventDefault();
        setShowAddclientModal(true);
    }

    return <>
        <Row style={{ marginBottom: '20px' }}>
            <Col> <Tips contentKey="dashboardAdvisor" /></Col>
        </Row>
        <Row style={{ marginBottom: '20px' }}>
            <Col md={3} className="mb-3"><Card className="dashboard totalAssets" style={{ textAlign: "center" }}>    <blockquote className="blockquote mb-0 card-body">
                  {clients.length}
                <footer>
                    <small>
                        Connected Clients
                    </small>
                </footer>
            </blockquote></Card></Col>
            <Col md={3} className="mb-3"><Card className="dashboard totalValue" style={{ textAlign: "center" }}>   <blockquote className="blockquote mb-0 card-body">
                 Unlimited
                <footer>
                    <small>
                       Connections Available
                    </small>
                </footer>
            </blockquote></Card></Col>
            <Col md={3} className="mb-3"><Card className="dashboard attention" style={{ textAlign: "center" }}>    <blockquote className="blockquote mb-0 card-body">
                    October 1st, 2021
                <footer>
                    <small>
                        Renewal Date
                    </small>
                </footer>
            </blockquote></Card></Col>
        </Row>
        <Row style={{ marginBottom: '20px' }}>
            <Col md={6} >
                <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">Filter By Name</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        placeholder="Name"
                        aria-label="filter By Name"
                        onChange={(e) => connectionsFilterChange(e.target.value)}
                    />
                </InputGroup>
            </Col>
            <Col md={6}>
                <Button variant="info" size="sm" className="pull-right" onClick={(e) => onAddClientVault(e)}><FaUserPlus /> Add Client Vault</Button>
            </Col>
        </Row>
        <Row className="row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-3 row-cols-xl-3">
            {clients && clients.slice(0).reverse().map((p, i) => {
                 if (connectionssFilter === "" || (p.name && p.name.toLowerCase().includes(connectionssFilter.toLowerCase())))
                    return <Col className="mb-3" key={p.vault}><PeopleCard person={p} /></Col>
                 return null;                    
            })}
        </Row>

        <Modal
            show={showAddClientModal}
            onHide={() => setShowAddclientModal(false)}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Add Client Vault
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Client's Name</Form.Label>
                        <Form.Control name="nickname" value={clientName} onChange={(e)=> setClientName(e.target.value)}  placeholder="Name of the client" />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Client's Email</Form.Label>
                        <Form.Control name="Email" value={clientEmail} onChange={(e)=> setClientEmail(e.target.value)} placeholder="Valid Email" />
                    </Form.Group>
                </Form.Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="info" size="sm" className="pull-right" onClick={(e) => { onAddClientVaultSubmit(e) }}> Add</Button>
            </Modal.Footer>
        </Modal>
    </>
}

export default People;