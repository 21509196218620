import {Toast} from 'react-bootstrap';
import {FaCheck } from 'react-icons/fa';

export const Toaster = (props) => {
    const {setShowSuccessToast, showSuccessToast, toasterMessage} = props;
    return <>
    <div aria-live="polite" aria-atomic="true" className="fixed-top" style={{"top": "100px", "width":"100%"}}>
        <Toast onClose={() => setShowSuccessToast(false)} show={showSuccessToast} delay={5000} autohide={true} style={{
            float: "left",
            marginRight: "20px",
            width: "400px",
            float: "right"
        }}>
            <Toast.Header closeButton={false}>
                <FaCheck style={{ color: "green" }} /><strong className="mr-auto">&nbsp;{toasterMessage.title}</strong>
            </Toast.Header>
            <Toast.Body>{toasterMessage.message}</Toast.Body>
        </Toast>
        </div>
    </>
}

export default Toaster;