import { Card, Row, Col, Button } from 'react-bootstrap';
import { FaLightbulb, FaPlusCircle } from 'react-icons/fa';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";

const Description = () => null;

export const Tips = (props) => {
    const { children, imageSrc = "https://app.execuvault.io/images/avatars/accounts.png", title = "Tips", contentKey = "" } = props;
    const [contentBlock, setContentBlock] = useState("");
    const [actionLink, setActionLink] = useState("");
    const [actionLinkText, setActionLinkText] = useState("");

    useEffect(() => {
        if (contentKey !== "" && contentKey !== null) {
            axios.get(`/api/content?content=` + contentKey)
                .then(res => {
                    setContentBlock(res.data.block);
                    setActionLink(res.data.actionLink);
                    setActionLinkText(res.data.actionLinkText);
                })
        }
    }, []);

    return <>
        {contentKey === "" && contentBlock === "" && <Card bg="tips" text="white">
            <Card.Header> <FaLightbulb className="tips circle-icon" />{'    '}{title}</Card.Header>
            <Card.Body>
                <Card.Text>
                    {children}
                </Card.Text>
            </Card.Body>
        </Card>
        }
        {contentBlock !== "" && <>
            <Card bg="tips" text="white" className={contentKey}>
                <Card.Body>
                    <div dangerouslySetInnerHTML={{ __html: contentBlock }}></div>
                    {actionLink && <>
                        <Row>
                            <Col>
                                <Button variant="info" as={Link} to={actionLink} size="sm" className="pull-right"><FaPlusCircle /> <span dangerouslySetInnerHTML={{ __html: actionLinkText }}></span></Button>
                            </Col>
                        </Row>
                    </>}
                </Card.Body>
            </Card>
        </>}
    </>
}

Tips.Description = Description;

export default Tips;