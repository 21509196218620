import { Form, Row, Col, Card, Button, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { FaTelegramPlane, FaUserFriends, FaBriefcase, FaRibbon, FaPhoneAlt, FaChild } from 'react-icons/fa';
import Tips from './Tips';
import Address from './Address';
import { useForm } from "react-hook-form";
import { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { useHistory } from "react-router-dom";
import AppContext from './AppContext';
import {GetRelationshipLabel} from './../../Utils.js';

function AddPerson(props) {
    const { vault } = useContext(AppContext);
    const history = useHistory();
    const { register, handleSubmit, errors } = useForm({ criteriaMode: "all" });
    const [type, setType] = useState();
    const [relationship, setRelationship] = useState([]);

    
    const onSubmit = (data, e) => {
        const connection = {
            email: e.target.email?.value,
            name: e.target.fullName.value,
            address: e.target.address.value,
            city: e.target.city.value,
            country: e.target["rcrs-country"].value,
            region: e.target["rcrs-region"].value,
            zip: e.target.zip.value,
            type: type,
            relationship: e.target?.Relationship?.value
        }

        axios.put(`/api/connections?vault=` + vault.id, connection)
            .then(res => {
                if (res.status === 200) {
                    history.push("/people/" + res.data + "?new")
                }
            })
    }

    useEffect(() => {
        switch(type) {
            case "beneficiary":
                setRelationship(["spouse", "partner", "child", "sibling", "parent", "family", "friend"]);
                break;
            case "advisor":
                setRelationship(["lawyer", "planner", "insurance", "tax", "wealth"]);     
                break;   
            case "dependent":
                setRelationship(["elder", "minor", "pet", "special"]);
                break;                
            default:
                setRelationship([]);          
        }
    }, [type])

    return <>

        <Row>
            <Col md={9}>

                <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Card className="giant">
                        <Card.Header>
                            Connection Type
                    </Card.Header>
                        <Card.Body>
                            <ToggleButtonGroup type="radio" name="options" className="connectionToggle" defaultValue={""} onChange={(v) => setType(v)}>
                                <ToggleButton className="beneficiary" value={"beneficiary"}><FaUserFriends /><br />Beneficiary</ToggleButton>
                                <ToggleButton className="advisor" value={"advisor"}><FaBriefcase /><br />Advisors + Lawyers</ToggleButton>
                                <ToggleButton className="dependent"  value={"dependent"}><FaChild /><br />Dependent</ToggleButton>
                                <ToggleButton className="charity" value={"charity"}><FaRibbon /><br />Charitable Organization</ToggleButton>
                                <ToggleButton className="contact"  value={"contact"}><FaPhoneAlt /><br />Addtional Contact</ToggleButton>
                            </ToggleButtonGroup>
                        </Card.Body>
                    </Card>
                    <br />
                    <Card className="mb-3">
                        <Card.Header>
                            Connection Details
                    </Card.Header>
                        <Card.Body>
                            <Form.Row>
                            <Form.Group as={Col}>
                                    <Form.Label>{type==="charity" ? <>Charity Name</> : <> Full Name</> }</Form.Label>
                                    <Form.Control ref={register({ required: true, maxLength: 80 })} isInvalid={!!errors.fullName} name="fullName" placeholder="" />
                                    <Form.Control.Feedback type="invalid" tooltip>
                                        {errors.fullName && "Name is required"}
                                    </Form.Control.Feedback>
                                </Form.Group>                                
                                {type!=="charity" && type!=="dependent" && <>
                                <Form.Group as={Col}>
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        ref={register({
                                            maxLength: 80, pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            }
                                        })}
                                        isInvalid={!!errors.email}
                                        type="email"
                                        name="email"
                                        placeholder="Enter email"
                                    />
                                    <Form.Control.Feedback type="invalid" tooltip>
                                        {errors.email && "Email is required"}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                </>}
    
                            </Form.Row>
                            <Address register={register} errors={errors} />
                            {relationship.length > 0 && <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>Relationship</Form.Label><br />
                                    <ToggleButtonGroup type="radio" name="Relationship" className="toggleSelector">
                                        {relationship.map((r, i) => {
                                            return <ToggleButton key={r} variant="light" value={r}>{GetRelationshipLabel(r)}</ToggleButton>
                                        })}
                                    </ToggleButtonGroup>
                                </Form.Group>
                            </Form.Row>}
                        </Card.Body>
                        <Card.Footer>
                            <Button variant="info" size="sm" type="submit" className="pull-right"><FaTelegramPlane /> Add</Button>{' '}
                        </Card.Footer>
                    </Card>
                </Form>
            </Col>
            <Col>
                <Tips contentKey="peopleDetails" />
            </Col>
        </Row>
    </>
}

export default AddPerson;