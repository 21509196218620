import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { FaTelegramPlane, FaCreditCard } from 'react-icons/fa';
import { Link } from "react-router-dom";

const accountTypeOptions = [
  { key: 'Amazon', label: 'Amazon', value: 'Amazon', icon: 'fab fa-amazon' },
  { key: 'Apple ID', label: 'Apple ID', value: 'Apple ID', icon: 'fab fa-apple' },
  { key: 'Email', label: 'Email', value: 'Email', icon: 'fas fa-at' },
  { key: 'Facebook', label: 'Facebook', value: 'Facebook', icon:'fab fa-facebook' },
  { key: 'Laptop/Desktop/Tablet', label: 'Laptop/Desktop/Tablet', value: 'Laptop/Desktop/Tablet', icon: 'fas fa-laptop' },
  { key: 'LinkedIn', label: 'LinkedIn', value: 'LinkedIn', icon: 'fab fa-linkedin' },
  { key: 'Mobile/Smartphone', label: 'Mobile/Smartphone', value: 'Mobile/Smartphone', icon: 'fas fa-mobile alternate' },
  { key: 'Paypal', label: 'Paypal', value: 'Paypal', icon: 'fab fa-paypal' },
  { key: 'Streaming Services', label: 'Streaming Services', value: 'Streaming Services', icon: 'fas fa-tv' },
  { key: 'Utilities', label: 'Utilities', value: 'Utilities', icon: 'fas fa-bolt' },
  { key: 'Wifi', label: 'Wifi', value: 'Wifi', icon: 'fas fa-wifi' },
  { key: 'Other', label: 'Other', value: 'Other', icon: 'fas fa-power-off' },
]

function LiabilitiesCard(props) {
  const { liability } = props;
  const __icon = accountTypeOptions.filter (o => o.value === liability.accountDeviceType);
  const icon = __icon.length === 1 ? __icon[0].icon + ' fa-lg': 'fas fa-2x fa-power-off';
  console.log(icon);
  return <>
    <Card>
      <Card.Header>
        <div className="specialAccountCircle"><i className={icon} /></div><div style={{ float: "left", marginTop:"5px" }}> {liability.name} <br /><span style={{ fontWeight:"400"}}>{liability.loginName}</span></div>
      </Card.Header>
      <Card.Footer>
        <Button variant="info" as={Link} to={"/accounts/" + liability._id} className="pull-right" size="sm"><FaTelegramPlane /> View</Button>
      </Card.Footer>
    </Card>
  </>
}

export default LiabilitiesCard;