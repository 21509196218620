/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Card, Modal } from "react-bootstrap";
import { FaRegFileAlt, FaTimes, FaPaperclip } from "react-icons/fa";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

export const Attachments = (props) => {
  const {
    isReadOnly = true,
    uploadCallBack = () => {},
    originalFiles = [],
    deleteFileCallback = () => {},
    viewFileCallBack = () => {},
  } = props;
  const handleFileSelect = (e) => {
    e.preventDefault();
    setShowUploadModal(true);
  };
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
  const [documentFiles, setDocumentFiles] = useState(originalFiles);

  const handleDelete = (key) => {
    const ar = documentFiles.filter((file) => file.key !== key);
    setDocumentFiles(ar);
    deleteFileCallback(key);
  };

  const handleViewClick = (e, key) => {
    e.preventDefault();
    console.log("KEY -> " + key);
    viewFileCallBack(key);
  };
  const handleUpload = (e) => {
    acceptedFiles.forEach(function (file) {
      documentFiles.push({ name: file.name, type: file.type });
    });

    uploadCallBack(acceptedFiles);
    setDocumentFiles(documentFiles);
    setShowUploadModal(false);
  };
  const [showUploadModal, setShowUploadModal] = useState(false);

  useEffect(() => {
    console.log("new files....");
    if (originalFiles.length > 0) {
      acceptedFiles.length = 0;
      acceptedFiles.splice(0, acceptedFiles.length);
      setDocumentFiles(originalFiles);
    }
  }, [originalFiles]);

  const __files = acceptedFiles.map((file, i) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <>
      <Modal
        size="lg"
        show={showUploadModal}
        onHide={() => setShowUploadModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Select the files you want to upload</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <section className="container">
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              <p>Drag 'n' drop some files here, or click to select files</p>
            </div>
            <aside>
              <br />
              Files Awaiting Upload:
              {__files.length === 0 && <> None</>}
              <ul>{__files}</ul>
            </aside>
          </section>
          <Button
            variant="info"
            size="sm"
            className="pull-right"
            onClick={() => {
              handleUpload();
            }}
          >
            {" "}
            Upload Documents
          </Button>
        </Modal.Body>
      </Modal>

      <Card>
        <Card.Header>Attachments</Card.Header>
        <Card.Body>
          {documentFiles.length === 0 && <>No files yet.</>}
          {documentFiles.length !== 0 && (
            <>
              {documentFiles.map(function (f, i) {
                return (
                  <div key={f.name + i} className="fileList">
                    <FaRegFileAlt />{" "}
                    <a
                      href="#"
                      onClick={(e) => {
                        handleViewClick(e, f.key);
                      }}
                    >
                      {f.name}
                    </a>{" "}
                    <FaTimes
                      onClick={() => {
                        handleDelete(f.key);
                      }}
                    />
                  </div>
                );
              })}
            </>
          )}
        </Card.Body>
        {!isReadOnly && (
          <Card.Footer>
            <Button
              size="sm"
              variant="info"
              className="pull-right"
              onClick={(e) => handleFileSelect(e)}
            >
              <FaPaperclip /> Add Attachment(s)
            </Button>
          </Card.Footer>
        )}
      </Card>
    </>
  );
};
export default Attachments;
