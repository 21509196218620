import { Form, Col, InputGroup, FormControl, Card, Button } from 'react-bootstrap';
import { FaTelegramPlane } from "react-icons/fa";
import { useForm } from "react-hook-form";
import React, { useState, useEffect } from 'react';
import { isNumber } from './../../Utils.js';
import { GetSubTypeLabel } from './../../Utils.js';

export const AssetDetailsForm = (props) => {
    const { register, handleSubmit, errors } = useForm({ criteriaMode: "all" });
    const { assetType, onSubmit, showExtraFields, isReadOnly = true, defaultValues = { permission: 0, meta: {} }, showSubCategory = false } = props;

    const [assetValue, setAssetValue] = useState(0);
    const [assetAmountOwing, setAssetAmountOwing] = useState(0);
    const [assetPercentage, setAssetPercentage] = useState(0);
    const [calculatedEstimatedValue, setCalculatedEstimatedValue] = useState(0);
    const [relationship, setRelationship] = useState([]);


    useEffect(() => {
        setAssetPercentage(defaultValues.percentage);
        setAssetAmountOwing(defaultValues.amountOwing);
        setAssetValue(defaultValues.value);

        switch (assetType) {
            case "account":
                setRelationship(["cash", "checking", "crypto", "mutualfunds", "retirement", "savings", "shares", "stocks"]);
                break;
            case "insurance":
                setRelationship(["annuity", "final", "life", "preneed", "Other"]);
                break;
            case "property":
                setRelationship(["building", "condo", "cottage", "farm", "garage", "house", "land", "marina", "timeshare"]);         
                break;
            case "vehicle":
                setRelationship(["bicycle", "boat", "car", "motorcycle", "plane", "rv", "snowmobile", "tractor", "truck", "suv", "Other"]);       
                break;
            case "collectibles":
                setRelationship(["artwork", "books", "clothing", "coins", "decor", "gadgets", "music", "jewelry", "sporting", "tools", "toys", "vintages"]);
                break;
            default:
                setRelationship([]);
        }

    }, [defaultValues.permission, defaultValues.percentage, defaultValues.amountOwing, defaultValues.value]);

    useEffect(() => {
        if (!isNumber(Number(assetPercentage)) || assetPercentage < 0 || assetPercentage > 100) return;

        let value = (assetValue - assetAmountOwing) * (assetPercentage / 100);
        if (value <= 0) {
            value = 0;
        }
        setCalculatedEstimatedValue(value);
    }, [assetValue, assetAmountOwing, assetPercentage]);

    if (defaultValues.meta === undefined) {
        defaultValues.meta = {};
    }

    return (
        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Card.Body>
                <Form.Row>
                    <Form.Group as={Col} md="12">
                        <Form.Label>Nickname of this Asset</Form.Label>
                        <Form.Control name="name" placeholder="" isInvalid={!!errors.name} defaultValue={defaultValues.title} ref={register({ required: true })} />
                        <Form.Control.Feedback type="invalid" tooltip>
                            {errors.name && "Nickname is required"}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form.Row>
                {showSubCategory && <>
                <Form.Row>
                    <Form.Group as={Col} md="6">
                        <Form.Label>Subcategory</Form.Label><br />
                        <Form.Control as="select" name="subType" ref={register()} defaultValue={defaultValues.subType} >
                            <option value="None">None Selected</option>
                            {relationship !== null && <>
                                {relationship.map(function (r, index) {
                                    const _punysubtype = defaultValues.subType === r ? "selected":"";
                                    return <option key={r} value={r} selected={_punysubtype}>{GetSubTypeLabel(r)}</option>
                                })}
                            </>
                            }
                        </Form.Control>
                    </Form.Group>
                </Form.Row>
                </>}
                <Form.Row>
                    <Form.Group as={Col} md="4">
                        <Form.Label>Asset Value </Form.Label>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text>$</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl placeholder="0" name="value" defaultValue={defaultValues.value} onChange={(e) => setAssetValue(e.target.value)} aria-label="Amount"
                                isInvalid={!!errors.assetValue}
                                ref={
                                    register({
                                        required: true,
                                        valueAsNumber: true,
                                        validate: { positive: value => parseInt(value, 10) > 0 || 'Asset value should be greater than 0' }
                                    })
                                }
                            />
                            <Form.Control.Feedback type="invalid" tooltip>
                                {errors.assetValue?.types?.required && <p>Required</p>}
                                {errors.assetValue?.types?.positive && <p>Must be greater than 0</p>}
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                    {showExtraFields && <><Form.Group as={Col} md="4">
                        <Form.Label>Estimated Amount Owing</Form.Label>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text>$</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl placeholder="Amount" name="amountOwing" defaultValue={defaultValues.amountOwing} onChange={(e) => setAssetAmountOwing(e.target.value)} aria-label="Amount" ref={register()} />
                        </InputGroup>
                    </Form.Group>
                        <Form.Group as={Col} md="4">
                            <Form.Label>Ownership Percentage </Form.Label>
                            <InputGroup className="mb-3">
                                <FormControl placeholder="100" name="percentage" defaultValue={defaultValues.percentage} onChange={(e) => setAssetPercentage(e.target.value)} aria-label="Percentage" ref={register()} />
                                <InputGroup.Append>
                                    <InputGroup.Text>%</InputGroup.Text>
                                </InputGroup.Append>
                            </InputGroup>
                        </Form.Group></>}
                </Form.Row>
                {showExtraFields && <>
                    <Form.Row>
                        <Form.Group as={Col} md="4">
                            <Form.Label>Estimated Value of Asset </Form.Label>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>$</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl readOnly placeholder="" value={calculatedEstimatedValue} aria-label="Amount" />
                            </InputGroup>
                        </Form.Group>
                    </Form.Row>
                </>}

                {assetType === "account" && <>
                    <Form.Row>
                        <Form.Group as={Col} md="4">
                            <Form.Label>Institution Name / Number</Form.Label>
                            <InputGroup className="mb-3">
                                <FormControl name="meta.institution" placeholder="" defaultValue={defaultValues.meta.institution} aria-label="Amount" />
                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md="4">
                            <Form.Label>Account Number</Form.Label>
                            <InputGroup className="mb-3">
                                <FormControl name="meta.account" placeholder="" defaultValue={defaultValues.meta.account} aria-label="Amount" />
                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md="4">
                            <Form.Label>Transit / Routing Number</Form.Label>
                            <InputGroup className="mb-3">
                                <FormControl name="meta.transit" placeholder="" defaultValue={defaultValues.meta.transit} aria-label="Amount" />
                            </InputGroup>
                        </Form.Group>
                    </Form.Row>
                </>}


                {assetType === "insurance" && <>
                    <Form.Row>
                        <Form.Group as={Col} md="6">
                            <Form.Label>Coverage | Face Amount</Form.Label>
                            <InputGroup className="mb-3">
                                <FormControl name="meta.coverage" placeholder="" defaultValue={defaultValues.meta.coverage} aria-label="Amount" />
                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md="6">
                            <Form.Label>Name of Insurance Company</Form.Label>
                            <InputGroup className="mb-3">
                                <FormControl name="meta.company" placeholder="" defaultValue={defaultValues.meta.company} aria-label="Amount" />
                            </InputGroup>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} md="6">
                            <Form.Label>Policy Number</Form.Label>
                            <InputGroup className="mb-3">
                                <FormControl name="meta.policy" placeholder="" defaultValue={defaultValues.meta.policy} aria-label="Amount" />
                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md="6">
                            <Form.Label>Policy Expiration Date</Form.Label>
                            <InputGroup className="mb-3">
                                <FormControl name="meta.expiration" placeholder="" defaultValue={defaultValues.meta.expiration} aria-label="Amount" />
                            </InputGroup>
                        </Form.Group>
                    </Form.Row>
                </>}

                {assetType === "vehicle" && <>
                    <Form.Row>
                        <Form.Group as={Col} md="6">
                            <Form.Label>Year | Make | Model</Form.Label>
                            <InputGroup className="mb-3">
                                <FormControl name="meta.model" placeholder="" defaultValue={defaultValues.meta.model} aria-label="Amount" />
                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md="6">
                            <Form.Label>Vin #</Form.Label>
                            <InputGroup className="mb-3">
                                <FormControl name="meta.vin" placeholder="" defaultValue={defaultValues.meta.vin} aria-label="Amount" />
                            </InputGroup>
                        </Form.Group>
                    </Form.Row>
                </>}

                {assetType === "collectibles" && <>
                    <Form.Row>
                        <Form.Group as={Col} md="6">
                            <Form.Label>Maker | Brand | Style</Form.Label>
                            <InputGroup className="mb-3">
                                <FormControl name="meta.maker" placeholder="" defaultValue={defaultValues.meta.maker} aria-label="Amount" />
                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md="6">
                            <Form.Label>Serial#, Edition#, or Model#</Form.Label>
                            <InputGroup className="mb-3">
                                <FormControl name="meta.serial" placeholder="" defaultValue={defaultValues.meta.serial} aria-label="Amount" />
                            </InputGroup>
                        </Form.Group>
                    </Form.Row>
                </>}

            </Card.Body>
            {!isReadOnly && <Card.Footer>
                &nbsp;<Button variant="info" size="sm" className="pull-right" style={{ marginBottom: "10px" }} type="submit"><FaTelegramPlane /> Save</Button>
            </Card.Footer>}
        </Form>
    )
}

export default AssetDetailsForm;