import React, { createContext, useContext, useState } from "react";

const AuthContext = createContext({});

function AuthProvider({ children }) {
    const localUserJson = localStorage.getItem("user");
    const localUser = localUserJson && JSON.parse(localUserJson);
    // Really want to be calling web service every time... Keeps returning promise????
    // Need help
    const [user, setUser] = useState(localUser);
  
    const saveUser = user => {
      setUser(user);
      localStorage.setItem("user", JSON.stringify(user));
    };
  
    const deleteUser = () => {
      setUser(null);
      localStorage.removeItem("user");
    };
    const signup = user => sendRequest("signup", user);
    const login = user => sendRequest("login", user, saveUser, deleteUser);
    const verifyEmail = token => sendRequest("verifyEmail", token, saveUser);
    const forgotpassword = user => sendRequest("forgotpassword", user);
    const setpassword = user => sendRequest("setpassword", user);
    const authUser = user => sendRequest("users", user, undefined, deleteUser);
    const isAuth = user => 
        {
            sendRequest("users", user, saveUser);
            if (user && user["email"])
            {
                return true;
            }
        };
    const logout = () => sendRequest("logout", undefined, deleteUser);
    return (
        <AuthContext.Provider value={{ user, setpassword, forgotpassword, isAuth, signup, login, logout, verifyEmail, authUser }}>
            {children}
        </AuthContext.Provider>
    );
}

async function sendRequest(endpoint, body, successCallback, errorCallback) {
    const requestOptions = {
        method: "POST",
        headers: {
            Accept: "application/json"
        }
    };

    if (body) {
        requestOptions.headers["Content-Type"] = "application/json";
        requestOptions.body = JSON.stringify(body);
    }

    const response = await fetch(
        `/api/${endpoint}`,
        requestOptions
    );
    if (response.ok && successCallback) {
        const responseBody = await response.json();
        successCallback(responseBody);
    }
    else if (errorCallback != undefined)
    {
        errorCallback();
    }
    return response;
}

const useAuth = () => useContext(AuthContext);

export { useAuth, AuthProvider };