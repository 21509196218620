import { Row, Col, Card, Form, Button, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import Tips from './../Tips';
import AppContext from './../AppContext';
import { useContext, useEffect, useState } from "react";
import axios from 'axios';

export default function Wishes(props) {

    const { vaultAccessMode, vault } = useContext(AppContext);
    const [method, setMethod] = useState("");
    const [interment, setInterment] = useState("");
    const [funeral, setFuneral] = useState("");
    const [notes, setNotes] = useState("");

    const methods = [
        { name: "PrePaid/PreNeed", description: "Be sure to include a copy of your preneed contract in Documents + Vital Records to prevent your survivors from paying for arrangements you’ve already covered", value: "prepaid" },
        { name: "Insurance Paid", description: "Be sure to include your final expense insurance policy in Insurance Policies to prevent your survivors from paying for arrangements you’ve already covered", value: "insurance" },
        { name: "Estate or Other", description: "Be sure to include a copy of your preneed contract in Documents + Vital Records to prevent your survivors from paying for arrangements you’ve already covered", value: "estate" }
    ];

    useEffect(() => {
        if(vault === undefined || vault.id === undefined || vault === {}) return;
        axios.get(`/api/vaults?options=wishes`, {
            params: {
                vault: vault.id
            }
        })
            .then(res => {
                setMethod (res.data.method);
                setInterment (res.data.interment);
                setFuneral (res.data.funeral);
                setNotes (res.data.notes);
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [vault]);

    const saveWishes = () => {
        axios.post(`/api/vaults?vault=` + vault.id,
            {
                notes: notes,
                interment: interment,
                funeral: funeral,
                method: method
            })
            .then(res => {
                if (res.status === 200) {

                }
            });
    }

    return <>
        <Tips contentKey="wishes" /><br />
        <Row><Col><h5>Desired Method of Funding</h5></Col></Row>
        <Row className="row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-3 row-cols-xl-3">
            <>
                {methods.map(function (m, index) {
                    return <Col className="mb-3" key={m.value}>
                        <Card onClick={() => setMethod(m.value)} style={{ cursor: "pointer" }} bg={method !== m.value ? "" : "secondary"} text={method !== m.value ? "" : "white"}>
                            <Card.Header>
                                {m.name}
                            </Card.Header>
                            <Card.Body>
                                <Card.Text>
                                    {m.description}
                                </Card.Text>
                                <Card.Text>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                })
                }
            </>
        </Row>
        <Row style={{ marginTop: "40px", marginBottom: "20px" }}><Col><h5>Interment Choices</h5></Col></Row>
        <Row>
            <Col>
                <ToggleButtonGroup type="radio" name="interment" value={interment} onChange={setInterment}>
                    <ToggleButton variant="light" value={"cemetery"}>Cemetery Burial</ToggleButton>
                    <ToggleButton variant="light" value={"mausoleum"}>Mausoleum Entombment</ToggleButton>
                    <ToggleButton variant="light" value={"cremation"}>Cremation</ToggleButton>
                    <ToggleButton variant="light" value={"donation"}>Donation to Medical Science</ToggleButton>
                    <ToggleButton variant="light" value={"other"}>Other</ToggleButton>
                </ToggleButtonGroup>
            </Col>
        </Row>
        <Row style={{ marginTop: "40px", marginBottom: "20px" }}><Col><h5>Funeral Services</h5></Col></Row>
        <Row>
            <Col>
                <ToggleButtonGroup type="radio" name="funeralServices" value={funeral} onChange={setFuneral}>
                    <ToggleButton variant="light" value={"traditional"}>Traditional Service</ToggleButton>
                    <ToggleButton variant="light" value={"memorial"}>Memorial Service</ToggleButton>
                    <ToggleButton variant="light" value={"graveside"}>Graveside Service</ToggleButton>
                    <ToggleButton variant="light" value={"interment"}>Interment Only</ToggleButton>
                    <ToggleButton variant="light" value={"other"}>Other</ToggleButton>
                </ToggleButtonGroup>
            </Col>
        </Row>
        <Row style={{ marginTop: "40px", marginBottom: "20px" }}><Col><h5>Related Information</h5>You might consider storing a more detailed “Letter of Instruction” in Documents + Vital Records to outline precise arrangement specifications, assign individual roles, and further define responsibilities.</Col></Row>
        <Row>
            <Col>
                <Form.Control as="textarea" rows={3} defaultValue={notes} onChange={(e) => setNotes(e.target.value)} />
            </Col>
        </Row>
        <hr />
        {!vaultAccessMode.isReadOnly && <Row><Col><Button variant="info" className="pull-right" onClick={() => saveWishes()}>Save</Button></Col></Row>}
    </>
}
