import { useHistory } from "react-router-dom";
import { useAuth } from "../../providers/auth-provider";

function Logout(props) {
    const { logout } = useAuth();
    const history = useHistory();

    logout();
    // Redirect to home
    history.push('/login');

    return <>
        Logging out...
    </>
}

export default Logout;