import { Row, Col, Container } from "react-bootstrap";
import Header from "./UI/Components/Header.js";
import { Header as AdvisorHeader } from "./UI/Components/Advisor/Header.js";
import AppContext from "./UI/Components/AppContext";
import People from "./UI/Components/People.js";
import AdvisorSearch from "./UI/Components/AdvisorSearch.js";
import Person from "./UI/Components/Person.js";
import Assets from "./UI/Components/Assets.js";
import AssetDetails from "./UI/Components/AssetDetails.js";
import AddAsset from "./UI/Components/AddAsset.js";
import Vault from "./UI/Components/Vault.js";
import LoginPage from "./UI/Components/LoginPage.js";
import ForgotPassword from "./UI/Components/ForgotPassword.js";
import Logout from "./UI/Components/Logout.js";
import Signup from "./UI/Components/Signup.js";
import AdvisorSignup from "./UI/Components/AdvisorSignup.js";
import VerifyEmail from "./UI/Components/VerifyEmail.js";
import Clients from "./UI/Components/Advisor/Clients.js";
import Report from "./UI/Components/Advisor/Report.js";
import UserReport from "./UI/Components/Report.js";
import AddPerson from "./UI/Components/AddPerson.js";
import UserProfile from "./UI/Components/UserProfile";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory,
} from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useAuth } from "./providers/auth-provider";
import ResetPassword from "./UI/Components/ResetPassword.js";
import axios from "axios";
import Content from "./UI/Components/Admin/Content";

const AuthenticatedApp = (props) => (
  <>
    <Header />
    <div
      className={"bg-benefactor " + props.title}
      style={{ marginBottom: "20px", height: "10px" }}
    />
    <Container
      className={`p-3 ${props.title}`}
      style={{ marginBottom: "100px" }}
    >
      <div>
        <Switch>
          <Route path="/profile" component={UserProfile} />
          <Route path="/report" component={UserReport} />
          <Route path="/assets/add" component={AddAsset} />
          <Route path="/assets/:id" component={AssetDetails} />
          <Route path="/assets" component={Assets} />
          <Route path="/advisorsearch" component={AdvisorSearch} />
          <Route path="/people/add" component={AddPerson} />
          <Route path="/people/:id" component={Person} />
          <Route path="/people" component={People} />
          <Route path="/logout" component={Logout} />
          <Route path="/resetpassword" component={ResetPassword} />
          <Route path="/forgotpassword" component={ForgotPassword} />
          <Route path="/verifyEmail" component={VerifyEmail} />
          <Route path="/admin/content" component={Content} />
          <Route path="/" component={Vault} />
        </Switch>
      </div>
    </Container>
  </>
);

const AdvisorApp = () => (
  <>
    <AdvisorHeader />
    <div
      className="bg-advisor"
      style={{ marginBottom: "20px", height: "10px" }}
    />
    <Container className="p-3" style={{ marginBottom: "100px" }}>
      <div>
        <Switch>
          <Route path="/advisor/report/:id" component={Report} />
          <Route path="/advisor" component={Clients} />
        </Switch>
      </div>
    </Container>
  </>
);

const UnAuthenticatedApp = () => (
  <Container
    style={{
      backgroundColor: "#ebedef",
      maxWidth: "100%",
      width: "100vw",
      height: "100vh",
    }}
  >
    <Row className="align-items-center h-100">
      <Col className="mx-auto" md={6}>
        <Switch>
          <Route path="/login" component={LoginPage} />
          <Route path="/signup" component={Signup} />
          <Route path="/advisorsignup" component={AdvisorSignup} />
          <Route path="/resetpassword" component={ResetPassword} />
          <Route path="/forgotpassword" component={ForgotPassword} />
          <Route path="/verifyEmail" component={VerifyEmail} />
        </Switch>
      </Col>
    </Row>
  </Container>
);

const UnAuthenticatedPages = [
  "/login",
  "/signup",
  "/advisorsignup",
  "/resetpassword",
  "/forgotpassword",
  "/verifyEmail",
];

const App = () => {
  const { user } = useAuth();
  const [vault, setVault] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [vaultAccessMode, setVaultAccessMode] = useState({
    mode: "full",
    isReadOnly: false,
  });
  const [profile, setProfile] = useState(user);
  const history = useHistory();

  axios.interceptors.response.use(undefined, (error) => {
    if (
      (error.response.status === 401 ||
        error.response.data.message ===
          "There is no jwt cookie, so the request is unauthorized") &&
      !UnAuthenticatedPages.includes(window.location.pathname)
    ) {
      // Delete the local storage user and ask them to log in again except on UnAuthenticated pages
      localStorage.removeItem("user");
      //delete all cookies
      document.cookie.split(";").forEach(function (c) {
        document.cookie = c
          .replace(/^ +/, "")
          .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
      });

      const currentUrl = window.location.pathname;
      if (!currentUrl.includes("login")) {
        window.location.replace("/login");

        setTimeout(() => {
        window.location.reload();
        }, 1500);
      }
    }
    return Promise.reject(error);
  });

  useEffect(() => {
    if (window.location.pathname === "/login") {
      if (user && user.role === "advisor") {
        window.location.replace("/advisor");
      }

      setIsLoading(false);
      return;
    }

    // history.listen((location) => {
    //   console.log(`You changed the page to: ${window.location.pathname}`)
    // })

    if (
      user &&
      user.role === "advisor" &&
      vault &&
      Object.keys(vault).length === 0 &&
      window.location.pathname !== "/advisor" &&
      window.location.pathname !== "/resetpassword"
    ) {
      window.location.replace("/advisor");
    }

    axios
      .get(`/api/loggedInVault`, {})
      .then((res) => {
        setVault(res.data);
      })
      .catch(function (error) {
        //Not logged in set vault to null
        setVault(null);
      })
      .finally(function () {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, history]);

  let backgroundClass = "";
  if (!isLoading) {
    if (user && vault) {
      axios
        .get(`/api/users`, {})
        .then((res) => {})
        .catch(function (error) {
          //Not logged in, move to login unless there
          if (!window.location.pathname === "/login") {
            window.location.replace("/login");
          }
        })
        .finally(function () {
          setIsLoading(false);
        });

      backgroundClass =
        user.id === vault.ownerId ? "selfVault" : "someoneElseVault";
      if (window.location.pathname === "/profile") backgroundClass = "";
      return (
        <>
          <AppContext.Provider
            value={{
              user,
              vault,
              setVault,
              profile,
              setProfile,
              vaultAccessMode,
              setVaultAccessMode,
            }}
          >
            <Router>
              <Switch>
                <Route path="/login" component={UnAuthenticatedApp} />
                <Route path="/signup" component={UnAuthenticatedApp} />
                <Route path="/advisorsignup" component={UnAuthenticatedApp} />
                <Route path="/forgotPassword" component={UnAuthenticatedApp} />
                <Route path="/resetPassword" component={UnAuthenticatedApp} />
                <Route path="/verifyEmail" component={UnAuthenticatedApp} />
                <Route path="/advisor" component={AdvisorApp} />
                <Route
                  path="/"
                  render={(props) => (
                    <AuthenticatedApp
                      title={backgroundClass}
                      isSelfVault={true}
                    />
                  )}
                />
              </Switch>
            </Router>
          </AppContext.Provider>
        </>
      );
    } else {
      return (
        <>
          <AppContext.Provider
            value={{
              user,
              vault,
              setVault,
              profile,
              setProfile,
              vaultAccessMode,
              setVaultAccessMode,
            }}
          >
            <Router>
              <Switch>
                <Route path="/login" component={UnAuthenticatedApp} />
                <Route path="/signup" component={UnAuthenticatedApp} />
                <Route path="/advisorsignup" component={UnAuthenticatedApp} />
                <Route path="/forgotPassword" component={UnAuthenticatedApp} />
                <Route path="/resetPassword" component={UnAuthenticatedApp} />
                <Route path="/verifyEmail" component={UnAuthenticatedApp} />
                <Route path="/">
                  <Redirect to="login" />
                </Route>
                <Route path="/:rest*">404, not found!</Route>
              </Switch>
            </Router>
          </AppContext.Provider>
        </>
      );
    }
  } else {
    // Loading icon?
    return null;
  }
};

export default App;

