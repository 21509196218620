import { Button, Card, Badge } from "react-bootstrap";
import ProfileImage from "./ProfileImage";
import { Link } from "react-router-dom";
import {
  FaTelegramPlane,
  FaUserFriends,
  FaBriefcase,
  FaRibbon,
  FaPhoneAlt,
  FaChild,
  FaLightbulb,
  FaCalendarAlt,
} from "react-icons/fa";
import { GetRelationshipLabel } from "./../../Utils.js";
import Moment from "react-moment";

function ConnectionCardIcon(props) {
  let icon = <FaUserFriends className="icon" />;

  switch (props.type) {
    case "beneficiary":
      icon = <FaUserFriends className="icon" />;
      break;
    case "advisor":
      icon = <FaBriefcase className="icon" />;
      break;
    case "dependent":
      icon = <FaChild className="icon" />;
      break;
    case "charity":
      icon = <FaRibbon className="icon" />;
      break;
    case "contact":
      icon = <FaPhoneAlt className="icon" />;
      break;
    default:
      icon = <FaUserFriends className="icon" />;
  }
  return icon;
}

function PeopleCard(props) {
  const { connection } = props;
  return (
    <>
      <Card className={"peopleCard " + connection.type}>
        <Card.Header
          className={"alittlebitofcss"}
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
            }}
          >
            <ProfileImage
              profileImage={connection.profileImage}
              name={connection.name}
            />
            <div
              style={{ marginTop: "5px", float: "left", fontWeight: "bold" }}
            >
              {connection.name}
              <br />{" "}
              <span
                style={{
                  fontWeight: "normal",
                  fontSize: "0.8em",
                  marginTop: "2px",
                }}
              >
                {GetRelationshipLabel(connection.relationship)}
              </span>
            </div>
          </div>
          <ConnectionCardIcon type={connection.type} />
        </Card.Header>
        <Card.Body>
          <Card.Text className="moreCSS">
            <div style={{ float: "right" }}>
              {connection.connected && (
                <>
                  <FaLightbulb /> Connected
                </>
              )}
              {!connection.connected && false && (
                <>
                  <FaCalendarAlt /> Invited{" "}
                  <Moment format="ll">{connection.dateCreated}</Moment>
                </>
              )}
            </div>
            {connection.relatedDesignations && (
              <>
                <div>
                  {connection.relatedDesignations.map(function (r, index) {
                    if (connection.relatedDesignations.length === index + 1)
                      return <span>{r.label}</span>;
                    return <span>{r.label}, </span>;
                  })}
                </div>
              </>
            )}

            {/*  Assets: {connection.assignedCount} */}
          </Card.Text>
        </Card.Body>
        <Card.Footer>
          <Button
            variant="info"
            as={Link}
            to={"/people/" + connection._id}
            size="sm"
            className="pull-right"
          >
            <FaTelegramPlane /> View
          </Button>{" "}
          {connection.invited && connection.invited !== "" && (
            <div style={{ float: "right" }}>
              <small className="text-muted">Invited {connection.invited}</small>
            </div>
          )}
        </Card.Footer>
      </Card>
    </>
  );
}

export default PeopleCard;

