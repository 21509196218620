import { Row, Col, Table, Card, Image } from 'react-bootstrap';
import { FaGem, FaCar, FaCoins, FaHome } from 'react-icons/fa';
import { GiChest } from "react-icons/gi";
import { useState, useEffect } from 'react';
import { IoLogoBitcoin } from "react-icons/io";
import axios from 'axios';
import { GetCurrencyFormatted, GetAssetTypeLabel, GetSubTypeImage, GetAssetTypeColor } from './../../../Utils.js';
import { Chart } from "react-google-charts";
import _ from 'lodash';
import { Link } from "react-router-dom";

function GetAssetTypeIcon(label) {
    switch (label) {
        case "account": return <IoLogoBitcoin />;
        case "insurance": return <FaCoins />;
        case "properties": return <FaHome />;
        case "property": return <FaHome />;
        case "vehicle": return <FaCar />;
        case "collectibles": return <GiChest />;
        default: return label;
    }
}


function Report(props) {
    //"600311fb04a97730acbbb4a6"
    const [assets, setAssets] = useState([]);
    const [assetsAllocation, setAssetsAllocation] = useState([]);
    const [beneficaryAllocation, setBeneficiaryAllocation] = useState([]);
    const [assetAllocationSlices, setAssetAllocationSlices] = useState([]);
    const [estimatedValue, setEstimatedValue] = useState(0);
    const [estimatedInheritanceValue, setEstimatedInheritancValue] = useState(0);
    const [estimatedInheritanceCount, setEstimatedInheritancCount] = useState(0);
    const [assetsCount, setAssetsCount] = useState(0);
    const [profileData, setProfileData] = useState();

    useEffect(() => {
        const reportProfile = props.match.params.id;
        axios.all([
            axios.get(`/api/connections?options=names`, {
                params: {
                    vault: reportProfile
                }
            }),
            axios.get(`/api/assets`, {
                params: {
                    vault: reportProfile
                }
            }),
            axios.get(`/api/vaults`, {
                params: {
                    vault: reportProfile
                }
            }),
        ])
            .then(axios.spread((connectionResults, assetResult, vaultResults) => {
                console.log(connectionResults);
                console.log(assetResult.data);
                console.log(vaultResults.data);
                let estimatedValue = 0;
                assetResult.data.forEach(function (element) {
                    estimatedValue += parseInt(element.calculatedValue);

                    if (element.assignments && element.assignments.length > 0) {
                        element.assignments.forEach(function (beneficiary) {

                            let _c = connectionResults.data.filter(conn => conn._id === beneficiary.assignment);
                            if (_c && _c[0] !== undefined) {
                                assets.push({
                                    asset: element,
                                    beneficiary: {
                                        name: _c[0].name,
                                        percentage: beneficiary.percentage,
                                        value: element.calculatedValue * beneficiary.percentage / 100,
                                        privacy: "VISIBLE"
                                    }
                                })
                            };
                        });
                    }
                    else {
                        assets.push({
                            asset: element,
                            beneficiary: { name: "" },
                            privacy: "VISIBLE"
                        })
                    }
                });
                setAssets(assets);
                setEstimatedValue(estimatedValue);
                setAssetsCount(assetResult.data.length)


                const ans = _(assets)
                    .groupBy('asset.type')
                    .map((platform, id) => ({
                        platformId: id,
                        totalAmount: _.sumBy(platform, 'asset.calculatedValue'),
                    }))
                    .value();
                console.log(ans);

                const chartData = [['Currency Name', 'Currency Rate']]
                const slices = [];
                ans.forEach(r => {

                    if (r.platformId == "null") {
                        r.platformId = "Unassigned";
                        r.totalAmount = 0;
                    }
                    chartData.push([GetAssetTypeLabel(r.platformId), r.totalAmount])
                    slices.push ({color:GetAssetTypeColor(r.platformId)});
                });

                setAssetsAllocation(chartData);
                setAssetAllocationSlices(slices);

                const ans2 = _(assets)
                    .groupBy('beneficiary.name')
                    .map((platform, id) => ({
                        platformId: id,
                        totalAmount: _.sumBy(platform, 'beneficiary.value'),
                    }))
                    .value();

                console.log(ans2);
                const totalAssigned = _.sumBy(ans2, 'totalAmount');
                console.log(totalAssigned);

                const chartData2 = [['Currency Name', 'Currency Rate']]
                ans2.forEach(r => {

                    if (r.platformId === "") {
                        r.platformId = "Unassigned";
                        r.totalAmount = estimatedValue - totalAssigned;
                    }
                    chartData2.push([r.platformId, r.totalAmount])
                });


                setBeneficiaryAllocation(chartData2);

                console.log(vaultResults.data.owner)
                axios.all([
                    axios.get(`/api/profiles`, {
                        params: {
                            profileId: vaultResults.data.owner
                        }
                    }),
                    axios.get(`/api/profiles?options=readAssetsInheritance`, {
                        params: {
                            profileId: vaultResults.data.owner
                        }
                    })
                ])
                    .then(axios.spread((res, inheritance) => {
                        setProfileData(res.data);
                        setEstimatedInheritancCount (inheritance.data.length);

                        let estimatedValue = 0;
                        inheritance.data.forEach(function (element) {
                            estimatedValue += parseInt(element.calculatedValue);
                        });
                        setEstimatedInheritancValue(estimatedValue);

                    }))
                    .catch(function (error) {
                        console.log(error);
                    });

            }));
    }, []);

    return <>
        <Row style={{ marginBottom: '20px' }}><Col><Link to="/advisor/clients">Back</Link></Col></Row>
        {profileData !== null && profileData !== undefined && <>
            <Card>
                <Card.Body>
                    <Row><Col><h3>{profileData.fullName}</h3></Col></Row>

                    <Row style={{ marginBottom: '40px' }}>
                        <Col md={4}>
                            Address: {profileData.address} {profileData.address2}<br />
                            {profileData.city}, {profileData.region}, {profileData.country} <br />
                            {profileData.zipCode}
                        </Col>
                        <Col md={4}>
                            Phone Number: {profileData.phoneNumber}<br />
                            Gender: {profileData.gender}<br />
                            Marital Status: {profileData.marital}<br />
                            Birthday: {profileData.dob}
                        </Col>
                        <Col md={4}>
                            Employer: {profileData.employer}<br />
                            Job Title: {profileData.occupation}<br />
                            Annual Income: ${GetCurrencyFormatted(Number(profileData.income))}<br />
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: '20px' }}>
                        <Col md={12}>
                            {assetsCount} Assets with Estimated Value of ${GetCurrencyFormatted(estimatedValue)} <br />
                            Estimated Inheritance: {estimatedInheritanceCount} Assets with a Value of ${GetCurrencyFormatted(estimatedInheritanceValue)}
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>}


        <Row style={{ marginBottom: '20px', marginTop: '20px' }}>
            <Col md={6}>
                {assetsAllocation.length > 0 && <>
                    <Card><Card.Body>
                        <Chart
                            width={'500px'}
                            height={'300px'}
                            chartType="PieChart"
                            loader={<div>Loading Chart</div>}
                            data={assetsAllocation}
                            options={{
                                title: 'Allocation By Asset Type',
                                is3D: true,
                                slices: assetAllocationSlices                              
                            }}
                            rootProps={{ 'data-testid': '1' }}
                        />
                    </Card.Body></Card>
                </>}
            </Col>
            <Col md={6}>
                {beneficaryAllocation.length > 0 && <>
                    <Card><Card.Body>
                        <Chart
                            width={'500px'}
                            height={'300px'}
                            chartType="PieChart"
                            loader={<div>Loading Chart</div>}
                            data={beneficaryAllocation}
                            options={{
                                title: 'Allocation By Beneficiary',
                                is3D: true,
                            }}
                            rootProps={{ 'data-testid': '2' }}
                        />
                    </Card.Body></Card>
                </>}
            </Col>
        </Row>

        <Row style={{ marginBottom: '20px' }}>
            <Col md={12} >
                <Card><Card.Body>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th><FaGem /></th>
                                <th>Asset</th>
                                <th>Estimated Value</th>
                                <th>Beneficiary</th>
                                <th>Beneficiary %</th>
                                {/*  <th>Privacy</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {assets && <>
                                {assets.map((c, i) => {
                                    return <tr key={"connections_" + i}>
                                        <td>{GetAssetTypeIcon(c.asset.type)}

                                        </td>
                                        <td><Image src={"https://www.execuvault.com/asset-icons/" + GetSubTypeImage(c.asset.subType)} className="smallerAvatarSmaller" roundedCircle /> {c.asset.name}</td>
                                        <td align="right">${GetCurrencyFormatted(c.asset.calculatedValue)}</td>
                                        {c.beneficiary.name === "" && <>
                                            <td>{c.beneficiary.name}</td>
                                            <td align="right"></td>
                                        </>}
                                        {c.beneficiary.name !== "" && <>
                                            <td>{c.beneficiary.name}</td>
                                            <td align="right">${GetCurrencyFormatted(c.beneficiary.value)} ({c.beneficiary.percentage})</td>
                                        </>}
                                        {/* <td>{c.beneficiary.privacy}</td> */}
                                    </tr>
                                })}
                            </>}
                        </tbody>

                    </Table>
                </Card.Body></Card>
            </Col>
        </Row>
    </>
}

export default Report;