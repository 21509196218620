import { Form, Row, Col, Card, Button, Modal, Badge, DropdownButton, Dropdown } from 'react-bootstrap';
import { Link, useHistory } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { FaTelegramPlane, FaChevronLeft, FaRegFileAlt, FaTimes, FaPaperclip } from 'react-icons/fa';
import { useForm } from "react-hook-form";
import AppContext from '../AppContext';
import Tips from '../Tips';
import { IoMdSettings } from 'react-icons/io';
import axios from 'axios';
import Toaster from '../Toaster';
import { useLocation } from 'react-router';
import Select, {components} from 'react-select'

const accountTypeOptions = [
    { key: 'Amazon', label: 'Amazon', value: 'Amazon', icon: 'fab fa-amazon' },
    { key: 'Apple ID', label: 'Apple ID', value: 'Apple ID', icon: 'fab fa-apple' },
    { key: 'Email', label: 'Email', value: 'Email', icon: 'fas fa-at' },
    { key: 'Facebook', label: 'Facebook', value: 'Facebook', icon:'fab fa-facebook' },
    { key: 'Laptop/Desktop/Tablet', label: 'Laptop/Desktop/Tablet', value: 'Laptop/Desktop/Tablet', icon: 'fas fa-laptop' },
    { key: 'LinkedIn', label: 'LinkedIn', value: 'LinkedIn', icon: 'fab fa-linkedin' },
    { key: 'Mobile/Smartphone', label: 'Mobile/Smartphone', value: 'Mobile/Smartphone', icon: 'fas fa-mobile alternate' },
    { key: 'Paypal', label: 'Paypal', value: 'Paypal', icon: 'fab fa-paypal' },
    { key: 'Streaming Services', label: 'Streaming Services', value: 'Streaming Services', icon: 'fas fa-tv' },
    { key: 'Utilities', label: 'Utilities', value: 'Utilities', icon: 'fas fa-bolt' },
    { key: 'Wifi', label: 'Wifi', value: 'Wifi', icon: 'fas fa-wifi' },
    { key: 'Other', label: 'Other', value: 'Other', icon: 'fas fa-power-off' },
]

const { Option, SelectOption } = components;
function customSingleValue (props) {
    return <Option {...props}>
      <i className={props.data.icon}></i> {props.data.label}
    </Option>
}

function customSelectedValue (props){
    console.log(props);
    return <SelectOption>hi</SelectOption>
}

function AccountDetails(props) {
    const liabilityProfile = props.match.params.id;
    const { vaultAccessMode, vault } = useContext(AppContext);
    const [deleteAssetConfirmation, setDeleteAssetConfirmation] = useState(false);
    const [showSuccessToast, setShowSuccessToast] = useState(false);
    const [toasterMessage, setToasterMessage] = useState({ title: "", message: "" });
    const [isEditing, setIsEditing] = useState(liabilityProfile !== "new");

    const isReadOnly = vaultAccessMode === "readOnly";
    const [account, setAccount] = useState(null);
    const [accountDeviceType, setAccountDeviceType] = useState(null);
    const { register, handleSubmit, errors } = useForm({ criteriaMode: "all" });
    const history = useHistory();
    const location = useLocation();


    const onSelectControlChange = (val) => {console.log(val); setAccountDeviceType (val.value)};
 

    const handleDelete= () => {
        setDeleteAssetConfirmation(false);
          axios.delete(`/api/accountDevices?vault=` + vault.id + `&account=` + liabilityProfile)
            .then(res => {
                if (res.status === 200) {
                    history.push("/accounts/");
                }
            })            
    }

    const onSubmit = (data, e) => {
        setIsEditing (liabilityProfile !== "new");
        data.accountDeviceType = accountDeviceType; //accountDevices
        console.log(data);
         if(isEditing){
            axios.post(`/api/accountDevices?vault=` + vault.id + `&account=` + liabilityProfile, data)
            .then(res => {
                if (res.status === 200) {
                    console.log("done");
                    setToasterMessage({ title: "Success!", message: "Account/Device Details Updated" })
                    setShowSuccessToast(true);
                }
            })
        }
        else{
        axios.put(`/api/accountDevices?vault=` + vault.id, data)
            .then(res => {
                if (res.status === 200) {
                    setIsEditing(true);
                    history.push("/accounts/" + res.data + "?new")
                }
            })
        } 
    }

    useEffect(() => {
        if(liabilityProfile === "new"){
            setAccount({accountDeviceType:"Amazon"})
            return;
        }
        if (location.search === "?new") {
            setToasterMessage({ title: "Success!", message: "New Liability Created" })
            setShowSuccessToast(true);
        }


        axios.get(`/api/accountDevices`, {
            params: {
                vault: vault.id,
                account: liabilityProfile
            }
        })
            .then(res => {
                setAccount(res.data);
            })
            .catch(function (error) {
                console.log(error);
            })
    }, []);

    return <>
        <Modal
            size="sm"
            show={deleteAssetConfirmation}
            onHide={() => setDeleteAssetConfirmation(false)}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Are you sure you want to delete this Account/Device?
          </Modal.Title>
            </Modal.Header>
            <Modal.Body> <Button variant="info" size="sm" className="pull-right" onClick={() => {handleDelete()}}> Delete Account/Device</Button></Modal.Body>
        </Modal>
        <Row>
            <Col>
                <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Card>
                        {isEditing && <>
                            <Card.Header>
                                {account && account.name}
                        <DropdownButton variant="icon" className="pull-right" id="dropdown-basic-button" title={<IoMdSettings />}>
                                    {!isReadOnly && <Dropdown.Item onClick={() => setDeleteAssetConfirmation(true)}>Remove Account/Device</Dropdown.Item>}
                                </DropdownButton>
                            </Card.Header>
                        </>}
                        {!isEditing && <>
                            <Card.Header>
                                Account Details
                        </Card.Header>
                        </>}
                        <Card.Body>

                            <Form.Row>
                                <Form.Group as={Col} md="12">
                                    <Form.Label>Nickname of this Account</Form.Label>
                                    <Form.Control
                                        name="name"
                                        defaultValue={account ? account.name : ""}
                                        placeholder=""
                                        ref={register({
                                            required: true, maxLength: 80,
                                        })}
                                        isInvalid={!!errors.name}
                                    />
                                    <Form.Control.Feedback type="invalid" tooltip>
                                        {errors.name && "Name is required"}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} md="12">
                                    <Form.Label>Account or Device </Form.Label>
                                    {account && account.accountDeviceType && <>
                                    <Select
                                        onChange = {onSelectControlChange}
                                        defaultValue = {accountTypeOptions.filter (o=> o.key ===  account.accountDeviceType)}
                                        name="accountdevice"
                                        options={ accountTypeOptions }
                                        components={ {Option: customSingleValue, SelectOption: customSelectedValue } }
                                    /> 
                                    </>}
                                </Form.Group>
                            </Form.Row>                            
                            <Form.Row>
                                <Form.Group as={Col} md="12">
                                    <Form.Label>Login (Username or Account Email) </Form.Label>
                                    <Form.Control
                                        name="loginName"
                                        defaultValue={account ? account.loginName : ""}
                                        placeholder=""
                                        ref={register()}
                                        isInvalid={!!errors.name}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} md="12">
                                    <Form.Label>Password Hint (Personalized Notes) </Form.Label>
                                    <Form.Control
                                        name="passwordHint"
                                        defaultValue={account ? account.passwordHint : ""}
                                        placeholder=""
                                        ref={register()}
                                        isInvalid={!!errors.name}
                                        as="textarea" rows={8}
                                    />
                                </Form.Group>
                            </Form.Row>
                        </Card.Body>
                        {!isReadOnly && <Card.Footer>
                            <Button variant="info" type="submit" size="sm" className="pull-right"><FaTelegramPlane /> Save</Button>
                        </Card.Footer>}
                    </Card>
                </Form>
            </Col>
            <Col md="4">
            <Tips contentKey="accountDetails" />
            </Col>
        </Row>
        <Toaster setShowSuccessToast={setShowSuccessToast} toasterMessage={toasterMessage} showSuccessToast={showSuccessToast} />
    </>
}

export default AccountDetails;