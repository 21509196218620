import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import { FaPencilAlt } from 'react-icons/fa';
import ReactGoogleMapImage from 'react-google-map-image';

function LocationCard(props) {
    const { setShowLocation = () => void 0, location, isReadOnly =false } = props;
    const googleMapApiConfig = {
        center: location.address + " " + location.city + " " + location.country,
        size: '500x240',
        zoom: '15',
        key: 'AIzaSyBb9nrVZdnuiYeJyODid0RT-OpFxKn_iMs',
        maptype: 'roadmap'
    }

    return <>
        <Card>
            <Card.Header style={{ height: "56px" }}>
                {location.name}  {location.isPrimary && <><Badge variant="info">Primary</Badge><br /></>}
            </Card.Header>
            <Card.Body>
                <ReactGoogleMapImage
                    config={googleMapApiConfig}
                    wrapperStyle={{ width: '100%' }}
                    style={{ width: '100%', height: 'auto', border: '1px solid #ccc' }}
                />
                <br />
                <Card.Text style={{ fontSize: '.8em' }}>
                    {location.address}<br />
                    {location.city},  {location.region},  {location.country} <br />
                    {location.zip}
                </Card.Text>

            </Card.Body>
            {!isReadOnly && <Card.Footer>
                <Button variant="primary" onClick={() => setShowLocation(location)} size="sm" className="pull-right"><FaPencilAlt /> Edit</Button>
            </Card.Footer>}
        </Card>
    </>
}

export default LocationCard;