import { Form, Col } from 'react-bootstrap';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { useState, useEffect } from "react";

export const Address = (props) => {
    const { register = () => void 0, errors = {}, requiredValidation = false, defaultAddress = {} } = props;
    const [country, setCountry] = useState(defaultAddress.country);
    const [region, setRegion] = useState(defaultAddress.region);

    useEffect(() => {
        setCountry(defaultAddress.country);
    }, [defaultAddress.country])

    useEffect(() => {
        setRegion(defaultAddress.region);
    }, [defaultAddress.region])


    return <>
        <Form.Row>
            <Form.Group as={Col}>
                <Form.Label>Address</Form.Label>
                <Form.Control
                    ref={register({ required: requiredValidation, maxLength: 80 })}
                    isInvalid={!!errors.address}
                    name="address"
                    defaultValue={defaultAddress.address}
                    placeholder="1234 Main St" />
            </Form.Group>
            <Form.Group as={Col} md="3">
                <Form.Label>City</Form.Label>
                <Form.Control
                    name="city"
                    ref={register}
                    defaultValue={defaultAddress.city}
                />
            </Form.Group>
        </Form.Row>
        <Form.Row>
            <Form.Group as={Col}>
                <Form.Label>Country</Form.Label>
                <CountryDropdown
                    className="form-control"
                    value={country}
                    priorityOptions={["CA", "US", "GB"]}
                    onChange={(val) => setCountry(val)} />
            </Form.Group>

            <Form.Group as={Col}>
                <Form.Label>State/Province/Region</Form.Label>
                <RegionDropdown
                    ref={register}
                    className="form-control"
                    disableWhenEmpty={true}
                    country={country}
                    value={region}
                    blankOptionLabel="Select State/Province/Region"
                    defaultOptionLabel="Select State/Province/Region"
                    onChange={(val) => setRegion(val)} />
            </Form.Group>

            <Form.Group as={Col} md="3">
                <Form.Label>Zip/Postal Code</Form.Label>
                <Form.Control name="zip" ref={register} defaultValue={defaultAddress.zip} />
            </Form.Group>
        </Form.Row>
    </>
}

export default Address;